import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

type PutCustomerBlockedResourceRequest = {
  customerId: string;
  resourceIds: string[];
};

function putCustomerBlockedResource(request: PutCustomerBlockedResourceRequest) {
  return getSMPBackendAPIAdmin().customersControllerAddBlockedResource(request.customerId, {
    resourceIds: request.resourceIds,
  });
}

export function usePutCustomerBlockedResource() {
  return useMutation({ mutationFn: putCustomerBlockedResource });
}
