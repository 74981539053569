import { Card } from 'antd';

import { ContactBox } from '@/apps/web';

export function AppContactPage() {
  return (
    <Card>
      <ContactBox isResource />
    </Card>
  );
}
