/* eslint-disable @typescript-eslint/no-floating-promises */
import * as Sentry from '@sentry/react';
import 'antd/dist/reset.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './i18n.ts';
import './styles/index.scss';

import { environment } from '@/environments';

import { App } from './App';
import { Auth0ProviderWithNavigate } from './components/auth/Auth0ProviderWithNavigate.js';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: environment.sentryDsn,
    // escape backslash in api url for sentry
    tracePropagationTargets: [import.meta.env.VITE_API.replace(/\\/g, '\\\\')],
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

dayjs.extend(relativeTime);
dayjs.extend(utc);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);
