import { useQuery } from '@tanstack/react-query';

import { BookingVerification } from '@/models';
import { ErrorDto, getSMPBackendAPIPublic, ServiceBookingVerificationDto } from '@/proxy-web';
import { toVerifyBooking } from '@/services';

function getVerifyWebBooking(bookingId: string) {
  return getSMPBackendAPIPublic().requestControllerGetBookingVerification(bookingId);
}

export function useGetVerfiyWebBooking(bookingId: string) {
  return useQuery<ServiceBookingVerificationDto, ErrorDto, BookingVerification>({
    queryKey: ['verifyWebBooking', bookingId],
    queryFn: () => getVerifyWebBooking(bookingId),
    enabled: !!bookingId,
    select: (data) => toVerifyBooking(data),
  });
}
