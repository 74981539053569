import { useMutation } from '@tanstack/react-query';

import { DateTimeSurcharge } from '@/models';
import { getSMPBackendAPIAdmin } from '@/proxy';

function deleteTimeSurcharge(dateTimeDataId: DateTimeSurcharge['id']) {
  return getSMPBackendAPIAdmin().settingsControllerDeleteDayDateSurcharge(dateTimeDataId);
}

export function useDeleteTimeSurcharge() {
  return useMutation({ mutationFn: deleteTimeSurcharge });
}
