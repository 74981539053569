import { Col, Row } from 'antd';

export function Header() {
  return (
    <Col span={24} style={{ backgroundColor: '#faf0e5', padding: 24 }}>
      <Row align='middle' justify='center'>
        <img src='/smpLogo180.png' alt='SMP Logo' />
      </Row>
    </Col>
  );
}
