import { UploadFile } from 'antd/lib/upload/interface';
import { Dayjs } from 'dayjs';

import { ServiceBookingDto } from '@/proxy';

import { Customer } from './customer.model';
import { Language } from './language.model';
import { Location } from './location';
import { Resource } from './resource.model';

import { Invoice } from '.';

export type PaginatedBookings = {
  data: Booking[];
  total: number;
  page: number;
  limit: number;
};

export type PaginatedBookingsDto = {
  data: ServiceBookingDto[];
  total: number;
  page: number;
  limit: number;
};

export type BookingGroup = {
  date: string;
  bookings: Booking[];
};

export type Booking = {
  id: string;
  reference: string;
  date: Dayjs;
  customer: Customer;
  debtor?: Customer;
  type: BookingType;
  language: Language;
  alternativeLanguage?: Language;
  status: BookingStatus;
  customerCharges: Surcharge[];
  resourceCharges: Surcharge[];
  contactPerson: ContactPerson;
  notes?: string;
  resource?: Resource;
  ignoreAvailability?: boolean;
  acceptsAlternativeResource?: boolean;
  requestExpiresAt?: Dayjs;
  onsite?: OnsiteBooking;
  remote?: RemoteBooking;
  document?: DocumentBooking;
  phone?: PhoneBooking;
  verification?: Verification;
  specialRate?: number;
  client?: Client;
  gender?: Gender;
  invoice?: Invoice;
  createdAt: Dayjs;
  updatedAt: Dayjs;
};

export type Gender = (typeof Gender)[keyof typeof Gender];

export const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
} as const;

export type BookingRequest = Booking;

export type OnsiteBooking = {
  duration: number;
  alternativeService: boolean;
  distance: number;
  location: Location;
};

export type RemoteBooking = {
  duration: number;
  emails: string[];
  meetingUrl?: string;
};

export type DocumentBooking = {
  fileId: string;
  fileName: string;
  viewUrl: string;
  downloadUrl: string;
  wordCount: number;
  file?: UploadFile[];
};

export type PhoneBooking = {
  duration: number;
  zoomConferenceNumber: string;
  zoomConferenceId: string;
  zoomConferencePassword: string;
  dialInNumber: string;
  meetingUrl: string;
  clientPhone: string;
  customerPhone: string;
};

export type BookingStatus = (typeof BookingStatus)[keyof typeof BookingStatus];

export const BookingStatus = {
  OPEN: 'OPEN',
  PUBLISHED: 'PUBLISHED',
  CONFIRMED: 'CONFIRMED',
  CLOSED: 'CLOSED',
  VERIFIED: 'VERIFIED',
  CANCELED: 'CANCELED',
  REQUESTED: 'REQUESTED',
  DECLINED: 'DECLINED',
} as const;

export type BookingType = (typeof BookingType)[keyof typeof BookingType];

export const BookingType = {
  DOCUMENT: 'DOCUMENT',
  ONSITE: 'ONSITE',
  REMOTE: 'REMOTE',
  PHONE: 'PHONE',
} as const;

export type ContactPerson = {
  name: string;
  email: string;
  phone: string;
};

export type Surcharge = {
  name: string;
  description?: string | null;
  value: number;
  duration?: number | null;
  type: SurchargeType;
};

export type SurchargeType = (typeof SurchargeType)[keyof typeof SurchargeType];

export const SurchargeType = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
  HOURLY: 'HOURLY',
} as const;

export type Client = {
  name: string;
  birthDate?: string;
  reference?: string;
};

export type Verification = {
  id: string;
  value: number;
  issuer: string;
  fileName?: string;
  fileUrl?: string;
  startTime?: Dayjs;
};

export type ResourceBooking = {
  id: string;
  reference: string;
  language: string;
  alternativeLanguage?: string;
  customer: string;
  date: Dayjs;
  type: BookingType;
  status: BookingStatus;
  resourceCharges: Surcharge[];
  contactPerson: ContactPerson;
  specialRate?: number;
  notes?: string;
  resource?: Resource;
  onsite?: Omit<OnsiteBooking, 'distance' | 'alternativeService'>;
  remote?: RemoteBooking;
  document?: DocumentBooking;
  phone?: PhoneBooking;
  verification?: Verification;
  gender?: Gender;
};

export type ResourceJob = {
  id: string;
  reference: string;
  date: Dayjs;
  type: BookingType;
  language: string;
  alternativeLanguage?: string;
  status: BookingStatus;
  resourceCharges: Surcharge[];
  contactPerson: ContactPerson;
  specialRate?: number;
  notes?: string;
  onsite?: Omit<OnsiteBooking, 'distance' | 'alternativeService'>;
  remote?: ResourceJobRemoteBooking;
  document?: Omit<DocumentBooking, 'file' | 'fileId' | 'fileName' | 'viewUrl' | 'downloadUrl'>;
  phone?: Pick<PhoneBooking, 'duration'>;
  gender?: Gender;
  requestExpirationDate?: Dayjs;
};

export type ResourceJobRemoteBooking = {
  duration: number;
  participantsCount: number;
};
