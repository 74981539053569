import { Card } from 'antd';
import { CardTabListType } from 'antd/es/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BillablePage, InvoicesPage } from '@/components/billing';
import { useDelayedLoading } from '@/hooks';

export function BillingPage() {
  const [activeTabKey, setActiveTabKey] = useState<string>('billable');
  const { t } = useTranslation();

  const showDelayedLoading = useDelayedLoading(false);

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const tabList: CardTabListType[] = [
    {
      key: 'billable',
      tab: t('common:billable'),
    },
    {
      key: 'invoices',
      tab: t('common:invoices'),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    billable: <BillablePage />,
    invoices: <InvoicesPage />,
  };

  return (
    <Card
      title={t('common:billing')}
      loading={showDelayedLoading}
      tabProps={{ size: 'small' }}
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      extra={
        <a href='https://app.lexoffice.de/dashboard' target='_blank' rel='norefferer noreferrer'>
          {t('common:editLexoffice')}
        </a>
      }
      bodyStyle={{ overflowY: 'auto', height: '100%', paddingBottom: '6rem' }}
      style={{ height: '100%', overflow: 'hidden' }}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}
