import {
  ClockCircleOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  FlagOutlined,
  HomeOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { List, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Booking } from '@/models';
import {
  DATETIME_WITHOUT_DAY_FORMAT,
  displayAddress,
  formatResourceName,
  getStatusColor,
  showGender,
} from '@/utils';

const { Text } = Typography;

type OnsiteBookingItemProps = {
  booking: Booking;
  extra?: React.ReactNode;
};

export function OnsiteBookingItem({ booking, extra }: OnsiteBookingItemProps) {
  const { t } = useTranslation();

  const borderColor = getStatusColor(booking.status);
  const listItemStyle = borderColor
    ? { marginBottom: '0.5rem', borderLeft: `4px solid ${borderColor}` }
    : { marginBottom: '0.5rem' };

  return (
    <List.Item style={listItemStyle} extra={extra}>
      <List.Item.Meta
        title={
          <Space split='-'>
            <Text strong>{booking.reference}</Text>
            <Text>
              <IdcardOutlined /> {booking.customer.company}
            </Text>
            <Text>
              <FlagOutlined />
              &nbsp;
              {booking.language.name}
              {booking.alternativeLanguage && ` / ${booking.alternativeLanguage.name}`}
            </Text>
            <Text>
              <ContactsOutlined />
              &nbsp;
              {booking.resource?.firstname && booking.resource.lastname
                ? formatResourceName(
                    booking.resource?.firstname,
                    booking.resource?.lastname,
                    'non-formal'
                  )
                : t('noResource')}
            </Text>
            {booking.specialRate && (
              <Tag color='red-inverse'>
                {t('forms:specialPrice')}: {booking.specialRate}€
              </Tag>
            )}
          </Space>
        }
        description={
          <Space>
            <Tag icon={<HomeOutlined />}>{t(booking.type)}</Tag>
            {booking.gender && (
              <Tag color={booking.gender === 'FEMALE' ? 'pink-inverse' : 'blue-inverse'}>
                {showGender(booking.gender)}
              </Tag>
            )}
            <Text>{dayjs(booking.date).format(DATETIME_WITHOUT_DAY_FORMAT)}</Text>
            {booking.onsite?.location ? (
              <Text>
                <EnvironmentOutlined /> {displayAddress(booking.onsite?.location)}
              </Text>
            ) : null}
            <Text>
              <ClockCircleOutlined />
              &nbsp;
              {t('minuteWithCount', { count: booking.onsite?.duration })}
            </Text>
          </Space>
        }
      />
    </List.Item>
  );
}
