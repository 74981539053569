import { Alert, Badge, Button, Card, List, Popconfirm, Space } from 'antd';
import { CardTabListType } from 'antd/es/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { CenterSpinner } from '@/components/shared';
import { queryClient, queryKeys } from '@/lib/react-query';
import { BookingStatus, ResourceJob } from '@/models';
import { ROUTE_CONSTANTS } from '@/routes';
import { showMessage } from '@/utils';
import { useAcceptJobRequest, useAcceptPublishedBooking, useJobs } from '@/web-api/bookings';
import { useJobRequests } from '@/web-api/bookings/useJobRequests';

import {
  DocumentBookingCardItem,
  OnsiteBookingCardItem,
  PhoneBookingCardItem,
  RemoteBookingCardItem,
} from '../components';

export function AppJobsPage() {
  const [activeTabKey, setActiveTabKey] = useState('jobs');

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading, error, isError } = useJobs();
  const requestedJobsQuery = useJobRequests();
  const confirmBookingMutation = useAcceptPublishedBooking();
  const acceptJobRequest = useAcceptJobRequest();

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const handleConfirmBooking = (id: string, status: BookingStatus) => {
    if (status === BookingStatus.REQUESTED) {
      acceptJobRequest.mutate(id, {
        onSuccess: () => {
          showMessage('success', t('forms:bookedJob'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.resourceBookings.all });
          void queryClient.invalidateQueries({ queryKey: queryKeys.jobs.all });
          navigate(`/app/mybookings/${id}`);
        },
      });
    } else {
      confirmBookingMutation.mutate(id, {
        onSuccess: () => {
          showMessage('success', t('forms:bookedJob'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.resourceBookings.all });
          void queryClient.invalidateQueries({ queryKey: queryKeys.jobs.all });
          navigate(`/app/mybookings/${id}`);
        },
      });
    }
  };

  const Extra = (job: ResourceJob) => {
    const link =
      job.status === BookingStatus.REQUESTED
        ? `/${ROUTE_CONSTANTS.APP_PATH}/${ROUTE_CONSTANTS.APP.JOB_REQUEST}/${job.id}`
        : `/${ROUTE_CONSTANTS.APP_PATH}/${ROUTE_CONSTANTS.APP.JOBS}/${job.id}`;
    return (
      <Space style={{ justifyContent: 'space-evenly', width: '100%' }}>
        <Link to={link}>{t('common:moreInfo')}</Link>
        <Popconfirm
          title={t('common:applyJob')}
          onConfirm={() => handleConfirmBooking(job.id, job.status)}
        >
          <Button type='primary'>{t('common:apply')}</Button>
        </Popconfirm>
      </Space>
    );
  };

  function renderBooking(job: ResourceJob) {
    switch (job.type) {
      case 'ONSITE':
        return <OnsiteBookingCardItem jobBooking={job} isJob extra={<Extra {...job} />} />;
      case 'REMOTE':
        return <RemoteBookingCardItem jobBooking={job} isJob extra={<Extra {...job} />} />;
      case 'PHONE':
        return <PhoneBookingCardItem jobBooking={job} isJob extra={<Extra {...job} />} />;
      case 'DOCUMENT':
        return <DocumentBookingCardItem jobBooking={job} isJob extra={<Extra {...job} />} />;
      default:
        return null;
    }
  }

  if (data === undefined && !isError) {
    return <CenterSpinner isLoading />;
  }

  const tabList: CardTabListType[] = [
    {
      key: 'jobs',
      tab: t('common:allJobs'),
    },
    {
      key: 'requestedJobs',
      tab: (
        <>
          {t('common:requestedJobs')} <Badge size='small' count={requestedJobsQuery.data?.length} />
        </>
      ),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    jobs: (
      <List
        loading={isLoading}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        dataSource={data}
        renderItem={(booking) => (
          <div style={{ position: 'relative' }}>{renderBooking(booking)}</div>
        )}
      />
    ),
    requestedJobs: (
      <List
        loading={requestedJobsQuery.isLoading}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        dataSource={requestedJobsQuery.data}
        renderItem={(booking) => (
          <div style={{ position: 'relative' }}>{renderBooking(booking)}</div>
        )}
      />
    ),
  };

  return (
    <Card
      title={t('common:jobs')}
      styles={{ body: { overflowY: 'auto', height: '75vh', padding: 8 } }}
      tabList={tabList}
      tabProps={{ size: 'small' }}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      {error?.code === 'FORBIDDEN' && (
        <Alert
          showIcon
          type='error'
          message={t('common:accountDeactivated')}
          description={t('common:accountDeactivatedDescription')}
        />
      )}
      {contentList[activeTabKey]}
    </Card>
  );
}
