import { Select } from 'antd';
import { t } from 'i18next';

import { Customer } from '@/models';

type SelectCustomerProps = {
  selectedCustomer: string | undefined;
  customers?: Customer[];
  onChange: (value?: string) => void;
  isDisabled?: boolean;
};

export const SelectCustomer = ({
  selectedCustomer,
  customers,
  onChange,
  isDisabled,
}: SelectCustomerProps) => {
  return (
    <Select
      aria-label='Kunde auswählen'
      disabled={isDisabled}
      showSearch
      value={selectedCustomer}
      placeholder={t('forms:select')}
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={customers?.map((s) => ({
        value: s.id,
        label: `${s.company} (${s.number})`,
      }))}
      onChange={onChange}
      onClear={() => onChange(undefined)}
      allowClear
    />
  );
};
