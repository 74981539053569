import { CustomerServiceOutlined, MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';

import { environment } from '@/environments';

export function JiraHelpButtons() {
  return (
    <FloatButton.Group trigger='hover' type='primary' icon={<QuestionCircleOutlined />}>
      <FloatButton
        icon={<MailOutlined />}
        href={`mailto:${environment.jiraPortalMail}`}
        tooltip={'Support Mail'}
      />
      <FloatButton
        icon={<CustomerServiceOutlined />}
        href={environment.jiraPortalUrl}
        target={'_blank'}
        tooltip={'Support Portal'}
      />
    </FloatButton.Group>
  );
}
