import { Card, List } from 'antd';
import { CardTabListType } from 'antd/es/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ResourceBooking } from '@/models';
import { logger } from '@/utils';
import { useMyBookings } from '@/web-api/bookings';

import {
  DocumentBookingCardItem,
  OnsiteBookingCardItem,
  PhoneBookingCardItem,
  RemoteBookingCardItem,
} from '../components';

export function AppBookingsPage() {
  const [activeTabKey, setActiveTabKey] = useState('bookings');

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/app/service-worker.js', { scope: '/app/' })
      .then((registration) => {
        logger(`Service worker registered: ${String(registration)}`, 'info');
      })
      .catch((error) => {
        logger(`Service worker registration failed:', ${String(error)}`, 'error');
      });
  }

  const { t } = useTranslation();
  const { data } = useMyBookings({ status: 'current' });
  const pastBookings = useMyBookings({ status: 'past' });

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  function renderBooking(booking: ResourceBooking) {
    const extra = <Link to={booking.id}>{t('common:moreInfo')}</Link>;
    switch (booking.type) {
      case 'ONSITE':
        return <OnsiteBookingCardItem jobBooking={booking} isJob={false} extra={extra} />;
      case 'REMOTE':
        return <RemoteBookingCardItem jobBooking={booking} isJob={false} extra={extra} />;
      case 'PHONE':
        return <PhoneBookingCardItem jobBooking={booking} isJob={false} extra={extra} />;
      case 'DOCUMENT':
        return <DocumentBookingCardItem jobBooking={booking} isJob={false} extra={extra} />;
      default:
        return null;
    }
  }

  const tabList: CardTabListType[] = [
    {
      key: 'bookings',
      tab: t('common:upcomingBookings'),
    },
    {
      key: 'pastBookings',
      tab: t('common:pastBookings'),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    bookings: (
      <List
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 4,
        }}
        dataSource={data}
        renderItem={(booking: ResourceBooking) => renderBooking(booking)}
      />
    ),
    pastBookings: (
      <List
        grid={{
          gutter: 12,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={pastBookings.data}
        renderItem={(booking: ResourceBooking) => renderBooking(booking)}
      />
    ),
  };

  return (
    <Card
      title={t('common:bookings')}
      styles={{ body: { overflowY: 'auto', height: '75vh' } }}
      tabList={tabList}
      tabProps={{ size: 'small' }}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}
