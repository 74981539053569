import { Navigate, useLocation } from 'react-router-dom';

import { MainLayout } from '@/components/layout';

import 'dayjs/locale/de';

export function AdminIndex() {
  const location = useLocation();

  if (location.pathname === '/admin' || location.pathname === '/admin/') {
    return <Navigate to='/admin/bookings' replace />;
  }

  return <MainLayout />;
}
