import { StarFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

type SpecialRateHintProps = {
  specialRate?: number;
};

const { Title, Paragraph } = Typography;

export function SpecialRateHint({ specialRate }: SpecialRateHintProps) {
  const { t } = useTranslation();

  if (!specialRate) return null;

  return (
    <div style={{ border: '1px solid gold', padding: 12, borderRadius: 8, marginBottom: 12 }}>
      <Title level={4}>
        <StarFilled style={{ color: 'gold' }} /> {t('forms:specialPrice')}:&nbsp;
        {specialRate} €
      </Title>
      <Paragraph>{t('forms:specialPriceResourceText')}</Paragraph>
    </div>
  );
}
