import { useQueryClient } from '@tanstack/react-query';
import { Button, DatePicker, Row, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { Customer } from '@/models';
import { MONTH_YEAR_FORMAT, showMessage } from '@/utils';
import { useBillables } from '@/web-api/billing';
import { useCreateInvoice } from '@/web-api/billing/useCreateInvoice';

import { CustomerBillablesList } from './CustomerBillablesList';

import { SelectCustomerBilling } from '.';

const { Text } = Typography;

export function BillablePage() {
  const [selectedCustomers, setSelectedCustomers] = useState<Record<string, boolean>>({});
  const [selectedBookings, setSelectedBookings] = useState<Record<string, boolean>>({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedCustomer, setSelectedCustomer] = useState<Customer['id']>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const billableQuery = useBillables({
    month: selectedDate.month(),
    year: selectedDate.year(),
    customerId: selectedCustomer,
  });
  const createInvoiceMutation = useCreateInvoice();

  function onMonthChange(date: Dayjs) {
    if (date) {
      setSelectedDate(date);
    }
  }

  function onCreateInvoice() {
    createInvoiceMutation.mutate(
      { ids: Object.keys(selectedBookings) },
      {
        onSuccess: () => {
          showMessage('success', t('forms:createInvoiceSuccess'));
          setSelectedBookings({});
          setSelectedCustomers({});
          void queryClient.invalidateQueries({ queryKey: queryKeys.billing.all });
        },
      }
    );
  }

  return (
    <Space direction='vertical' size='large' style={{ width: '100%' }}>
      <Row align='middle' justify='space-between'>
        <SelectCustomerBilling customerState={[selectedCustomer, setSelectedCustomer]} />
        <DatePicker
          picker='month'
          value={selectedDate}
          onChange={onMonthChange}
          format={MONTH_YEAR_FORMAT}
          style={{ width: '25%' }}
        />
      </Row>
      <Row align='middle' justify='space-between'>
        <Text>{t('forms:createInvoiceText')}</Text>
        <Space>
          {Object.keys(selectedBookings).length > 0 && (
            <strong>
              {t('common:selectedBookings_other', {
                count: Object.keys(selectedBookings).length,
              })}
            </strong>
          )}
          <Button
            type='primary'
            onClick={onCreateInvoice}
            disabled={Object.keys(selectedBookings).length === 0 || createInvoiceMutation.isPending}
          >
            {t('forms:createInvoice')}
          </Button>
        </Space>
      </Row>

      <CustomerBillablesList
        customerState={[selectedCustomers, setSelectedCustomers]}
        bookingState={[selectedBookings, setSelectedBookings]}
        chargeable={billableQuery.data?.billable ?? []}
      />
    </Space>
  );
}
