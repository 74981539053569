/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function resendCancelLink(bookingId: string) {
  return getSMPBackendAPIAdmin().mailsControllerSentCustomerCancellationLink(bookingId);
}

export function useSendCancelLink() {
  return useMutation({ mutationFn: resendCancelLink });
}
