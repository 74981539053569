import { useQueryClient } from '@tanstack/react-query';
import { Button, Form, Row, Space, Switch, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { OpeningHourDay, Setting } from '@/models';
import { showMessage } from '@/utils';
import { useCreateUpdateOpeningHours } from '@/web-api/settings/useCreateUpdateOpeningHours';

type FormValues = {
  [key in OpeningHourDay]: {
    id: string;
    open: boolean;
    times: [Dayjs, Dayjs];
  };
};

type OpeningHoursProps = {
  setting?: Setting['openingHours'];
};

export function OpeningHours({ setting }: OpeningHoursProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const initialValues =
    setting?.reduce((acc, curr) => {
      acc[curr.day] = {
        id: curr.id,
        open: !!curr.isOpen,
        times: [dayjs(curr.openTime, 'HH:mm'), dayjs(curr.closeTime, 'HH:mm')],
      };
      return acc;
    }, {} as FormValues) ?? {};

  const openingHoursMutation = useCreateUpdateOpeningHours();

  function onFinish(values: FormValues) {
    const openingHours = Object.entries(values).map(([day, { id, open, times }]) => ({
      id,
      day: day as OpeningHourDay,
      isOpen: !!open,
      openTime: times?.[0]?.format('HH:mm') ?? null,
      closeTime: times?.[1]?.format('HH:mm') ?? null,
    }));
    openingHoursMutation.mutate(
      { openingHours },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
          showMessage('success', t('forms:saved'));
        },
      }
    );
  }

  const daysArray: OpeningHourDay[] = [];

  for (const key in OpeningHourDay) {
    if (Object.prototype.hasOwnProperty.call(OpeningHourDay, key)) {
      daysArray.push(OpeningHourDay[key as keyof typeof OpeningHourDay]);
    }
  }

  return (
    <Form
      form={form}
      requiredMark={false}
      onFinish={onFinish}
      labelCol={{ span: 3 }}
      labelAlign='left'
      colon={false}
      initialValues={initialValues}
    >
      {daysArray.map((day) => (
        <Form.Item key={day} label={t(`common:${day.toLocaleLowerCase()}`)}>
          <Form.Item name={[day, 'id']} hidden>
            <input />
          </Form.Item>
          <Space size='large'>
            <Form.Item name={[day, 'open']} valuePropName='checked' noStyle>
              <Switch checkedChildren={t('forms:open')} unCheckedChildren={t('forms:closed')} />
            </Form.Item>
            <Form.Item
              name={[day, 'times']}
              noStyle
              rules={[{ required: true, message: t('forms:openingHoursMessage') }]}
            >
              <TimePicker.RangePicker
                placeholder={[t('forms:opensAt'), t('forms:closesAt')]}
                needConfirm={false}
                format={'HH:mm'}
              />
            </Form.Item>
          </Space>
        </Form.Item>
      ))}
      <Row justify='end'>
        <Button onClick={() => form.resetFields()} style={{ marginRight: 12 }}>
          {t('forms:reset')}
        </Button>
        <Button type='primary' onClick={form.submit}>
          {t('forms:save')}
        </Button>
      </Row>
    </Form>
  );
}
