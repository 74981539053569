import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_de from './locales/de-DE/common.json';
import errorCodes_de from './locales/de-DE/errorCodes.json';
import forms_de from './locales/de-DE/forms.json';
import header_de from './locales/de-DE/header.json';
import common_en from './locales/en-GB/common.json';
import errorCodes_en from './locales/en-GB/errorCodes.json';
import forms_en from './locales/en-GB/forms.json';
import header_en from './locales/en-GB/header.json';

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      'de-DE': { common: common_de, forms: forms_de, header: header_de, errorCodes: errorCodes_de },
      'en-GB': { common: common_en, forms: forms_en, header: header_en, errorCodes: errorCodes_en },
    },
    defaultNS: 'common',
    fallbackLng: 'de-DE',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
