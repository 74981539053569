/* eslint-disable @typescript-eslint/no-floating-promises */
import { ContactsOutlined } from '@ant-design/icons';
import { useResponsive } from 'ahooks';
import { Button, Checkbox, Form, Modal } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { queryClient, queryKeys } from '@/lib/react-query';
import { Resource } from '@/models/resource.model';
import { showMessage } from '@/utils';
import { useCreateResource } from '@/web-api/resources';

import { ResourceForm } from './ResourceForm';

export const CreateResourceModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breakpoint = useResponsive();

  const [form] = Form.useForm<Resource>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRageMode, setIsRageMode] = useState(false);
  const createResourceMutation = useCreateResource();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onOk = () => form.submit();
  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFormFinish = (resource: Resource) => {
    createResourceMutation.mutate(resource, {
      onSuccess: (data) => {
        showMessage('success', t('forms:saved'));
        queryClient.invalidateQueries({ queryKey: queryKeys.resources.all });
        form.resetFields();
        setIsModalOpen(isRageMode);
        !isRageMode && navigate(data.id);
      },
    });
  };

  return (
    <Fragment>
      <Button type='primary' icon={<ContactsOutlined />} onClick={() => showModal()}>
        {t('common:newResource')}
      </Button>
      <Modal
        title={t('common:newResource')}
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        okText={t('forms:save')}
        destroyOnClose={true}
        centered
        maskClosable={false}
        width={breakpoint.lg ? '50vw' : '85vw'}
      >
        <ResourceForm form={form} onFinish={onFormFinish} isEditingMode={true} mode='create' />
        <Checkbox checked={isRageMode} onChange={(e) => setIsRageMode(e.target.checked)}>
          {t('common:createMoreResources')}
        </Checkbox>
      </Modal>
    </Fragment>
  );
};
