import { useQuery } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';
import { toSurcharge } from '@/services';

function getCancelResourceBooking(bookingId: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerGetCancellationFees(bookingId);
}

export function useGetCancelResourceBooking(bookingId: string) {
  return useQuery({
    queryKey: ['getCancelBookingResource', bookingId],
    queryFn: () => getCancelResourceBooking(bookingId),
    enabled: !!bookingId,
    select: (data) => toSurcharge(data),
  });
}
