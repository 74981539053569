import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { DashboardBooking } from '@/models';
import { ReportsControllerGetBookingReportParams, getSMPBackendAPIAdmin } from '@/proxy';
import { toDashboardBooking } from '@/services';

function getDashboardBookings(params: ReportsControllerGetBookingReportParams) {
  return getSMPBackendAPIAdmin().reportsControllerGetBookingReport(params);
}

export function useDashboardBookings(params: ReportsControllerGetBookingReportParams) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.dashboard.bookings(params),
    queryFn: () => getDashboardBookings(params),
    staleTime: 86400000,
    initialData: () =>
      queryClient.getQueryData<DashboardBooking>(queryKeys.dashboard.bookings(params)),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKeys.dashboard.bookings(params))?.dataUpdatedAt,
    select: (data) => toDashboardBooking(data),
  });
}
