import {
  DefaultOptions,
  MutationCache,
  QueryCache,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import { PromiseValue } from 'type-fest';

import { environment } from '@/environments';
import {
  CustomersControllerFindAllWithPaginationParams,
  CustomersControllerFindCustomerBookingsParams,
  InvoiceControllerGetChargeableServiceBookingsByCustomerParams,
  InvoiceControllerGetInvoicesParams,
  ReportsControllerGetBookingReportParams,
  ReportsControllerGetRequestReportParams,
} from '@/proxy';
import { showErrorMessage, showMessage } from '@/utils';
import { GetBookingsParams } from '@/web-api/bookings';
import { GetBillableBookingsParams } from '@/web-api/bookings/useBillableBooking';
import { GetResourcesParams } from '@/web-api/resources';

export interface BackendError {
  code: string;
}

const queryConfig: DefaultOptions = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: environment.development ? false : 'always',
    retry: false,
  },
};

export const defaultStaleTime = 1000 * 60 * 5;

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  queryCache: new QueryCache({
    onError: (error) => {
      let errorMessage: string;
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      } else if (typeof error === 'object' && error !== null && 'code' in error) {
        const backendError = error as BackendError;
        errorMessage = backendError.code;
      } else {
        errorMessage = 'An error occurred';
      }
      showMessage('error', i18next.t(showErrorMessage(errorMessage)));
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      console.log(error);
      let errorMessage: string;
      if (error instanceof Error && 'message' in error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      } else if (typeof error === 'object' && error !== null && 'code' in error) {
        const backendError = error as BackendError;
        errorMessage = backendError.code;
      } else {
        errorMessage = 'An error occurred';
      }
      showMessage('error', i18next.t(showErrorMessage(errorMessage)));
    },
  }),
});

export type QueryConfig<FetcherFnType extends (...args: any) => any> = UseQueryOptions<
  PromiseValue<ReturnType<FetcherFnType>>
>;

export type MutationConfig<FetcherFnType extends (...args: any) => any> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  AxiosError,
  Parameters<FetcherFnType>[0]
>;

// Query Key factories => https://tkdodo.eu/blog/effective-react-query-keys
export const queryKeys = {
  currentUser: {
    all: ['currentUser'] as const,
  },
  users: {
    all: ['users'] as const,
    lists: () => [...queryKeys.users.all, 'list'] as const,
    details: () => [...queryKeys.users.all] as const,
    detail: (id: string) => [...queryKeys.users.details(), id] as const,
  },
  resources: {
    all: ['resources'] as const,
    lists: (params?: GetResourcesParams) => [...queryKeys.resources.all, 'list', params] as const,
    details: () => [...queryKeys.resources.all] as const,
    detail: (id: string) => [...queryKeys.resources.details(), id] as const,
  },
  languages: {
    all: ['languages'] as const,
    lists: (archived: boolean) => [...queryKeys.languages.all, 'list', archived] as const,
    public: () => [...queryKeys.languages.all, 'public'] as const,
    details: () => [...queryKeys.languages.all] as const,
    detail: (id: string) => [...queryKeys.languages.details(), id] as const,
  },
  bookings: {
    all: ['bookings'] as const,
    lists: (params?: GetBookingsParams) => [...queryKeys.bookings.all, 'list', params] as const,
    details: () => [...queryKeys.bookings.all] as const,
    detail: (id: string) => [...queryKeys.bookings.details(), id] as const,
  },
  jobs: {
    all: ['jobs'] as const,
    lists: () => [...queryKeys.jobs.all, 'list'] as const,
    requestLists: () => [...queryKeys.jobs.all, 'requestList'] as const,
    requests: () => [...queryKeys.jobs.all, 'requests'] as const,
    request: (id: string) => [...queryKeys.jobs.requests(), id] as const,
    details: () => [...queryKeys.jobs.all] as const,
    detail: (id: string) => [...queryKeys.jobs.details(), id] as const,
  },
  resourceBookings: {
    all: ['resourceBookings'] as const,
    lists: (params: string) => [...queryKeys.resourceBookings.all, 'list', params] as const,
    details: () => [...queryKeys.resourceBookings.all] as const,
    detail: (id: string) => [...queryKeys.resourceBookings.details(), id] as const,
    billable: (params: GetBillableBookingsParams) =>
      [...queryKeys.billing.all, 'list', 'billable', params] as const,
  },
  customers: {
    all: ['customers'] as const,
    lists: () => [...queryKeys.customers.all, 'list'] as const,
    pagination: (params: CustomersControllerFindAllWithPaginationParams) =>
      [...queryKeys.customers.all, params, 'list'] as const,
    details: () => [...queryKeys.customers.all] as const,
    detail: (id: string) => [...queryKeys.customers.details(), id] as const,
    bookings: (id: string, params: CustomersControllerFindCustomerBookingsParams) =>
      [...queryKeys.customers.detail(id), params, 'bookings'] as const,
  },
  settings: {
    all: ['settings'] as const,
    lists: () => [...queryKeys.settings.all, 'list'] as const,
    details: () => [...queryKeys.settings.all] as const,
    detail: (id: string) => [...queryKeys.settings.details(), id] as const,
  },
  billing: {
    all: ['billing'] as const,
    billable: (params: InvoiceControllerGetChargeableServiceBookingsByCustomerParams) =>
      [...queryKeys.billing.all, 'list', 'billable', params] as const,
    invoices: (params: InvoiceControllerGetInvoicesParams) =>
      [...queryKeys.billing.all, 'list', 'invoices', params] as const,
  },
  dashboard: {
    all: ['dashboard'] as const,
    requests: (params: ReportsControllerGetRequestReportParams) =>
      [...queryKeys.dashboard.all, 'requests', params] as const,
    bookings: (params: ReportsControllerGetBookingReportParams) =>
      [...queryKeys.dashboard.all, 'bookings', params] as const,
    export: () => [...queryKeys.dashboard.all, 'export'] as const,
  },
};
