import { ROUTE_CONSTANTS } from '@/routes';

export function checkRoutePath(pathname: string) {
  const paths = [
    ROUTE_CONSTANTS.BOOKINGS_PATH,
    ROUTE_CONSTANTS.LANGUAGES_PATH,
    ROUTE_CONSTANTS.RESOURCES_PATH,
    ROUTE_CONSTANTS.CUSTOMERS_PATH,
    ROUTE_CONSTANTS.USERS_PATH,
    ROUTE_CONSTANTS.SETTINGS_PATH,
    ROUTE_CONSTANTS.BILLING_PATH,
    ROUTE_CONSTANTS.DASHBOARD_PATH,
  ];

  return paths.find((path) => pathname.includes(path)) || '';
}
