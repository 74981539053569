import { Rule } from 'antd/es/form';

/**
 *
 * @param locale
 * @param currency ‚
 * @param value in Cents
 * @returns
 */
export const formatCurrency = (locale = 'de-DE', value: number) => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(value);
};

export const centsToEuros = (cents: number) => cents / 100;
export const eurosToCents = (euros: number) => Math.round(euros * 100);
export const metersToKm = (meters: number) => meters / 1000;
export const kmToMeters = (km: number) => km * 1000;
export const decimalToPercent = (decimal: number) => decimal * 100;
export const percentToDecimal = (percent: number) => percent / 100;

const phoneNumberRegex = new RegExp(
  /^(?:(?:\+|0{0,2})\d{1,3}[\s-]?)?(?:\(\d{1,4}\)|\d{1,4})?[\s-]?\d{1,4}[\s-]?\d{1,9}$/
);

export const validatePhoneNumber = (rule: Rule, value: string) => {
  return new Promise((resolve, reject) => {
    if (phoneNumberRegex.test(value)) {
      resolve(true);
    } else {
      reject(false); // You should put an error message here
    }
  });
};

export function isNumber(value: string | number) {
  return typeof value === 'number';
}
