import { StopOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Resource } from '@/models';

import { SelectMultipleResources } from '../resource';

type AddBlockedResourceProps = {
  resources: Resource[] | undefined;
  onSubmit: (resourceIds: string[]) => void;
};

export const BlockedResourceListFooter = ({ resources, onSubmit }: AddBlockedResourceProps) => {
  const { t } = useTranslation();

  const [selectedResources, setSelectedResources] = useState<string[]>([]);

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} lg={20}>
        <SelectMultipleResources
          selectedResources={selectedResources}
          resources={resources}
          onChange={(value) => setSelectedResources(value)}
        />
      </Col>
      <Col xs={24} lg={4}>
        <Button
          danger
          block
          icon={<StopOutlined />}
          disabled={selectedResources.length === 0}
          onClick={() => {
            onSubmit(selectedResources);
            setSelectedResources([]);
          }}
        >
          {t('common:block')}
        </Button>
      </Col>
    </Row>
  );
};
