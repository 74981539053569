import { useResponsive } from 'ahooks';
import { Descriptions, DescriptionsProps } from 'antd';

import { SurchargeType } from '@/models';
import { formatCurrency } from '@/utils';

import { AppResourceChargesDetailsProps } from '.';

export function AppResourceChargesDetails({ charges }: AppResourceChargesDetailsProps) {
  const breakpoint = useResponsive();

  const items: DescriptionsProps['items'] = charges.flatMap((charge) => {
    // Adding description to each item
    return [
      {
        key: 'name',
        label: charge.name,
        children:
          charge.type === SurchargeType.PERCENTAGE
            ? `${charge.value}%`
            : formatCurrency(undefined, charge.value),
      },
    ];
  });

  return (
    <Descriptions
      size='small'
      bordered
      layout={breakpoint.lg ? 'horizontal' : 'vertical'}
      items={items} // Flatten the array of arrays
      labelStyle={{ fontWeight: 'bolder' }}
      column={1}
    />
  );
}
