import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { ResourceJob } from '@/models';
import { ErrorDto, ResourceJobDto, getSMPBackendAPIAdmin } from '@/proxy';
import { toResourceJob } from '@/services';

function getJobRequest(jobId: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerFindRequestById(jobId);
}

export function useJobRequest(jobId: string) {
  return useQuery<ResourceJobDto, ErrorDto, ResourceJob>({
    queryKey: queryKeys.jobs.request(jobId),
    queryFn: () => getJobRequest(jobId),
    enabled: !!jobId,
    select: (data) => toResourceJob(data),
  });
}
