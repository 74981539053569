import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { TransformBookingRequest, getSMPBackendAPIAdmin } from '@/proxy';

type TransformBookingParams = {
  bookingId: string;
  request: TransformBookingRequest;
};

function transformBooking(params: TransformBookingParams) {
  return getSMPBackendAPIAdmin().serviceBookingsControllerTransform(
    params.bookingId,
    params.request
  );
}

export function useTransformBooking() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: transformBooking,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.all });
    },
  });
}
