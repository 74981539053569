import { Button, List, Tag } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Language } from '@/models';

type LanguageListProps = {
  header?: ReactNode;
  footer?: ReactNode;
  languages?: Language[];
  isLoading: boolean;
  onRemove?: (id: string) => void;
};

export const LanguageList = ({
  header,
  footer,
  languages,
  isLoading,
  onRemove,
}: LanguageListProps) => {
  const { t } = useTranslation();

  return (
    <List
      size='small'
      header={header}
      footer={footer}
      itemLayout='horizontal'
      dataSource={languages}
      loading={isLoading}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Link aria-label='Sprache anzeigen' key={item.id} to={`/admin/languages/${item.id}`}>
              {t('common:show')}
            </Link>,
            onRemove ? (
              <Button
                aria-label='Sprache entfernen'
                type='link'
                danger
                key={`remove-${item.id}`}
                onClick={() => onRemove && onRemove(item.id)}
              >
                {t('common:remove')}
              </Button>
            ) : null,
          ]}
        >
          <List.Item.Meta
            title={
              <>
                {item.isArchived && <Tag color='red'>{t('common:archived')}</Tag>}
                {item.name}
              </>
            }
          />
        </List.Item>
      )}
    />
  );
};
