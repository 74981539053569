import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { DashboardRequest } from '@/models';
import { ReportsControllerGetRequestReportParams, getSMPBackendAPIAdmin } from '@/proxy';
import { toDashboardRequest } from '@/services';

function getDashboardRequests(params: ReportsControllerGetRequestReportParams) {
  return getSMPBackendAPIAdmin().reportsControllerGetRequestReport(params);
}

export function useDashboardRequests(params: ReportsControllerGetRequestReportParams) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.dashboard.requests(params),
    queryFn: () => getDashboardRequests(params),
    select: (data) => toDashboardRequest(data),
    staleTime: 86400000,
    initialData: () =>
      queryClient.getQueryData<DashboardRequest>(queryKeys.dashboard.requests(params)),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKeys.dashboard.requests(params))?.dataUpdatedAt,
  });
}
