import { Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { BookingStatus } from '@/models';
import { getStatusColor } from '@/utils';

type SelectMultipleBookingStatusProps = {
  selectedStatus: BookingStatus[];
  status: BookingStatus[];
  onChange: (value: BookingStatus[]) => void;
  disabled?: boolean;
};

export const SelectMultipleBookingStatus = ({
  selectedStatus,
  status,
  onChange,
  disabled,
}: SelectMultipleBookingStatusProps) => {
  const { t } = useTranslation();
  return (
    <Select
      aria-label='Status filtern'
      mode='multiple'
      value={selectedStatus}
      popupMatchSelectWidth={false}
      disabled={disabled}
      showSearch
      placeholder={t('common:status')}
      optionFilterProp='children'
      maxTagCount={'responsive'}
      options={status.map((s) => ({
        value: s,
        label: <Tag color={getStatusColor(s)}>{t(`common:${s}`)}</Tag>,
      }))}
      onChange={onChange}
      onClear={() => onChange([])}
      allowClear
      style={{ minWidth: 100 }}
    />
  );
};
