import { Col, Row } from 'antd';
import { Fragment, PropsWithChildren } from 'react';

import { CenterSpinner } from '@/components/shared';

import { ContactBox, Footer, Header } from '.';

type WebLayoutProps = PropsWithChildren<{
  isLoading: boolean;
}>;

export function WebLayout(props: WebLayoutProps) {
  return (
    <Fragment>
      <CenterSpinner isLoading={props.isLoading} isRequest />
      <Row justify='center'>
        <Header />
        <Col style={{ padding: 24 }} xs={24} md={14} xxl={12}>
          {props.children}
        </Col>
        <ContactBox isResource={false} />
        <Footer />
      </Row>
    </Fragment>
  );
}
