import { useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, FormInstance, Modal, Space, Typography } from 'antd';
import { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/environments';
import { queryKeys } from '@/lib/react-query';
import { Booking, BookingStatus, Client } from '@/models';
import { isButtonDisabled, showMessage } from '@/utils';
import { useCancelServiceBooking, useSendCancelLink } from '@/web-api/bookings';

const { Paragraph } = Typography;

type CancelBookingButtonProps = {
  isEditingMode: boolean;
  setEditingMode: (value: SetStateAction<boolean>) => void;
  data: Booking;
  form: FormInstance<Booking & { debtorId: string; client: Client }>;
};

export function CancelBookingButton({
  isEditingMode,
  setEditingMode,
  data,
  form,
}: CancelBookingButtonProps) {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isModelOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();
  const cancelServiceBookingMutation = useCancelServiceBooking();
  const resendCancelBookingLinkMutation = useSendCancelLink();
  const queryClient = useQueryClient();

  function showModal() {
    setIsModalOpen(true);
  }

  const onCancelBooking = () => {
    if (data && isCheckboxChecked) {
      cancelServiceBookingMutation.mutate(data.id, {
        onSuccess: () => {
          showMessage('success', t('common:CANCELED'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.all });
          setEditingMode(false);
          setIsModalOpen(false);
          form.resetFields();
        },
      });
    }
  };

  function sendCancelLink() {
    if (data?.id) {
      resendCancelBookingLinkMutation.mutate(data.id, {
        onSuccess: () => {
          showMessage('success', t('forms:linkSent'));
          setIsModalOpen(false);
        },
      });
    }
  }

  const isDisabled =
    isEditingMode ||
    isButtonDisabled(data.status, [
      BookingStatus.OPEN,
      BookingStatus.PUBLISHED,
      BookingStatus.CONFIRMED,
      BookingStatus.REQUESTED,
    ]);

  const showCancelOption = isButtonDisabled(data.status, [
    BookingStatus.OPEN,
    BookingStatus.PUBLISHED,
    BookingStatus.REQUESTED,
  ]);

  return (
    <>
      <Button aria-label='Buchung stornieren' danger disabled={isDisabled} onClick={showModal}>
        {t('common:cancel')}
      </Button>
      <Modal
        title={t('common:cancel')}
        open={isModelOpen}
        okText={t('common:confirm')}
        onOk={onCancelBooking}
        okButtonProps={{ disabled: !isCheckboxChecked, danger: true }}
        onCancel={() => setIsModalOpen(false)}
        closable={true}
      >
        <p>{t('common:sendCancelLinkText')}</p>
        {data?.id && (
          <Space size='large' align='baseline'>
            <Button onClick={sendCancelLink}>{t('common:sendCancelLink')}</Button>
            <Paragraph copyable={{ text: `${environment.baseUrl}/web/cancel/${data.id}` }}>
              {t('common:copyLink')}
            </Paragraph>
          </Space>
        )}
        {!showCancelOption && (
          <>
            <p style={{ marginTop: 24 }}>{t('common:confirmCancelBookingText')}</p>
            <Checkbox
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            >
              {t('common:confirmCancelCheckbox')}
            </Checkbox>
          </>
        )}
      </Modal>
    </>
  );
}
