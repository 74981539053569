import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { BookingType } from '@/models';

type SelectMultipleBookingTypesProps = {
  selectedTypes: BookingType[];
  type: BookingType[];
  onChange: (value: BookingType[]) => void;
  disabled?: boolean;
};

export const SelectMultipleBookingTypes = ({
  selectedTypes,
  type,
  onChange,
  disabled,
}: SelectMultipleBookingTypesProps) => {
  const { t } = useTranslation();
  return (
    <Select
      aria-label='Typ filtern'
      mode='multiple'
      value={selectedTypes}
      popupMatchSelectWidth={false}
      disabled={disabled}
      showSearch
      placeholder={t('forms:type')}
      optionFilterProp='children'
      maxTagCount={'responsive'}
      options={type.map((type) => ({
        value: type,
        label: t(`common:${type}`),
      }))}
      onChange={onChange}
      onClear={() => onChange([])}
      allowClear
      style={{ minWidth: 100 }}
    />
  );
};
