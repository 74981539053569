import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { User } from '@/models';
import { isUserAdmin } from '@/utils';

type UserFormProp = {
  form: FormInstance<User>;
  user?: User;
  onFinish: (resource: User) => void;
  isEditingMode: boolean;
  isProfile?: boolean;
  isCreate?: boolean;
};

export const UserForm = ({
  form,
  user,
  onFinish,
  isEditingMode,
  isProfile,
  isCreate,
}: UserFormProp) => {
  const { t } = useTranslation();
  const { user: auth0User } = useAuth0();
  const isAdmin = isUserAdmin(auth0User);

  return (
    <Form
      form={form}
      initialValues={user}
      layout='vertical'
      disabled={!isEditingMode}
      onFinish={onFinish}
    >
      <Divider orientation='left'>{t('forms:basicInfo')}</Divider>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label={t('forms:email')}
        name='email'
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input disabled={(!isAdmin && !isCreate) || isProfile} />
      </Form.Item>
      <Form.Item label={t('forms:firstname')} name='firstname' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={t('forms:lastname')} name='lastname' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};
