import { Select } from 'antd';
import { t } from 'i18next';

import { Customer } from '@/models';

type SelectMultipleCustomersProps = {
  selectedCustomers: string[];
  customers?: Customer[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  query?: string;
};

export const SelectMultipleCustomers = ({
  selectedCustomers,
  customers,
  onChange,
  disabled,
}: SelectMultipleCustomersProps) => {
  return (
    <Select
      aria-label='Kunden filtern'
      mode='multiple'
      value={selectedCustomers}
      popupMatchSelectWidth={false}
      disabled={disabled}
      maxTagCount={'responsive'}
      showSearch
      placeholder={t('common:customer')}
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={customers?.map((s) => ({
        value: s.id,
        label: `${s.company} (${s.number})`,
      }))}
      onChange={onChange}
      onClear={() => onChange([])}
      allowClear
      style={{ minWidth: 100 }}
    />
  );
};
