import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { ResourceJob } from '@/models';
import { ErrorDto, ResourceJobDto, getSMPBackendAPIAdmin } from '@/proxy';
import { toResourceJob } from '@/services';

function getJobRequests() {
  return getSMPBackendAPIAdmin().resourceBookingsControllerFindRequests();
}

export function useJobRequests() {
  return useQuery<ResourceJobDto[], ErrorDto, ResourceJob[]>({
    queryKey: queryKeys.jobs.requestLists(),
    queryFn: getJobRequests,
    select: (data) => data.map(toResourceJob),
  });
}
