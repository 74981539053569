import { Environment } from './environment.model';

export const environment: Environment = {
  api: import.meta.env.VITE_API,
  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  auth0CallbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
  jiraPortalUrl: import.meta.env.VITE_JIRA_PORTAL_URL,
  jiraPortalMail: import.meta.env.VITE_JIRA_PORTAL_MAIL,
  production: import.meta.env.PROD,
  development: import.meta.env.DEV,
  baseUrl: import.meta.env.VITE_BASE_URL,
  auth0Username: import.meta.env.VITE_AUTH0_USERNAME,
  auth0Password: import.meta.env.VITE_AUTH0_PASSWORD,
  auth0Scope: import.meta.env.VITE_AUTH0_SCOPE,
  auth0Clientid: import.meta.env.VITE_AUTH0_CLIENTID,
  auth0ClientSecret: import.meta.env.VITE_AUTH0_CLIENT_SECRET,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
};
