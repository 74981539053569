import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';

function reactivateResource(resourceId: string) {
  return getSMPBackendAPIAdmin().resourcesControllerReactivate(resourceId);
}

export function useReactivateResource() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: reactivateResource,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.resources.all });
    },
  });
}
