import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMount, useUpdateEffect } from 'ahooks';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SelectLanguage } from '@/components/language';
import { BookingType, Client } from '@/models';
import { WebBooking } from '@/models/web.booking.model';
import { validatePhoneNumber } from '@/utils';
import { useAvailableLanguages } from '@/web-api/public/useAvailableLanguages';

import { BookingSubForm } from '.';

const { Option } = Select;

type WebBookingFormProps = {
  defaultDate?: Dayjs;
  form: FormInstance<WebBooking & { client?: Client }>;
  onFinish: (resource: WebBooking & { client?: Client }) => void;
};

export const WebBookingForm = ({ form, onFinish }: WebBookingFormProps) => {
  const [isNewCustomer, setNewCustomer] = useState<boolean>();
  const [isPaying, setIsPaying] = useState(true);
  const [selectedBookingType, setSelectedBookingType] = useState<BookingType>(BookingType.ONSITE);
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();
  const [selectedAlternativeLanguage, setSelectedAlternativeLanguage] = useState<
    string | undefined
  >();

  const { t } = useTranslation();
  const watchGender = Form.useWatch('gender', form);
  const watchResource = Form.useWatch('resourceNumber', form);
  const watchResourceAlternative = Form.useWatch('acceptAlternativeResource', form);

  useUpdateEffect(() => {
    if (watchResource) {
      form.setFieldsValue({ gender: undefined });
    }
  }, [watchResourceAlternative]);

  const { data: languages } = useAvailableLanguages();

  useMount(() => {
    form.setFieldValue('type', selectedBookingType);
  });

  function handleCustomerStatus(value: 'true' | 'false') {
    setNewCustomer(value === 'true');
  }

  const handleValuesChange = (changedValues: WebBooking & { client?: Client }) => {
    if (changedValues?.client?.birthDate) {
      let value = changedValues.client.birthDate.replace(/\D/g, '');
      if (value.length > 8) value = value.slice(0, 8);
      if (value.length > 4) value = value.slice(0, 4) + '.' + value.slice(4);
      if (value.length > 2) value = value.slice(0, 2) + '.' + value.slice(2);

      form.setFieldsValue({ client: { birthDate: value } });
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      initialValues={{
        acceptAlternativeResource: false,
      }}
    >
      <Divider orientation='left'>{t('common:customer')}</Divider>
      <Select
        title='customer'
        value={isNewCustomer === true ? 'true' : 'false'}
        onChange={handleCustomerStatus}
        style={{ width: '100%', marginBottom: 24 }}
        aria-label='Customer status'
      >
        <Select.Option value='true'>{t('forms:newCustomer')}</Select.Option>
        <Select.Option value='false'>{t('forms:existingCustomer')}</Select.Option>
      </Select>
      {isNewCustomer ? (
        <Row gutter={[12, 12]}>
          <Col xs={24} md={24}>
            <Form.Item
              label={t('forms:company')}
              name={['customer', 'company']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:street')}
              name={['customer', 'street']}
              rules={[{ required: true }]}
            >
              <Input aria-label='Customer street' />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:department')}
              name={['customer', 'supplement']}
              rules={[{ required: false }]}
              tooltip={t('forms:departmentTooltip')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:zip')}
              name={['customer', 'zip']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:city')}
              name={['customer', 'city']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              hidden={true}
              label={t('forms:country')}
              name={['customer', 'country']}
              rules={[{ required: true }]}
              initialValue={'Deutschland'}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:firstname')}
              name={['customer', 'firstName']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:lastname')}
              name={['customer', 'lastName']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:email')}
              name={['customer', 'email']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:phone')}
              name={['customer', 'phone']}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Form.Item
          label={t('forms:customerNumber')}
          name='customerNumber'
          rules={[{ required: true }]}
          tooltip={t('forms:customerNumberTooltip')}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      )}
      <Divider orientation='left'>{t('forms:payment')}</Divider>
      <Row gutter={[12, 12]}>
        <Select
          aria-label='Payment option'
          defaultValue={isPaying}
          onChange={(value) => setIsPaying(value)}
          style={{ width: '100%', marginBottom: 24 }}
        >
          <Select.Option value={true}>{t('forms:paymentCustomer')}</Select.Option>
          <Select.Option value={false}>{t('forms:paymentOtherCustomer')}</Select.Option>
        </Select>
        <Col xs={24} md={24}>
          {!isPaying && (
            <Form.Item
              label={t('forms:debitorNumber')}
              name='debtorNumber'
              rules={[{ required: true }]}
              tooltip={t('forms:debitorNumberTooltip')}
            >
              <Input />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Divider orientation='left'>{t('forms:contactInfo')}</Divider>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={8}>
          <Form.Item
            name={['contactPerson', 'name']}
            label={t('forms:name')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={['contactPerson', 'email']}
            label={t('forms:email')}
            rules={[{ required: true, type: 'email' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={['contactPerson', 'phone']}
            label={t('forms:phone')}
            rules={[
              {
                required: true,
                validator: validatePhoneNumber,
                message: `${t('forms:phoneNumberInvalid')}`,
              },
            ]}
            tooltip={t('forms:urgentMatters')}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>{t('forms:bookingData')}</Divider>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={24}>
          <Form.Item
            label={t('common:sourceLanguage')}
            name='languageId'
            rules={[{ required: true }]}
            tooltip={t('common:sourceLanguageTooltip')}
          >
            <SelectLanguage
              selectedLanguage={selectedLanguage}
              languages={languages}
              onChange={(value) => {
                form.setFieldValue('languageId', value);
                setSelectedLanguage(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <Form.Item
            label={t('common:alternativeLanguage')}
            name='alternativeLanguageId'
            tooltip={t('common:alternativeLanguageTooltip')}
          >
            <SelectLanguage
              isDisabled={!selectedLanguage}
              selectedLanguage={selectedAlternativeLanguage}
              languages={languages?.filter((lang) => lang.id !== selectedLanguage)}
              onChange={(value) => {
                form.setFieldValue('alternativeLanguageId', value);
                setSelectedAlternativeLanguage(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <Form.Item label={t('forms:service')} name='type' rules={[{ required: true }]}>
            <Select
              aria-label='Booking type'
              allowClear
              value={selectedBookingType}
              options={Object.values(BookingType).map((type) => ({
                label: t(`common:${type}`),
                value: type,
              }))}
              onChange={(value) => {
                setSelectedBookingType(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <BookingSubForm type={selectedBookingType} mode='create' />
      {selectedBookingType !== 'DOCUMENT' && (
        <>
          <Divider orientation='left'>{t('forms:clientData')}</Divider>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                label={t('forms:reference')}
                name={['client', 'reference']}
                rules={[{ required: true }]}
                tooltip={t('forms:referenceHelp')}
                extra={t('forms:referenceExtra')}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:name')} name={['client', 'name']}>
                <Input aria-label='Client name' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:birthdate')}
                name={['client', 'birthDate']}
                rules={[
                  {
                    pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                    message: t('forms:birthdateInvalid'),
                  },
                ]}
              >
                <Input aria-label='Client birthdate' />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Divider orientation='left'>{t('common:resourceInformation')}</Divider>
      <Form.Item
        label={t('common:specificResource')}
        name='resourceNumber'
        tooltip={t('common:specificResourceTooltip')}
      >
        <InputNumber
          aria-label='Resource number'
          style={{ width: '100%' }}
          disabled={!!watchGender && !watchResourceAlternative}
        />
      </Form.Item>
      {watchResource && (
        <Form.Item name='acceptAlternativeResource' valuePropName='checked'>
          <Checkbox>{t('common:specificResourceCheckbox')}</Checkbox>
        </Form.Item>
      )}
      <Form.Item label={t('common:gender')} name='gender'>
        <Select disabled={!!watchResource && !watchResourceAlternative} allowClear>
          <Option value='MALE'>{t('common:male')}</Option>
          <Option value='FEMALE'>{t('common:female')}</Option>
        </Select>
      </Form.Item>
      <Divider orientation='left'>{t('common:furtherInformation')}</Divider>
      <Form.Item label={t('forms:topic')} name='notes' rules={[{ required: false }]}>
        <Input.TextArea placeholder={t('forms:topicPlaceholder')} />
      </Form.Item>
      <Form.Item
        name='termsAndConditions'
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t('common:termsConditionsPrivacyWarning'))),
          },
        ]}
        valuePropName='checked'
      >
        <Checkbox>
          <Trans
            i18nKey='common:termsAndConditions'
            components={[
              <a key={'1'} href='http://smp-berlin.de/konditionen' target='_blank' rel='noreferrer'>
                here
              </a>,
            ]}
          />
        </Checkbox>
      </Form.Item>
      <Form.Item
        name='privacyPolicy'
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t('common:termsConditionsPrivacyWarning'))),
          },
        ]}
        valuePropName='checked'
      >
        <Checkbox>
          <Trans
            i18nKey='common:privacyPolicy'
            components={[
              <a key={'1'} href='http://smp-berlin.de/datenschutz' target='_blank' rel='noreferrer'>
                here
              </a>,
            ]}
          />
        </Checkbox>
      </Form.Item>
      <Space align='baseline'>
        <Form.Item name='rememberMe' valuePropName='checked'>
          <Checkbox>{t('forms:rememberMe')}</Checkbox>
        </Form.Item>
        <Tooltip title={t('forms:rememberMeTooltip')}>
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
    </Form>
  );
};
