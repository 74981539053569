/* eslint-disable @typescript-eslint/no-floating-promises */
import { FlagOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, Form, Modal } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { showMessage } from '@/utils';
import { useCreateLanguage } from '@/web-api/languages';

import { LanguageForm } from './LanguageForm';

export type FormLanguage = {
  id?: string;
  name: string;
  onsiteRate: number | null;
  remoteRate: number | null;
  documentRate: number | null;
  phoneRate: number | null;
};

export const CreateLanguageModal = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [form] = Form.useForm<FormLanguage>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRageMode, setIsRageMode] = useState(false);
  const createLanguageMutation = useCreateLanguage();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onOk = () => form.submit();
  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFormFinish = (language: FormLanguage) => {
    createLanguageMutation.mutate(language, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        queryClient.invalidateQueries({ queryKey: queryKeys.languages.all });
        form.resetFields();
        setIsModalOpen(isRageMode);
      },
    });
  };

  return (
    <Fragment>
      <Button
        aria-label='Neue Sprache'
        type='primary'
        icon={<FlagOutlined />}
        onClick={() => showModal()}
      >
        {t('common:newLanguage')}
      </Button>
      <Modal
        title={t('common:newLanguage')}
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        okText={t('forms:save')}
        destroyOnClose={true}
        centered
        maskClosable={false}
      >
        <LanguageForm
          form={form}
          onFinish={onFormFinish}
          isEditingMode
          isCreateModal
          isArchived={false}
          isRenameable={false}
        />
        <Checkbox checked={isRageMode} onChange={(e) => setIsRageMode(e.target.checked)}>
          {t('common:createMoreServices')}
        </Checkbox>
      </Modal>
    </Fragment>
  );
};
