/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Button, Result } from 'antd';
import type { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@/models';
import { showErrorMessage } from '@/utils';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation('common');
  switch (error.code) {
    case ErrorMessage.UNAUTHORIZED:
    case ErrorMessage.FORBIDDEN:
      return (
        <Result
          status='403'
          title='403'
          subTitle={t('errorBoundary403SubTitle')}
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              {t('backToHome')}
            </Button>
          }
        />
      );
    case ErrorMessage.PRISMA_RECORD_NOT_FOUND:
      return (
        <Result
          status='404'
          title='404'
          subTitle={t('errorBoundary404SubTitle')}
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              {t('backToHome')}
            </Button>
          }
        />
      );
    default:
      return (
        <Result
          status='warning'
          title={t(showErrorMessage(error.code))}
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              {t('backToHome')}
            </Button>
          }
        />
      );
  }
}

export default ErrorFallback;
