import { useQuery, useQueryClient } from '@tanstack/react-query';

import { defaultStaleTime, queryKeys } from '@/lib/react-query';
import { BookingStatus } from '@/models';
import { ServiceBookingGroupDto, getSMPBackendAPIAdmin } from '@/proxy';
import { toBookingGroup } from '@/services';

export type GetBookingsParams = {
  types?: string[];
  customers?: string[];
  languages?: string[];
  resources?: string[];
  status?: BookingStatus[];
  year: number;
  month: number;
};

function getBookings(params: GetBookingsParams) {
  return getSMPBackendAPIAdmin().serviceBookingsControllerFindAll(params);
}

export function useBookings(params: GetBookingsParams) {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: queryKeys.bookings.lists(params),
    queryFn: () => getBookings(params),
    select: (data) => data.map(toBookingGroup),
    staleTime: defaultStaleTime,
    initialData: () =>
      queryClient.getQueryData<ServiceBookingGroupDto[]>(queryKeys.bookings.lists(params)),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKeys.bookings.lists(params))?.dataUpdatedAt,
  });
}
