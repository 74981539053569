/* eslint-disable react-hooks/exhaustive-deps */
import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { environment } from '@/environments';

export function useAutoFillFormOnCtrlD(form: FormInstance) {
  useEffect(() => {
    if (environment.development) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'd') {
          event.preventDefault();
          form.setFieldsValue({
            customerNumber: 99999,
            contactPerson: {
              name: 'Max Mustermann',
              email: 'development@actdigital.io',
              phone: '030 12345678',
            },
            client: {
              name: 'Max Maximus',
              birthDate: '01.01.1990',
              reference: 'Keine',
            },
            termsAndConditions: true,
            privacyPolicy: true,
            date: dayjs().add(1, 'hour'),
            onsite: {
              duration: 60,
              location: {
                name: 'Jugendamt Neukölln',
                street: 'Karl-Marx-Straße 83',
                supplement: 'Raum 123',
                zip: '12043',
                city: 'Berlin',
              },
            },
            notes:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, vitae aliquam ni',
          });
        }
      };

      window.addEventListener('keydown', handleKeyDown);

      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, []);
}
