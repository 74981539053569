import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import { Setting } from '@/models';

import { BookingTypeRates } from './BookingTypeRates';
import { ExpressSurcharges } from './ExpressSurcharges';
import { TimeSurcharges } from './TimeSurcharges';
import { TravelSurcharges } from './TravelSurcharges';

type RatesProps = {
  setting: Setting;
};

export function Rates({ setting }: RatesProps) {
  const { t } = useTranslation('common');

  const items = [
    {
      key: 'rates',
      label: t('defaultPriceRates'),
      children: <BookingTypeRates rates={setting.languageRates} />,
    },
    {
      key: 'expressSurcharges',
      label: t('expressSurcharges'),
      children: <ExpressSurcharges expressSurcharges={setting.expressSurcharges} />,
    },
    {
      key: 'travelSurcharges',
      label: t('travelSurcharges'),
      children: <TravelSurcharges travelSurcharges={setting.travelSurcharges} />,
    },
    {
      key: 'timeSurcharges',
      label: t('timeSurcharges'),
      children: <TimeSurcharges timeSurcharges={setting.dayDateSurcharges} />,
    },
  ];

  return <Collapse defaultActiveKey={['rates']} items={items} />;
}
