import { Select } from 'antd';
import { t } from 'i18next';

import { Language } from '@/models';

type SelectMultipleLanguagesProps = {
  selectedLanguages: string[];
  languages?: Language[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
};

export const SelectMultipleLanguages = ({
  selectedLanguages,
  languages,
  onChange,
  disabled,
}: SelectMultipleLanguagesProps) => {
  return (
    <Select
      aria-label='Sprachen auswählen'
      mode='multiple'
      value={selectedLanguages}
      popupMatchSelectWidth={false}
      maxTagCount={'responsive'}
      disabled={disabled}
      showSearch
      placeholder={t('common:language')}
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={languages?.map((language) => ({
        value: language.id,
        label: language.name,
      }))}
      onChange={onChange}
      onClear={() => onChange([])}
      allowClear
      style={{ minWidth: 100 }}
    />
  );
};
