import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { ResourceJob } from '@/models';
import { ErrorDto, ResourceJobDto, getSMPBackendAPIAdmin } from '@/proxy';
import { toResourceJob } from '@/services';

function getJobs() {
  return getSMPBackendAPIAdmin().resourceBookingsControllerFindJobs();
}

export function useJobs() {
  return useQuery<ResourceJobDto[], ErrorDto, ResourceJob[]>({
    queryKey: queryKeys.jobs.lists(),
    queryFn: getJobs,
    select: (data) => data.map(toResourceJob),
  });
}
