import { useResponsive } from 'ahooks';
import { Descriptions, DescriptionsProps, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BookingStatus, ResourceBooking } from '@/models';
import { DATETIME_WITH_DAY_FORMAT, displayEmptyString } from '@/utils';

import { AppDetailsContentProps } from '.';

const { Link } = Typography;

export function AppPhoneDetailsContent({ jobBooking, isJob }: AppDetailsContentProps) {
  const { t } = useTranslation();
  const breakpoint = useResponsive();
  const isVerified = jobBooking.status === BookingStatus.VERIFIED;
  const inHours = Number(
    (((jobBooking as ResourceBooking)?.verification?.value ?? 0) / 60).toFixed(2)
  );

  const items: DescriptionsProps['items'] = [
    {
      key: 'reference',
      label: t('common:reference'),
      children: t(jobBooking.reference),
    },
    {
      key: 'type',
      label: t('common:bookingType'),
      children: t(jobBooking.type),
    },
    {
      key: 'language',
      label: t('common:language'),
      children: jobBooking.language,
    },
    ...(jobBooking.alternativeLanguage
      ? [
          {
            key: 'alternativeLanguage',
            label: t('common:alternativeLanguage'),
            children: jobBooking.alternativeLanguage,
          },
        ]
      : []),
    {
      key: 'date',
      label: t('forms:startTime'),
      children: dayjs(jobBooking.date).format(DATETIME_WITH_DAY_FORMAT),
    },
    {
      key: 'duration',
      label: t('forms:duration'),
      children: t('minuteWithCount', {
        count: jobBooking.phone?.duration,
      }),
    },
    ...((jobBooking as ResourceBooking).phone?.dialInNumber
      ? [
          {
            key: 'dialInNumber',
            label: t('common:dialInNumber'),
            children: (
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              <Link href={`tel:${(jobBooking as ResourceBooking)?.phone?.dialInNumber}`}>
                {(jobBooking as ResourceBooking)?.phone?.dialInNumber}
              </Link>
            ),
          },
          {
            key: 'zoomConferenceNumber',
            label: t('common:zoomConferenceNumber'),
            children: (jobBooking as ResourceBooking)?.phone?.zoomConferenceNumber,
          },
          {
            key: 'zoomConferenceId',
            label: t('common:zoomConferenceId'),
            children: (jobBooking as ResourceBooking)?.phone?.zoomConferenceId,
          },
        ]
      : []),
    {
      key: 'notes',
      label: t('forms:topic'),
      children: displayEmptyString(jobBooking.notes ?? '', t('common:noData')),
    },
    ...(jobBooking.gender
      ? [
          {
            key: 'gender',
            label: t('common:gender'),
            children: t(`common:${jobBooking.gender.toString().toLowerCase()}`),
          },
        ]
      : []),
    {
      key: 'contactPerson',
      label: t('common:contactPerson'),
      children: jobBooking.contactPerson.name,
    },
    ...(!isJob
      ? [
          {
            key: 'customer',
            label: t('common:customer'),
            children: (jobBooking as ResourceBooking).customer,
          },
        ]
      : []),
    ...(isVerified
      ? [
          {
            key: 'verified',
            label: t('common:VERIFIED'),
            children: (
              <>
                <strong>
                  {t('minuteWithCount', {
                    count: (jobBooking as ResourceBooking).verification?.value,
                  })}
                  &nbsp; (
                  {t('hourWithCount', {
                    count: inHours,
                  })}
                  )
                </strong>
                <br />
                {t('forms:startTime')}:&nbsp;
                {(jobBooking as ResourceBooking).verification?.startTime?.format(
                  DATETIME_WITH_DAY_FORMAT
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <Descriptions
      size='small'
      bordered
      layout={breakpoint.lg ? 'horizontal' : 'vertical'}
      items={items}
      labelStyle={{ fontWeight: 'bolder' }}
      column={1}
    />
  );
}
