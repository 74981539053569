import { App, Button, Col, Row, Space, Typography } from 'antd';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDelayedLoading } from '@/hooks';
import { formatCurrency, showErrorMessage } from '@/utils';
import { useCancelWebBooking, useGetCancelWebBooking } from '@/web-api/bookings';

import { WebLayout } from '.';

const { Title, Paragraph } = Typography;

export function WebCancelForm() {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { bookingId } = useParams();
  if (bookingId === undefined) {
    throw new Error('Expected bookingId to be defined');
  }

  const getCancelWebBookingQuery = useGetCancelWebBooking(bookingId);
  const cancelWebBookingMutation = useCancelWebBooking();

  const showDelayedLoading = useDelayedLoading(cancelWebBookingMutation.isPending);

  const totalAddedCharges = getCancelWebBookingQuery.data?.cancelationCharges.reduce(
    (acc, curr) => acc + curr.value,
    0
  );
  0;

  function onCancel() {
    bookingId &&
      cancelWebBookingMutation.mutate(bookingId, {
        onSuccess: () => {
          void modal.success({
            title: t('forms:bookingCancellationFormSuccessTitle'),
            content: t('forms:bookingCancellationFormSuccessDescription'),
            onOk: () => {
              window.location.href = '/web/request';
            },
          });
        },
      });
  }

  if (getCancelWebBookingQuery.isError) {
    return (
      <Row justify='center' align='middle' style={{ height: '100vh', textAlign: 'center' }}>
        <Col span={16}>
          <Title level={1}>{t(showErrorMessage(getCancelWebBookingQuery.error.code))}</Title>
        </Col>
      </Row>
    );
  }

  return (
    <WebLayout isLoading={showDelayedLoading}>
      <Fragment>
        <Title level={1}>{t('forms:bookingCancellationFormTitle')}</Title>
        <Paragraph>{t('forms:bookingCancellationFormWelcome')}</Paragraph>
        <Title level={2}>{t('forms:bookingCancellationFormNotice')}</Title>
        <Paragraph>{t('forms:bookingCancellationFormNoticeDescription')}</Paragraph>
        <Title level={2}>{t('common:cancellationFees')}</Title>
        <div
          style={{
            padding: '2rem',
            backgroundColor: '#e2e8f0',
            borderRadius: 12,
            textAlign: 'center',
          }}
        >
          {getCancelWebBookingQuery.data?.cancelationCharges.map((charge, index) => (
            <Space key={index} style={{ fontStyle: 'italic' }}>
              <span>{charge.name}</span>
              <span>{formatCurrency(undefined, charge.value)}</span>
            </Space>
          ))}
          <Title level={3} underline>
            Total: {formatCurrency(undefined, totalAddedCharges ?? 0)}
          </Title>
        </div>
        <Col style={{ marginTop: 24, textAlign: 'center' }} span={24}>
          <Button type='primary' size='large' danger onClick={onCancel} aria-label='Cancel booking'>
            {t('common:cancel')}
          </Button>
        </Col>
      </Fragment>
    </WebLayout>
  );
}
