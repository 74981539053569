import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toResourceJob } from '@/services';

function getJobById(jobId: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerFindJobById(jobId);
}

export function useJob(jobId: string) {
  return useQuery({
    queryKey: queryKeys.jobs.detail(jobId),
    queryFn: () => getJobById(jobId),
    enabled: !!jobId,
    select: (data) => toResourceJob(data),
  });
}
