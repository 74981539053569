import { User } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { environment as env } from '@/environments';

export function navigateToRoleDefault(role: string, navigate: ReturnType<typeof useNavigate>) {
  const roleRoutes: { [key: string]: string } = {
    admin: '/admin',
    user: '/admin',
    resource: '/app',
  };

  navigate(roleRoutes[role]);
}

export function isUserAdmin(user?: User) {
  const userRoles = user && (user[`${env.auth0Audience}roles`] as string[]);
  return userRoles?.includes('admin');
}
