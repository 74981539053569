import { Card, Form, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useDelayedLoading } from '@/hooks';
import { queryClient, queryKeys } from '@/lib/react-query';
import { User } from '@/models';
import { ROUTE_CONSTANTS } from '@/routes';
import { showMessage } from '@/utils';
import { formatResourceName } from '@/utils/resourceHelper';
import { useDeleteUser, useUpdateUser, useUser } from '@/web-api/users';

import { DeleteButton, EditSaveButton } from '../shared';

import { UserForm } from './UserForm';

export const UserDetailsPage = () => {
  const [isEditingMode, setEditingMode] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  if (userId === undefined) {
    throw new Error('Expected resourceId to be defined');
  }
  const { data, isLoading } = useUser(userId);
  const updateUserMutation = useUpdateUser();
  const deleteUserMutation = useDeleteUser();

  const showDelayedLoading = useDelayedLoading(isLoading);

  const [form] = Form.useForm<User>();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const onSave = () => form.submit();

  const onCancel = () => {
    form.resetFields();
    setEditingMode(!isEditingMode);
  };

  function onDelete() {
    if (userId === undefined) {
      throw new Error('Expected resourceId to be defined');
    }
    deleteUserMutation.mutate(userId, {
      onSuccess: () => {
        showMessage('success', t('forms:deleted'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.users.all });
        navigate(`/admin/${ROUTE_CONSTANTS.USERS_PATH}`, { replace: true });
      },
    });
  }

  const onFormFinish = (user: User) => {
    updateUserMutation.mutate(user, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.users.detail(user.id) });
        setEditingMode(false);
      },
    });
  };

  return (
    <Card
      title={formatResourceName(data?.firstname ?? '', data?.lastname ?? '', 'non-formal')}
      extra={
        <Space>
          <DeleteButton onDelete={onDelete} />
          <EditSaveButton
            form={form}
            onEdit={() => setEditingMode(true)}
            isEditing={isEditingMode}
            onSave={onSave}
            onCancel={onCancel}
            isDisabled={false}
          />
        </Space>
      }
      loading={showDelayedLoading}
    >
      <UserForm user={data} form={form} onFinish={onFormFinish} isEditingMode={isEditingMode} />
    </Card>
  );
};
