import { useQueryClient } from '@tanstack/react-query';
import { Card, Form, Space, Tag } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FormLanguage, LanguageForm } from '@/components/language';
import { DeleteButton, EditSaveButton } from '@/components/shared';
import { useDelayedLoading } from '@/hooks';
import { queryKeys } from '@/lib/react-query';
import { ROUTE_CONSTANTS } from '@/routes';
import { showMessage, transformLanguage } from '@/utils';
import { useLanguage, useReactivateLanguage, useUpdateLanguage } from '@/web-api/languages';
import { useDeleteLanguage } from '@/web-api/languages/useDeleteLanguage';

export const LanguageDetailPage = () => {
  const { t } = useTranslation();
  const { languageId } = useParams();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  if (languageId === undefined) {
    throw new Error('Expected resourceId to be defined');
  }
  const { data, isLoading } = useLanguage(languageId);
  const showDelayedLoading = useDelayedLoading(isLoading);

  const [form] = Form.useForm<FormLanguage>();
  const [isEditingMode, setEditingMode] = useState(false);

  const deleteLanguageMutation = useDeleteLanguage();
  const updateLanguageMutation = useUpdateLanguage();
  const reactivateLanguageMutation = useReactivateLanguage();

  const onSave = () => form.submit();

  const onCancel = () => {
    form.resetFields();
    setEditingMode(!isEditingMode);
  };

  function onDelete() {
    if (languageId === undefined) {
      throw new Error('Expected resourceId to be defined');
    }
    deleteLanguageMutation.mutate(languageId, {
      onSuccess: () => {
        showMessage('success', t('forms:deleted'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.languages.all });

        navigate(`/admin/${ROUTE_CONSTANTS.LANGUAGES_PATH}`, { replace: true });
      },
    });
  }

  function onReactivate() {
    if (languageId === undefined) {
      throw new Error('Expected resourceId to be defined');
    }
    reactivateLanguageMutation.mutate(languageId, {
      onSuccess: () => {
        showMessage('success', t('common:reactivated'));
      },
    });
  }

  const onFormFinish = (language: FormLanguage) => {
    updateLanguageMutation.mutate(language, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({
          queryKey: queryKeys.languages.detail(language?.id ?? ''),
        });
        setEditingMode(false);
      },
    });
  };

  return (
    <Card
      title={
        <Space direction='horizontal'>
          {data?.name}
          {data?.isArchived ? (
            <Tag color='red'>{t('common:archived')}</Tag>
          ) : (
            <Tag color='success'>{t('common:active')}</Tag>
          )}
        </Space>
      }
      extra={
        <Space>
          <DeleteButton
            onDelete={onDelete}
            onReactivate={onReactivate}
            isArchived={data?.isArchived}
          />
          <EditSaveButton
            form={form}
            onEdit={() => setEditingMode(true)}
            isEditing={isEditingMode}
            onSave={onSave}
            onCancel={onCancel}
            isDisabled={false}
          />
        </Space>
      }
      loading={showDelayedLoading}
    >
      {data && (
        <LanguageForm
          form={form}
          language={transformLanguage(data)}
          onFinish={onFormFinish}
          isEditingMode={isEditingMode}
          isCreateModal={false}
          isRenameable={data.isRenameable}
          isArchived={data.isArchived}
        />
      )}
    </Card>
  );
};
