import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { BookingType, Client } from '@/models';
import { WebBooking } from '@/models/web.booking.model';
import { ServiceBookingRequest, getSMPBackendAPIPublic } from '@/proxy-web';

const toWebRequest = (model: WebBooking & { client?: Client }): ServiceBookingRequest => ({
  customerNumber: model.customerNumber,
  date: model.date.format(),
  customer: model.customer,
  debtorNumber: model.debtorNumber,
  resourceNumber: model?.resourceNumber ? model.resourceNumber : undefined,
  acceptAlternativeResource: model?.acceptAlternativeResource,
  type: model.type,
  notes: model.notes,
  languageId: model.languageId,
  alternativeLanguageId: model.alternativeLanguageId,
  contactPerson: model.contactPerson,
  client: model.client && {
    name: model.client?.name,
    birthDate: model.client.birthDate
      ? dayjs.utc(model.client?.birthDate, 'DD.MM.YYYY').toISOString()
      : undefined,
    reference: model.client?.reference,
  },
  gender: model?.gender,
  ...(model.type === BookingType.ONSITE &&
    model.onsite && {
      onsite: {
        location: model.onsite.location,
        alternativeService: model.onsite.alternativeService ?? false,
        duration: model.onsite.duration,
      },
    }),
  ...(model.type === BookingType.REMOTE &&
    model.remote && {
      remote: {
        duration: model.remote.duration,
        emails: model.remote.emails,
      },
    }),
  ...(model.type === BookingType.PHONE &&
    model.phone && {
      phone: {
        duration: model.phone.duration,
        clientPhone: model.phone.clientPhone,
        customerPhone: model.phone.customerPhone,
      },
    }),
  ...(model.type === BookingType.DOCUMENT &&
    model.document && {
      file: model.document.file && model.document.file[0]?.originFileObj,
      document: {
        wordCount: model.document.wordCount,
      },
    }),
});

function createWebBookingRequest(model: WebBooking & { client?: Client }) {
  return getSMPBackendAPIPublic().requestControllerSendRequest(toWebRequest(model));
}

export function useWebCreateBookingReqeust() {
  return useMutation({ mutationFn: createWebBookingRequest });
}
