import { useMutation } from '@tanstack/react-query';

import { CreateUpdateUserRequest, getSMPBackendAPIAdmin } from '@/proxy';

function createUser(userRequest: CreateUpdateUserRequest) {
  return getSMPBackendAPIAdmin().usersControllerCreate(userRequest);
}

export function useCreateUser() {
  return useMutation({ mutationFn: createUser });
}
