import { Checkbox, Col, Divider, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  BookingType,
  EducationLevel,
  EmploymentType,
  LanguageLevel,
  Resource,
  Specialization,
} from '@/models';

type ResourceFormProp = {
  form: FormInstance<Resource>;
  resource?: Resource;
  onFinish: (resource: Resource) => void;
  isEditingMode: boolean;
  mode: 'create' | 'update';
};

export const ResourceForm = ({
  form,
  resource,
  onFinish,
  isEditingMode,
  mode,
}: ResourceFormProp) => {
  const { t } = useTranslation('forms');

  const colSize = mode === 'create' ? { xs: 24, md: 24 } : { xs: 24, md: 12 };

  return (
    <Form
      form={form}
      initialValues={resource}
      layout='vertical'
      disabled={!isEditingMode}
      onFinish={onFinish}
    >
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Row gutter={[12, 12]}>
        <Col {...colSize}>
          <Divider orientation='left'>{t('forms:basicInfo')}</Divider>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:firstname')} name='firstname' rules={[{ required: true }]}>
                <Input aria-label='Vorname' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:lastname')} name='lastname' rules={[{ required: true }]}>
                <Input aria-label='Nachname' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:salutation')} name='gender' rules={[{ required: true }]}>
                <Select aria-label='Anrede'>
                  <Select.Option value='MALE'>{t('forms:salutationMr')}</Select.Option>
                  <Select.Option value='FEMALE'>{t('forms:salutationMs')}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:birthdate')}
                name='dateOfBirth'
                rules={[
                  {
                    pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                    message: t('forms:birthdateInvalid'),
                  },
                ]}
              >
                <Input aria-label='Geburtstag' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:placeOfBirth')}
                name='placeOfBirth'
                rules={[{ required: true }]}
              >
                <Input aria-label='Geburtsort' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:originCountry')}
                name='originCountry'
                rules={[{ required: true }]}
              >
                <Input aria-label='Herkunftsland' />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col {...colSize}>
          <Divider orientation='left'>{t('forms:contactInfo')}</Divider>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:email')}
                name='email'
                rules={[{ required: true, type: 'email' }]}
              >
                <Input aria-label='E-Mail' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:phone')} name='phone' rules={[{ required: true }]}>
                <Input aria-label='Telefon' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:street')} name='street' rules={[{ required: true }]}>
                <Input aria-label='Straße' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:zip')} name='zip' rules={[{ required: true }]}>
                <Input aria-label='Postleitzahl' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:city')} name='city' rules={[{ required: true }]}>
                <Input aria-label='Stadt' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:country')} name='country' rules={[{ required: true }]}>
                <Input aria-label='Land' />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col {...colSize}>
          <Divider orientation='left'>{t('forms:contractData')}</Divider>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={4}>
              <Form.Item label={t('rate')} name='rate' rules={[{ required: true }]}>
                <InputNumber<number>
                  aria-label='Stundensatz'
                  disabled={!isEditingMode}
                  precision={2}
                  controls={false}
                  addonAfter='€'
                  formatter={(value) => (value ? `${value}` : '')}
                  onChange={(value) => value && form.setFieldsValue({ rate: value })}
                  decimalSeparator=','
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={20}>
              <Form.Item
                name='bookingTypes'
                label={t('common:services')}
                rules={[{ required: true }]}
              >
                <Select aria-label='Leistungen' mode='multiple'>
                  {Object.values(BookingType).map((type) => (
                    <Select.Option key={type} value={type}>
                      {t(`common:${type}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:contractStartDate')}
                name='contractStartDate'
                rules={[
                  {
                    required: true,
                    pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                    message: t('forms:dateInvalid'),
                  },
                ]}
              >
                <Input aria-label='Vertragsstart' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:contractEndDate')}
                name='contractEndDate'
                rules={[
                  {
                    pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                    message: t('forms:dateInvalid'),
                  },
                ]}
              >
                <Input aria-label='Vertragsende' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:taxNumber')}
                name='taxNumber'
                rules={[{ pattern: new RegExp('^\\d{2,3}\\/\\d{3}\\/\\d{4,5}$') }]}
              >
                <Input aria-label='Steuernummer' />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:taxId')}
                name='taxId'
                rules={[{ pattern: new RegExp('^(?!0)\\d{11}$') }]}
              >
                <Input aria-label='Steuer-ID' />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col {...colSize}>
          <Divider orientation='left'>{t('forms:education')}</Divider>
          <Row gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:educationLevel')} name='educationLevel'>
                <Select
                  aria-label='Bildungsabschluss'
                  allowClear
                  options={Object.values(EducationLevel).map((type) => ({
                    label: t(`common:${type}`),
                    value: type,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:germanLanguageLevel')} name='germanLanguageLevel'>
                <Select
                  aria-label='Deutschkenntnisse'
                  allowClear
                  options={Object.values(LanguageLevel).map((type) => ({
                    label: t(`common:${type}`),
                    value: type,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24}>
              <Form.Item label={t('forms:specialization')} name='specialization'>
                <Select
                  aria-label='Spezialisierung auswählen'
                  mode='multiple'
                  options={Object.values(Specialization).map((specialization) => ({
                    label: t(`common:${specialization}`),
                    value: specialization,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:employmentType')}
                name='employmentType'
                rules={[{ required: true }]}
              >
                <Select
                  aria-label='Beschäftigungsart'
                  allowClear
                  options={Object.values(EmploymentType).map((type) => ({
                    label: t(`common:${type}`),
                    value: type,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:employer')} name='employer'>
                <Input aria-label='Arbeitgeber' />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={12}>
        <Divider orientation='left'>{t('forms:more')}</Divider>
        <Col {...colSize}>
          <Row>
            <Col span={12}>
              <Form.Item name='cvAvailable' valuePropName={'checked'} rules={[{ required: true }]}>
                <Checkbox>{t('forms:cvAvailable')}</Checkbox>
              </Form.Item>
              <Form.Item name='certificateAvailable' valuePropName={'checked'}>
                <Checkbox>{t('forms:certificateAvailable')}</Checkbox>
              </Form.Item>
              <Form.Item name='imageAvailable' valuePropName={'checked'}>
                <Checkbox>{t('forms:imageAvailable')}</Checkbox>
              </Form.Item>
              <Form.Item
                name='nameTagReceived'
                valuePropName={'checked'}
                rules={[{ required: true }]}
              >
                <Checkbox>{t('forms:nameTagReceived')}</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='gdprConsent' valuePropName={'checked'} rules={[{ required: true }]}>
                <Checkbox>{t('forms:gdprConsent')}</Checkbox>
              </Form.Item>
              <Form.Item name='imageConsent' valuePropName={'checked'} rules={[{ required: true }]}>
                <Checkbox>{t('forms:imageConsent')}</Checkbox>
              </Form.Item>
              <Form.Item name='ndaConsent' valuePropName={'checked'} rules={[{ required: true }]}>
                <Checkbox>{t('forms:ndaConsent')}</Checkbox>
              </Form.Item>
              <Form.Item
                label={t('forms:criminalRecordDate')}
                name='criminalRecordDate'
                rules={[
                  {
                    pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                    message: t('forms:dateInvalid'),
                  },
                ]}
              >
                <Input aria-label='Datum Führungszeugniss' />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col {...colSize}>
          <Form.Item label={t('forms:details')} name='notes' rules={[{ required: false }]}>
            <Input.TextArea aria-label='Anmerkungen' rows={9} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
