/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import { environment as env } from '../environments';
import { logger } from '../utils';

import { customAuth } from './customAuth';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: env.api,
  timeout: 10000,
});

async function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  try {
    const accessToken: string = await customAuth.getAccessTokenSilently();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  } catch (error) {
    // Handle or ignore the error, depending on your needs.
    logger(`Failed to retrieve access token: ${String(error)}`, 'error');
  }
  config.headers.Accept = 'application/json';
  return config;
}

AXIOS_INSTANCE.interceptors.request.use(authRequestInterceptor);
AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const errorData = error.response?.data || error.message;
    return Promise.reject(errorData);
  }
);

export const customAxios = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
