import { BookingType, Gender } from '@/models/booking.model';

import { Language } from './language.model';

export type Resource = {
  id: string;
  number: number;
  gender: Gender;
  title?: string;
  firstname: string;
  lastname: string;
  rate: number;
  email: string;
  phone: string;
  notes?: string;
  isArchived: boolean;
  bookingTypes: BookingType[];
  languages?: Language[];
  dateOfBirth?: string;
  placeOfBirth?: string;
  originCountry?: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  taxId?: string;
  taxNumber?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  employmentType?: EmploymentType;
  employer?: string;
  criminalRecordDate?: string;
  cvAvailable: boolean;
  certificateAvailable: boolean;
  imageAvailable: boolean;
  gdprConsent: boolean;
  imageConsent: boolean;
  ndaConsent: boolean;
  nameTagReceived: boolean;
  educationLevel?: EducationLevel;
  germanLanguageLevel?: LanguageLevel;
  specialization?: Specialization[];
};

export type EmploymentType = (typeof EmploymentType)[keyof typeof EmploymentType];

export type EducationLevel = (typeof EducationLevel)[keyof typeof EducationLevel];

export type LanguageLevel = (typeof LanguageLevel)[keyof typeof LanguageLevel];

export type Specialization = (typeof Specialization)[keyof typeof Specialization];

export const EmploymentType = {
  MAIN: 'MAIN',
  SECONDARY: 'SECONDARY',
} as const;

export const EducationLevel = {
  NONE: 'NONE',
  APPRENTICESHIP: 'APPRENTICESHIP',
  BACHELOR: 'BACHELOR',
  MASTER: 'MASTER',
  PHD: 'PHD',
} as const;

export const LanguageLevel = {
  A1: 'A1',
  A2: 'A2',
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  C2: 'C2',
} as const;

export const Specialization = {
  HEALTH: 'HEALTH',
  EDUCATION: 'EDUCATION',
  SOCIAL: 'SOCIAL',
  POLITICS: 'POLITICS',
  LAW: 'LAW',
  CHILDREN_AND_YOUTH: 'CHILDREN_AND_YOUTH',
} as const;
