import { useMutation } from '@tanstack/react-query';

import { FormLanguage } from '@/components/language';
import { getSMPBackendAPIAdmin } from '@/proxy';

function createLanguage(language: FormLanguage) {
  return getSMPBackendAPIAdmin().languagesControllerCreate(language);
}

export function useCreateLanguage() {
  return useMutation({ mutationFn: createLanguage });
}
