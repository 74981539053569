import { DatePicker, List, Row, Tag, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Customer } from '@/models';
import { ROUTE_CONSTANTS } from '@/routes';
import { DATETIME_WITH_DAY_FORMAT, MONTH_YEAR_FORMAT, getInvoiceStatusColor } from '@/utils';
import { useInvoices } from '@/web-api/billing';

import { SelectCustomerBilling } from '.';

const { Text } = Typography;

export function InvoicesPage() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedCustomer, setSelectedCustomer] = useState<Customer['id']>();
  const invoicesQuery = useInvoices({
    month: selectedDate.month(),
    year: selectedDate.year(),
    customerId: selectedCustomer,
  });

  const { t } = useTranslation();

  function onMonthChange(date: Dayjs) {
    if (date) {
      setSelectedDate(date);
    }
  }

  return (
    <Fragment>
      <Row align='middle' justify='space-between' style={{ marginBottom: 24 }}>
        <SelectCustomerBilling customerState={[selectedCustomer, setSelectedCustomer]} />
        <DatePicker
          picker='month'
          value={selectedDate}
          onChange={onMonthChange}
          format={MONTH_YEAR_FORMAT}
          style={{ width: '25%' }}
        />
      </Row>
      <List
        bordered
        loading={invoicesQuery.isLoading}
        dataSource={invoicesQuery.data}
        renderItem={({ id, number, status, created, updated, customer }) => (
          <List.Item
            key={id}
            extra={
              <Link to={`/admin/${ROUTE_CONSTANTS.CUSTOMERS_PATH}/${customer.id}`}>
                {customer.company} ({customer.number})
              </Link>
            }
          >
            <List.Item.Meta
              title={`${number} (${t('common:updatedAt')}: ${updated.format(
                DATETIME_WITH_DAY_FORMAT
              )})`}
              description={
                <Text type='secondary'>
                  <Tag color={getInvoiceStatusColor(status)}>{t(`common:${status}`)}</Tag>
                  {t('common:createdAt')}: {created.format(DATETIME_WITH_DAY_FORMAT)}
                </Text>
              }
            />
          </List.Item>
        )}
      />
    </Fragment>
  );
}
