import { App, Button, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { BookingStatus } from '@/models';
import { getRange, isButtonDisabled, showMessage } from '@/utils';
import { useTransformBooking } from '@/web-api/bookings';

type TransformBookingButtonProps = {
  bookingId: string;
  status: BookingStatus;
};

type FormValues = {
  duration: number;
  customerPhone: string;
  clientPhone: string;
};

export function TransformBookingButton(props: TransformBookingButtonProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const { modal } = App.useApp();

  const transformBookingMutation = useTransformBooking();

  function handleTransformBooking(values: FormValues) {
    transformBookingMutation.mutate(
      {
        bookingId: props.bookingId,
        request: {
          phone: {
            duration: values.duration,
            clientPhone: values.clientPhone,
            customerPhone: values.customerPhone,
          },
        },
      },
      {
        onSuccess: () => {
          showMessage('success', t('forms:transformBookingSuccess'));
          // TODO: This is a workaround to refresh the page. We should find a way to reset initial values in form.
          window.location.reload();
        },
      }
    );
  }

  function handleModal() {
    void modal.confirm({
      title: t('TransformBookingModalTitle'),
      content: (
        <Form
          form={form}
          name='transform-booking'
          onFinish={handleTransformBooking}
          layout='vertical'
        >
          <Form.Item label={t('forms:duration')} name={'duration'} rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder={t('forms:select')}
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={getRange(1, 8, 0.25).map((minutes) => ({
                value: minutes,
                label: `${t('common:minuteWithCount', { count: minutes })} (${t(
                  'common:hourWithCount',
                  { count: minutes / 60 }
                )})`,
              }))}
              onSelect={(value) => form.setFieldValue('durationInMinutes', value)}
            />
          </Form.Item>
          <Form.Item
            name={'customerPhone'}
            label={t('forms:customerPhoneNumber')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'clientPhone'}
            label={t('forms:clientPhoneNumber')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: () => form.submit(),
    });
  }

  return (
    <Button
      aria-label='Buchung umwandeln'
      onClick={handleModal}
      disabled={isButtonDisabled(props.status, [
        BookingStatus.OPEN,
        BookingStatus.PUBLISHED,
        BookingStatus.CONFIRMED,
      ])}
    >
      {t('forms:transformPhone')}
    </Button>
  );
}
