import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

type RemoveCustomerBlockedResourceRequest = {
  customerId: string;
  resourceId: string;
};

function removeCustomerBlockedResource(request: RemoveCustomerBlockedResourceRequest) {
  return getSMPBackendAPIAdmin().customersControllerRemoveBlockedResource(
    request.customerId,
    request.resourceId
  );
}

export function useRemoveCustomerBlockedResource() {
  return useMutation({ mutationFn: removeCustomerBlockedResource });
}
