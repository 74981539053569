/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Admin)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */
import { customAxios } from '../lib/axios';

import type {
  UserDto,
  CreateUpdateUserRequest,
  ResourceDto,
  ResourcesControllerFindAllParams,
  CreateUpdateResourceRequest,
  AddLanguagesRequest,
  ResourceBatchRequest,
  LanguageDto,
  LanguagesControllerFindAllParams,
  CreateUpdateLanguageRequest,
  ServiceBookingDto,
  CreateServiceBookingRequest,
  ServiceBookingGroupDto,
  ServiceBookingsControllerFindAllParams,
  UpdateServiceBookingRequest,
  UpsertVerificationRequest,
  UpdateSurchargeRequest,
  TransformBookingRequest,
  ResourceJobDto,
  ResourceServiceBookingDto,
  ResourceBookingsControllerFindBookingsParams,
  CloseBookingRequest,
  SurchargeDto,
  ResourceBillableDto,
  ResourceBookingsControllerGetBillableBookingsParams,
  ResourceBookingsControllerGetBillableBookingsExportParams,
  CustomerDto,
  CreateCustomerRequest,
  CustomersControllerFindAllWithPagination200,
  CustomersControllerFindAllWithPaginationParams,
  UpdateCustomerRequest,
  CustomersControllerFindCustomerBookings200,
  CustomersControllerFindCustomerBookingsParams,
  AddBlockedResourceRequest,
  SettingsDto,
  UpsertServiceRateReqeuest,
  CreateUpdateDateTimeSurchargeDto,
  CreateUpdateExpressSurchargeDto,
  CreateUpdateTravelSurchargeDto,
  UpsertOpeningHoursRequest,
  LexofficeEvent,
  InvoiceDto,
  InvoiceControllerGetInvoicesParams,
  CreatInvoiceRequest,
  ChargeableServiceBookingsDto,
  InvoiceControllerGetChargeableServiceBookingsByCustomerParams,
  CustomerRequestsReportDto,
  ReportsControllerGetRequestReportParams,
  BookingStatusCountsResponseDto,
  ReportsControllerGetBookingReportParams,
  ReportsControllerGetBookingReportExcelParams,
} from './dtos';

export const getSMPBackendAPIAdmin = () => {
  const usersControllerFindMe = () => {
    return customAxios<UserDto>({ url: `/api/v1/users/me`, method: 'get' });
  };

  const usersControllerUpdateMe = (createUpdateUserRequest: CreateUpdateUserRequest) => {
    return customAxios<void>({
      url: `/api/v1/users/me`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateUserRequest,
    });
  };

  const usersControllerFindOne = (id: string) => {
    return customAxios<UserDto>({ url: `/api/v1/users/${id}`, method: 'get' });
  };

  const usersControllerDelete = (id: string) => {
    return customAxios<void>({ url: `/api/v1/users/${id}`, method: 'delete' });
  };

  const usersControllerUpdate = (id: string, createUpdateUserRequest: CreateUpdateUserRequest) => {
    return customAxios<void>({
      url: `/api/v1/users/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateUserRequest,
    });
  };

  const usersControllerFindAll = () => {
    return customAxios<UserDto[]>({ url: `/api/v1/users`, method: 'get' });
  };

  const usersControllerCreate = (createUpdateUserRequest: CreateUpdateUserRequest) => {
    return customAxios<UserDto>({
      url: `/api/v1/users`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateUserRequest,
    });
  };

  const resourcesControllerFindAll = (params?: ResourcesControllerFindAllParams) => {
    return customAxios<ResourceDto[]>({ url: `/api/v1/resources`, method: 'get', params });
  };

  const resourcesControllerCreate = (createUpdateResourceRequest: CreateUpdateResourceRequest) => {
    return customAxios<ResourceDto>({
      url: `/api/v1/resources`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateResourceRequest,
    });
  };

  const resourcesControllerFindOne = (id: string) => {
    return customAxios<ResourceDto>({ url: `/api/v1/resources/${id}`, method: 'get' });
  };

  const resourcesControllerUpdate = (
    id: string,
    createUpdateResourceRequest: CreateUpdateResourceRequest
  ) => {
    return customAxios<ResourceDto>({
      url: `/api/v1/resources/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateResourceRequest,
    });
  };

  const resourcesControllerDelete = (id: string) => {
    return customAxios<void>({ url: `/api/v1/resources/${id}`, method: 'delete' });
  };

  const resourcesControllerReactivate = (id: string) => {
    return customAxios<void>({ url: `/api/v1/resources/${id}/reactivate`, method: 'post' });
  };

  const resourcesControllerAddLanguage = (id: string, addLanguagesRequest: AddLanguagesRequest) => {
    return customAxios<void>({
      url: `/api/v1/resources/${id}/languages`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: addLanguagesRequest,
    });
  };

  const resourcesControllerRemoveLanguage = (id: string, languageId: string) => {
    return customAxios<void>({
      url: `/api/v1/resources/${id}/languages/${languageId}`,
      method: 'delete',
    });
  };

  const resourcesBatchControllerCreate = (resourceBatchRequest: ResourceBatchRequest) => {
    return customAxios<void>({
      url: `/api/v1/resources-batch`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resourceBatchRequest,
    });
  };

  const languagesControllerFindAll = (params: LanguagesControllerFindAllParams) => {
    return customAxios<LanguageDto[]>({ url: `/api/v1/languages`, method: 'get', params });
  };

  const languagesControllerCreate = (createUpdateLanguageRequest: CreateUpdateLanguageRequest) => {
    return customAxios<LanguageDto>({
      url: `/api/v1/languages`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateLanguageRequest,
    });
  };

  const languagesControllerFindOne = (id: string) => {
    return customAxios<LanguageDto>({ url: `/api/v1/languages/${id}`, method: 'get' });
  };

  const languagesControllerUpdate = (
    id: string,
    createUpdateLanguageRequest: CreateUpdateLanguageRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/languages/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateLanguageRequest,
    });
  };

  const languagesControllerDelete = (id: string) => {
    return customAxios<void>({ url: `/api/v1/languages/${id}`, method: 'delete' });
  };

  const languagesControllerReactivate = (id: string) => {
    return customAxios<void>({ url: `/api/v1/languages/${id}/reactivate`, method: 'post' });
  };

  const serviceBookingsControllerCreate = (
    createServiceBookingRequest: CreateServiceBookingRequest
  ) => {
    const formData = new FormData();
    if (createServiceBookingRequest.file !== undefined) {
      formData.append('file', createServiceBookingRequest.file);
    }
    formData.append('date', createServiceBookingRequest.date);
    formData.append('customerId', createServiceBookingRequest.customerId);
    if (createServiceBookingRequest.debtorId !== undefined) {
      formData.append('debtorId', createServiceBookingRequest.debtorId);
    }
    formData.append('languageId', createServiceBookingRequest.languageId);
    if (createServiceBookingRequest.alternativeLanguageId !== undefined) {
      formData.append('alternativeLanguageId', createServiceBookingRequest.alternativeLanguageId);
    }
    formData.append('contactPerson', JSON.stringify(createServiceBookingRequest.contactPerson));
    formData.append('type', createServiceBookingRequest.type);
    if (createServiceBookingRequest.onsite !== undefined) {
      formData.append('onsite', JSON.stringify(createServiceBookingRequest.onsite));
    }
    if (createServiceBookingRequest.phone !== undefined) {
      formData.append('phone', JSON.stringify(createServiceBookingRequest.phone));
    }
    if (createServiceBookingRequest.remote !== undefined) {
      formData.append('remote', JSON.stringify(createServiceBookingRequest.remote));
    }
    if (createServiceBookingRequest.document !== undefined) {
      formData.append('document', JSON.stringify(createServiceBookingRequest.document));
    }
    if (createServiceBookingRequest.client !== undefined) {
      formData.append('client', JSON.stringify(createServiceBookingRequest.client));
    }
    if (createServiceBookingRequest.gender !== undefined) {
      formData.append('gender', createServiceBookingRequest.gender);
    }
    if (createServiceBookingRequest.acceptAlternativeResource !== undefined) {
      formData.append(
        'acceptAlternativeResource',
        createServiceBookingRequest.acceptAlternativeResource.toString()
      );
    }
    if (createServiceBookingRequest.specialRate !== undefined) {
      formData.append('specialRate', createServiceBookingRequest.specialRate.toString());
    }
    if (createServiceBookingRequest.notes !== undefined) {
      formData.append('notes', createServiceBookingRequest.notes);
    }

    return customAxios<ServiceBookingDto>({
      url: `/api/v1/service-bookings`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };

  const serviceBookingsControllerFindAll = (params: ServiceBookingsControllerFindAllParams) => {
    return customAxios<ServiceBookingGroupDto[]>({
      url: `/api/v1/service-bookings`,
      method: 'get',
      params,
    });
  };

  const serviceBookingsControllerFindOne = (id: string) => {
    return customAxios<ServiceBookingDto>({ url: `/api/v1/service-bookings/${id}`, method: 'get' });
  };

  const serviceBookingsControllerUpdate = (
    id: string,
    updateServiceBookingRequest: UpdateServiceBookingRequest
  ) => {
    const formData = new FormData();
    if (updateServiceBookingRequest.file !== undefined) {
      formData.append('file', updateServiceBookingRequest.file);
    }
    if (updateServiceBookingRequest.resourceId !== undefined) {
      formData.append('resourceId', updateServiceBookingRequest.resourceId);
    }
    if (updateServiceBookingRequest.document !== undefined) {
      formData.append('document', JSON.stringify(updateServiceBookingRequest.document));
    }
    if (updateServiceBookingRequest.ignoreAvailability !== undefined) {
      formData.append(
        'ignoreAvailability',
        updateServiceBookingRequest.ignoreAvailability.toString()
      );
    }
    formData.append('date', updateServiceBookingRequest.date);
    formData.append('customerId', updateServiceBookingRequest.customerId);
    if (updateServiceBookingRequest.debtorId !== undefined) {
      formData.append('debtorId', updateServiceBookingRequest.debtorId);
    }
    formData.append('languageId', updateServiceBookingRequest.languageId);
    if (updateServiceBookingRequest.alternativeLanguageId !== undefined) {
      formData.append('alternativeLanguageId', updateServiceBookingRequest.alternativeLanguageId);
    }
    formData.append('contactPerson', JSON.stringify(updateServiceBookingRequest.contactPerson));
    formData.append('type', updateServiceBookingRequest.type);
    if (updateServiceBookingRequest.onsite !== undefined) {
      formData.append('onsite', JSON.stringify(updateServiceBookingRequest.onsite));
    }
    if (updateServiceBookingRequest.phone !== undefined) {
      formData.append('phone', JSON.stringify(updateServiceBookingRequest.phone));
    }
    if (updateServiceBookingRequest.remote !== undefined) {
      formData.append('remote', JSON.stringify(updateServiceBookingRequest.remote));
    }
    if (updateServiceBookingRequest.client !== undefined) {
      formData.append('client', JSON.stringify(updateServiceBookingRequest.client));
    }
    if (updateServiceBookingRequest.gender !== undefined) {
      formData.append('gender', updateServiceBookingRequest.gender);
    }
    if (updateServiceBookingRequest.acceptAlternativeResource !== undefined) {
      formData.append(
        'acceptAlternativeResource',
        updateServiceBookingRequest.acceptAlternativeResource.toString()
      );
    }
    if (updateServiceBookingRequest.specialRate !== undefined) {
      formData.append('specialRate', updateServiceBookingRequest.specialRate.toString());
    }
    if (updateServiceBookingRequest.notes !== undefined) {
      formData.append('notes', updateServiceBookingRequest.notes);
    }

    return customAxios<ServiceBookingDto>({
      url: `/api/v1/service-bookings/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };

  const serviceBookingsControllerUpdateVerification = (
    id: string,
    upsertVerificationRequest: UpsertVerificationRequest
  ) => {
    const formData = new FormData();
    if (upsertVerificationRequest.file !== undefined) {
      formData.append('file', upsertVerificationRequest.file);
    }
    formData.append('value', upsertVerificationRequest.value.toString());
    formData.append('issuer', upsertVerificationRequest.issuer);
    formData.append('autoVerify', upsertVerificationRequest.autoVerify.toString());
    if (upsertVerificationRequest.startTime !== undefined) {
      formData.append('startTime', upsertVerificationRequest.startTime);
    }

    return customAxios<void>({
      url: `/api/v1/service-bookings/${id}/verification`,
      method: 'patch',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };

  const serviceBookingsControllerUpdateCustomerSurcharges = (
    id: string,
    updateSurchargeRequest: UpdateSurchargeRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/service-bookings/${id}/customer-surcharges`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateSurchargeRequest,
    });
  };

  const serviceBookingsControllerUpdateResourceSurcharges = (
    id: string,
    updateSurchargeRequest: UpdateSurchargeRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/service-bookings/${id}/resource-surcharges`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateSurchargeRequest,
    });
  };

  const serviceBookingsControllerCancel = (id: string) => {
    return customAxios<void>({ url: `/api/v1/service-bookings/${id}/cancel`, method: 'put' });
  };

  const serviceBookingsControllerPublish = (id: string) => {
    return customAxios<void>({ url: `/api/v1/service-bookings/${id}/publish`, method: 'put' });
  };

  const serviceBookingsControllerTransform = (
    id: string,
    transformBookingRequest: TransformBookingRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/service-bookings/${id}/transform`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: transformBookingRequest,
    });
  };

  const resourceBookingsControllerFindJobs = () => {
    return customAxios<ResourceJobDto[]>({ url: `/api/v1/resource-bookings/jobs`, method: 'get' });
  };

  const resourceBookingsControllerFindJobById = (id: string) => {
    return customAxios<ResourceJobDto>({
      url: `/api/v1/resource-bookings/jobs/${id}`,
      method: 'get',
    });
  };

  const resourceBookingsControllerFindRequests = () => {
    return customAxios<ResourceJobDto[]>({
      url: `/api/v1/resource-bookings/requests`,
      method: 'get',
    });
  };

  const resourceBookingsControllerFindRequestById = (id: string) => {
    return customAxios<ResourceJobDto>({
      url: `/api/v1/resource-bookings/requests/${id}`,
      method: 'get',
    });
  };

  const resourceBookingsControllerFindBookings = (
    params: ResourceBookingsControllerFindBookingsParams
  ) => {
    return customAxios<ResourceServiceBookingDto[]>({
      url: `/api/v1/resource-bookings/bookings`,
      method: 'get',
      params,
    });
  };

  const resourceBookingsControllerFindBookingId = (id: string) => {
    return customAxios<ResourceServiceBookingDto>({
      url: `/api/v1/resource-bookings/bookings/${id}`,
      method: 'get',
    });
  };

  const resourceBookingsControllerAcceptRequestedServiceBooking = (id: string) => {
    return customAxios<void>({
      url: `/api/v1/resource-bookings/requests/${id}/accept`,
      method: 'put',
    });
  };

  const resourceBookingsControllerDeclineRequestedServiceBooking = (id: string) => {
    return customAxios<void>({
      url: `/api/v1/resource-bookings/requests/${id}/decline`,
      method: 'put',
    });
  };

  const resourceBookingsControllerAcceptPublishedServiceBooking = (id: string) => {
    return customAxios<void>({ url: `/api/v1/resource-bookings/jobs/${id}/accept`, method: 'put' });
  };

  const resourceBookingsControllerClose = (
    id: string,
    closeBookingRequest: CloseBookingRequest
  ) => {
    const formData = new FormData();
    if (closeBookingRequest.file !== undefined) {
      formData.append('file', closeBookingRequest.file);
    }
    formData.append('value', closeBookingRequest.value.toString());
    if (closeBookingRequest.startTime !== undefined) {
      formData.append('startTime', closeBookingRequest.startTime);
    }

    return customAxios<void>({
      url: `/api/v1/resource-bookings/bookings/${id}/close`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };

  const resourceBookingsControllerGetCancellationFees = (id: string) => {
    return customAxios<SurchargeDto>({
      url: `/api/v1/resource-bookings/bookings/${id}/cancel`,
      method: 'get',
    });
  };

  const resourceBookingsControllerConfirmCancel = (id: string) => {
    return customAxios<void>({
      url: `/api/v1/resource-bookings/bookings/${id}/cancel`,
      method: 'post',
    });
  };

  const resourceBookingsControllerGetBillableBookings = (
    id: string,
    params: ResourceBookingsControllerGetBillableBookingsParams
  ) => {
    return customAxios<ResourceBillableDto>({
      url: `/api/v1/resource-bookings/resource/${id}/billable`,
      method: 'get',
      params,
    });
  };

  const resourceBookingsControllerGetBillableBookingsExport = (
    id: string,
    params: ResourceBookingsControllerGetBillableBookingsExportParams
  ) => {
    return customAxios<Blob>({
      url: `/api/v1/resource-bookings/resource/${id}/billable/export`,
      method: 'get',
      params,
      responseType: 'blob',
    });
  };

  const customersControllerCreate = (createCustomerRequest: CreateCustomerRequest) => {
    return customAxios<CustomerDto>({
      url: `/api/v1/customers`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createCustomerRequest,
    });
  };

  const customersControllerFindAll = () => {
    return customAxios<CustomerDto[]>({ url: `/api/v1/customers`, method: 'get' });
  };

  const customersControllerFindAllWithPagination = (
    params?: CustomersControllerFindAllWithPaginationParams
  ) => {
    return customAxios<CustomersControllerFindAllWithPagination200>({
      url: `/api/v1/customers/paginated`,
      method: 'get',
      params,
    });
  };

  const customersControllerFindOne = (id: string) => {
    return customAxios<CustomerDto>({ url: `/api/v1/customers/${id}`, method: 'get' });
  };

  const customersControllerUpdate = (id: string, updateCustomerRequest: UpdateCustomerRequest) => {
    return customAxios<void>({
      url: `/api/v1/customers/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateCustomerRequest,
    });
  };

  const customersControllerFindCustomerBookings = (
    id: string,
    params?: CustomersControllerFindCustomerBookingsParams
  ) => {
    return customAxios<CustomersControllerFindCustomerBookings200>({
      url: `/api/v1/customers/${id}/bookings`,
      method: 'get',
      params,
    });
  };

  const customersControllerAddBlockedResource = (
    id: string,
    addBlockedResourceRequest: AddBlockedResourceRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/customers/${id}/blocked-resources`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: addBlockedResourceRequest,
    });
  };

  const customersControllerRemoveBlockedResource = (id: string, resourceId: string) => {
    return customAxios<void>({
      url: `/api/v1/customers/${id}/blocked-resources/${resourceId}`,
      method: 'delete',
    });
  };

  const settingsControllerFindAll = () => {
    return customAxios<SettingsDto>({ url: `/api/v1/settings`, method: 'get' });
  };

  const settingsControllerUpsertServiceRate = (
    upsertServiceRateReqeuest: UpsertServiceRateReqeuest
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/service-rates`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: upsertServiceRateReqeuest,
    });
  };

  const settingsControllerCreateDateTimeSurcharge = (
    createUpdateDateTimeSurchargeDto: CreateUpdateDateTimeSurchargeDto
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/date-time-surcharges`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateDateTimeSurchargeDto,
    });
  };

  const settingsControllerUpdateDateTimeSurcharge = (
    id: string,
    createUpdateDateTimeSurchargeDto: CreateUpdateDateTimeSurchargeDto
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/date-time-surcharges/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateDateTimeSurchargeDto,
    });
  };

  const settingsControllerDeleteDayDateSurcharge = (id: string) => {
    return customAxios<void>({
      url: `/api/v1/settings/date-time-surcharges/${id}`,
      method: 'delete',
    });
  };

  const settingsControllerCreateExpressSurcharge = (
    createUpdateExpressSurchargeDto: CreateUpdateExpressSurchargeDto
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/express-surcharges`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateExpressSurchargeDto,
    });
  };

  const settingsControllerUpdateExpressSurcharge = (
    id: string,
    createUpdateExpressSurchargeDto: CreateUpdateExpressSurchargeDto
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/express-surcharges/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateExpressSurchargeDto,
    });
  };

  const settingsControllerDeleteExpressSurcharge = (id: string) => {
    return customAxios<void>({
      url: `/api/v1/settings/express-surcharges/${id}`,
      method: 'delete',
    });
  };

  const settingsControllerCreateTravelSurcharge = (
    createUpdateTravelSurchargeDto: CreateUpdateTravelSurchargeDto
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/travel-surcharges`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateTravelSurchargeDto,
    });
  };

  const settingsControllerUpdateTravelSurcharge = (
    id: string,
    createUpdateTravelSurchargeDto: CreateUpdateTravelSurchargeDto
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/travel-surcharges/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateTravelSurchargeDto,
    });
  };

  const settingsControllerDeleteTravelSurcharge = (id: string) => {
    return customAxios<void>({ url: `/api/v1/settings/travel-surcharges/${id}`, method: 'delete' });
  };

  const settingsControllerUpsertOpeningHours = (
    upsertOpeningHoursRequest: UpsertOpeningHoursRequest
  ) => {
    return customAxios<void>({
      url: `/api/v1/settings/opening-hours`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: upsertOpeningHoursRequest,
    });
  };

  const lexofficeControllerUpsertContact = (lexofficeEvent: LexofficeEvent) => {
    return customAxios<void>({
      url: `/api/v1/lexoffice/webhook/contacts`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: lexofficeEvent,
    });
  };

  const lexofficeControllerUpdateInvoiceStatus = (lexofficeEvent: LexofficeEvent) => {
    return customAxios<void>({
      url: `/api/v1/lexoffice/webhook/invoice-status`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: lexofficeEvent,
    });
  };

  const invoiceControllerGetInvoices = (params: InvoiceControllerGetInvoicesParams) => {
    return customAxios<InvoiceDto[]>({ url: `/api/v1/invoices`, method: 'get', params });
  };

  const invoiceControllerCreateInvoice = (creatInvoiceRequest: CreatInvoiceRequest) => {
    return customAxios<void>({
      url: `/api/v1/invoices`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: creatInvoiceRequest,
    });
  };

  const invoiceControllerGetChargeableServiceBookingsByCustomer = (
    params: InvoiceControllerGetChargeableServiceBookingsByCustomerParams
  ) => {
    return customAxios<ChargeableServiceBookingsDto>({
      url: `/api/v1/invoices/chargeable`,
      method: 'get',
      params,
    });
  };

  const mailsControllerSentCustomerCancellationLink = (bookingId: string) => {
    return customAxios<void>({
      url: `/api/v1/mails/customer-cancellation/${bookingId}`,
      method: 'get',
    });
  };

  const reportsControllerGetRequestReport = (params: ReportsControllerGetRequestReportParams) => {
    return customAxios<CustomerRequestsReportDto>({
      url: `/api/v1/reports/requests`,
      method: 'get',
      params,
    });
  };

  const reportsControllerGetBookingReport = (params: ReportsControllerGetBookingReportParams) => {
    return customAxios<BookingStatusCountsResponseDto>({
      url: `/api/v1/reports/bookings`,
      method: 'get',
      params,
    });
  };

  const reportsControllerGetBookingReportExcel = (
    params: ReportsControllerGetBookingReportExcelParams
  ) => {
    return customAxios<string>({ url: `/api/v1/reports/bookings/export`, method: 'get', params });
  };

  return {
    usersControllerFindMe,
    usersControllerUpdateMe,
    usersControllerFindOne,
    usersControllerDelete,
    usersControllerUpdate,
    usersControllerFindAll,
    usersControllerCreate,
    resourcesControllerFindAll,
    resourcesControllerCreate,
    resourcesControllerFindOne,
    resourcesControllerUpdate,
    resourcesControllerDelete,
    resourcesControllerReactivate,
    resourcesControllerAddLanguage,
    resourcesControllerRemoveLanguage,
    resourcesBatchControllerCreate,
    languagesControllerFindAll,
    languagesControllerCreate,
    languagesControllerFindOne,
    languagesControllerUpdate,
    languagesControllerDelete,
    languagesControllerReactivate,
    serviceBookingsControllerCreate,
    serviceBookingsControllerFindAll,
    serviceBookingsControllerFindOne,
    serviceBookingsControllerUpdate,
    serviceBookingsControllerUpdateVerification,
    serviceBookingsControllerUpdateCustomerSurcharges,
    serviceBookingsControllerUpdateResourceSurcharges,
    serviceBookingsControllerCancel,
    serviceBookingsControllerPublish,
    serviceBookingsControllerTransform,
    resourceBookingsControllerFindJobs,
    resourceBookingsControllerFindJobById,
    resourceBookingsControllerFindRequests,
    resourceBookingsControllerFindRequestById,
    resourceBookingsControllerFindBookings,
    resourceBookingsControllerFindBookingId,
    resourceBookingsControllerAcceptRequestedServiceBooking,
    resourceBookingsControllerDeclineRequestedServiceBooking,
    resourceBookingsControllerAcceptPublishedServiceBooking,
    resourceBookingsControllerClose,
    resourceBookingsControllerGetCancellationFees,
    resourceBookingsControllerConfirmCancel,
    resourceBookingsControllerGetBillableBookings,
    resourceBookingsControllerGetBillableBookingsExport,
    customersControllerCreate,
    customersControllerFindAll,
    customersControllerFindAllWithPagination,
    customersControllerFindOne,
    customersControllerUpdate,
    customersControllerFindCustomerBookings,
    customersControllerAddBlockedResource,
    customersControllerRemoveBlockedResource,
    settingsControllerFindAll,
    settingsControllerUpsertServiceRate,
    settingsControllerCreateDateTimeSurcharge,
    settingsControllerUpdateDateTimeSurcharge,
    settingsControllerDeleteDayDateSurcharge,
    settingsControllerCreateExpressSurcharge,
    settingsControllerUpdateExpressSurcharge,
    settingsControllerDeleteExpressSurcharge,
    settingsControllerCreateTravelSurcharge,
    settingsControllerUpdateTravelSurcharge,
    settingsControllerDeleteTravelSurcharge,
    settingsControllerUpsertOpeningHours,
    lexofficeControllerUpsertContact,
    lexofficeControllerUpdateInvoiceStatus,
    invoiceControllerGetInvoices,
    invoiceControllerCreateInvoice,
    invoiceControllerGetChargeableServiceBookingsByCustomer,
    mailsControllerSentCustomerCancellationLink,
    reportsControllerGetRequestReport,
    reportsControllerGetBookingReport,
    reportsControllerGetBookingReportExcel,
  };
};
export type UsersControllerFindMeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['usersControllerFindMe']>>
>;
export type UsersControllerUpdateMeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['usersControllerUpdateMe']>>
>;
export type UsersControllerFindOneResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['usersControllerFindOne']>>
>;
export type UsersControllerDeleteResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['usersControllerDelete']>>
>;
export type UsersControllerUpdateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['usersControllerUpdate']>>
>;
export type UsersControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['usersControllerFindAll']>>
>;
export type UsersControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['usersControllerCreate']>>
>;
export type ResourcesControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerFindAll']>>
>;
export type ResourcesControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerCreate']>>
>;
export type ResourcesControllerFindOneResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerFindOne']>>
>;
export type ResourcesControllerUpdateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerUpdate']>>
>;
export type ResourcesControllerDeleteResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerDelete']>>
>;
export type ResourcesControllerReactivateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerReactivate']>>
>;
export type ResourcesControllerAddLanguageResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerAddLanguage']>>
>;
export type ResourcesControllerRemoveLanguageResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesControllerRemoveLanguage']>>
>;
export type ResourcesBatchControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourcesBatchControllerCreate']>>
>;
export type LanguagesControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['languagesControllerFindAll']>>
>;
export type LanguagesControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['languagesControllerCreate']>>
>;
export type LanguagesControllerFindOneResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['languagesControllerFindOne']>>
>;
export type LanguagesControllerUpdateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['languagesControllerUpdate']>>
>;
export type LanguagesControllerDeleteResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['languagesControllerDelete']>>
>;
export type LanguagesControllerReactivateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['languagesControllerReactivate']>>
>;
export type ServiceBookingsControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerCreate']>>
>;
export type ServiceBookingsControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerFindAll']>>
>;
export type ServiceBookingsControllerFindOneResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerFindOne']>>
>;
export type ServiceBookingsControllerUpdateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerUpdate']>>
>;
export type ServiceBookingsControllerUpdateVerificationResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerUpdateVerification']
    >
  >
>;
export type ServiceBookingsControllerUpdateCustomerSurchargesResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerUpdateCustomerSurcharges']
    >
  >
>;
export type ServiceBookingsControllerUpdateResourceSurchargesResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerUpdateResourceSurcharges']
    >
  >
>;
export type ServiceBookingsControllerCancelResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerCancel']>>
>;
export type ServiceBookingsControllerPublishResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerPublish']>>
>;
export type ServiceBookingsControllerTransformResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['serviceBookingsControllerTransform']>
  >
>;
export type ResourceBookingsControllerFindJobsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerFindJobs']>
  >
>;
export type ResourceBookingsControllerFindJobByIdResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerFindJobById']>
  >
>;
export type ResourceBookingsControllerFindRequestsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerFindRequests']>
  >
>;
export type ResourceBookingsControllerFindRequestByIdResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerFindRequestById']
    >
  >
>;
export type ResourceBookingsControllerFindBookingsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerFindBookings']>
  >
>;
export type ResourceBookingsControllerFindBookingIdResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerFindBookingId']>
  >
>;
export type ResourceBookingsControllerAcceptRequestedServiceBookingResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getSMPBackendAPIAdmin
      >['resourceBookingsControllerAcceptRequestedServiceBooking']
    >
  >
>;
export type ResourceBookingsControllerDeclineRequestedServiceBookingResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getSMPBackendAPIAdmin
      >['resourceBookingsControllerDeclineRequestedServiceBooking']
    >
  >
>;
export type ResourceBookingsControllerAcceptPublishedServiceBookingResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getSMPBackendAPIAdmin
      >['resourceBookingsControllerAcceptPublishedServiceBooking']
    >
  >
>;
export type ResourceBookingsControllerCloseResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerClose']>>
>;
export type ResourceBookingsControllerGetCancellationFeesResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerGetCancellationFees']
    >
  >
>;
export type ResourceBookingsControllerConfirmCancelResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerConfirmCancel']>
  >
>;
export type ResourceBookingsControllerGetBillableBookingsResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['resourceBookingsControllerGetBillableBookings']
    >
  >
>;
export type ResourceBookingsControllerGetBillableBookingsExportResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getSMPBackendAPIAdmin
      >['resourceBookingsControllerGetBillableBookingsExport']
    >
  >
>;
export type CustomersControllerCreateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerCreate']>>
>;
export type CustomersControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerFindAll']>>
>;
export type CustomersControllerFindAllWithPaginationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerFindAllWithPagination']>
  >
>;
export type CustomersControllerFindOneResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerFindOne']>>
>;
export type CustomersControllerUpdateResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerUpdate']>>
>;
export type CustomersControllerFindCustomerBookingsResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerFindCustomerBookings']>
  >
>;
export type CustomersControllerAddBlockedResourceResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerAddBlockedResource']>
  >
>;
export type CustomersControllerRemoveBlockedResourceResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['customersControllerRemoveBlockedResource']>
  >
>;
export type SettingsControllerFindAllResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerFindAll']>>
>;
export type SettingsControllerUpsertServiceRateResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerUpsertServiceRate']>
  >
>;
export type SettingsControllerCreateDateTimeSurchargeResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerCreateDateTimeSurcharge']
    >
  >
>;
export type SettingsControllerUpdateDateTimeSurchargeResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerUpdateDateTimeSurcharge']
    >
  >
>;
export type SettingsControllerDeleteDayDateSurchargeResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerDeleteDayDateSurcharge']>
  >
>;
export type SettingsControllerCreateExpressSurchargeResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerCreateExpressSurcharge']>
  >
>;
export type SettingsControllerUpdateExpressSurchargeResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerUpdateExpressSurcharge']>
  >
>;
export type SettingsControllerDeleteExpressSurchargeResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerDeleteExpressSurcharge']>
  >
>;
export type SettingsControllerCreateTravelSurchargeResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerCreateTravelSurcharge']>
  >
>;
export type SettingsControllerUpdateTravelSurchargeResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerUpdateTravelSurcharge']>
  >
>;
export type SettingsControllerDeleteTravelSurchargeResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerDeleteTravelSurcharge']>
  >
>;
export type SettingsControllerUpsertOpeningHoursResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['settingsControllerUpsertOpeningHours']>
  >
>;
export type LexofficeControllerUpsertContactResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['lexofficeControllerUpsertContact']>>
>;
export type LexofficeControllerUpdateInvoiceStatusResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['lexofficeControllerUpdateInvoiceStatus']>
  >
>;
export type InvoiceControllerGetInvoicesResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['invoiceControllerGetInvoices']>>
>;
export type InvoiceControllerCreateInvoiceResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['invoiceControllerCreateInvoice']>>
>;
export type InvoiceControllerGetChargeableServiceBookingsByCustomerResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getSMPBackendAPIAdmin
      >['invoiceControllerGetChargeableServiceBookingsByCustomer']
    >
  >
>;
export type MailsControllerSentCustomerCancellationLinkResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getSMPBackendAPIAdmin>['mailsControllerSentCustomerCancellationLink']
    >
  >
>;
export type ReportsControllerGetRequestReportResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['reportsControllerGetRequestReport']>>
>;
export type ReportsControllerGetBookingReportResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['reportsControllerGetBookingReport']>>
>;
export type ReportsControllerGetBookingReportExcelResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIAdmin>['reportsControllerGetBookingReportExcel']>
  >
>;
