import { Dayjs } from 'dayjs';

import { BookingType } from './booking.model';

export type Setting = {
  languageRates: LanguageRate[];
  travelSurcharges: TravelSurcharge[];
  expressSurcharges: ExpressSurcharge[];
  dayDateSurcharges: DateTimeSurcharge[];
  openingHours: OpeningHour[];
};

export type LanguageRate = {
  id: string;
  rate: number;
  languageBookingType: BookingType;
};

export type TravelSurcharge = {
  id: string;
  price: number;
  distance: number;
  resourceShare: number;
};

export type ExpressSurcharge = {
  id: string;
  percentage: number;
  minutes: number;
  resourceShare: number;
};

export type DateTimeSurcharge = {
  id: string;
  name: string;
  percentage: number;
  startTime: Dayjs;
  endTime: Dayjs;
  recurrenceType: DateTimeSurchargeRecurrenceType;
  interval: number | null;
  resourceShare: number;
};

export type DateTimeSurchargeRecurrenceType =
  (typeof DateTimeSurchargeRecurrenceType)[keyof typeof DateTimeSurchargeRecurrenceType];

export const DateTimeSurchargeRecurrenceType = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  YEARLY: 'YEARLY',
  null: null,
} as const;

export type OpeningHour = {
  id: string;
  day: OpeningHourDay;
  openTime?: string;
  closeTime?: string;
  isOpen?: boolean;
};

export type OpeningHourDay = (typeof OpeningHourDay)[keyof typeof OpeningHourDay];

export const OpeningHourDay = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
} as const;
