import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function acceptJobRequest(id: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerAcceptRequestedServiceBooking(id);
}

export function useAcceptJobRequest() {
  return useMutation({ mutationFn: acceptJobRequest });
}
