import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { App, Button } from 'antd';
import { useTranslation } from 'react-i18next';

type DeleteButtonProps = {
  isArchived?: boolean;
  onDelete: () => void;
  onReactivate?: () => void;
};

export function DeleteButton({ onDelete, onReactivate, isArchived }: DeleteButtonProps) {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  function handleDelete() {
    void modal.confirm({
      title: t('forms:deleteEntry'),
      content: t('forms:deleteEntryDescription'),
      onOk: onDelete,
      okButtonProps: { danger: true },
      okText: t('forms:delete'),
      centered: true,
    });
  }

  if (isArchived && onReactivate) {
    return (
      <Button type='text' icon={<UndoOutlined />} onClick={onReactivate}>
        {t('common:reactivate')}
      </Button>
    );
  } else if (isArchived) {
    return null;
  }

  return (
    <Button danger type='text' icon={<DeleteOutlined />} onClick={handleDelete}>
      {t('forms:delete')}
    </Button>
  );
}
