import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function cancelResourceBooking(bookingId: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerConfirmCancel(bookingId);
}

export function useCancelResourceBooking() {
  return useMutation({ mutationFn: cancelResourceBooking });
}
