import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Flex, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { SelectMultipleBookingStatus } from '@/components/booking';
import { SelectMultipleCustomers } from '@/components/customer';
import { SelectMultipleLanguages } from '@/components/language';
import { SelectMultipleResources } from '@/components/resource';
import { BookingStatus, BookingType } from '@/models';
import { MONTH_YEAR_FORMAT } from '@/utils/calendarHelper';
import { useCustomers } from '@/web-api/customers';
import { useLanguages } from '@/web-api/languages';
import { useResources } from '@/web-api/resources';

import { SelectMultipleBookingTypes } from '../booking/SelectMultipleBookingTypes';

import { CreateBookingModal } from './CreateBookingModal';

type CalendarHeaderProps = {
  selectedDate: Dayjs;
  selectedTypes: BookingType[];
  selectedCustomers: string[];
  selectedLanguages: string[];
  selectedResources: string[];
  selectedStatus: BookingStatus[];
  onChange: (
    value: Dayjs,
    selectedTypes: BookingType[],
    customerIds: string[],
    serviceIds: string[],
    resourceIds: string[],
    selectedStatus: BookingStatus[]
  ) => void;
};

export const CalendarHeader = ({
  selectedDate,
  selectedTypes,
  selectedCustomers,
  selectedLanguages,
  selectedResources,
  selectedStatus,
  onChange,
}: CalendarHeaderProps) => {
  const { t } = useTranslation();
  const { data: customers } = useCustomers();
  const { data: languages } = useLanguages();
  const { data: resources } = useResources();

  const onMonthChange = (date: Dayjs) => {
    updateFilter(
      date,
      selectedTypes,
      selectedCustomers,
      selectedLanguages,
      selectedResources,
      selectedStatus
    );
  };
  const onTypeChange = (value: BookingType[]) => {
    updateFilter(
      selectedDate,
      value,
      selectedCustomers,
      selectedLanguages,
      selectedResources,
      selectedStatus
    );
  };
  const onCustomerChange = (value: string[]) => {
    updateFilter(
      selectedDate,
      selectedTypes,
      value,
      selectedLanguages,
      selectedResources,
      selectedStatus
    );
  };
  const onServiceChange = (value: string[]) => {
    updateFilter(
      selectedDate,
      selectedTypes,
      selectedCustomers,
      value,
      selectedResources,
      selectedStatus
    );
  };
  const onResourceChange = (value: string[]) => {
    updateFilter(
      selectedDate,
      selectedTypes,
      selectedCustomers,
      selectedLanguages,
      value,
      selectedStatus
    );
  };
  const onStatusChange = (value: BookingStatus[]) => {
    updateFilter(
      selectedDate,
      selectedTypes,
      selectedCustomers,
      selectedLanguages,
      selectedResources,
      value
    );
  };

  const updateFilter = (
    date: Dayjs,
    selectedTypes: BookingType[],
    selectedCustomers: string[],
    selectedLanguages: string[],
    selectedResources: string[],
    selectedStatus: BookingStatus[]
  ) => {
    onChange(
      date,
      selectedTypes,
      selectedCustomers,
      selectedLanguages,
      selectedResources,
      selectedStatus
    );
  };

  return (
    <Flex gap='small'>
      <Space style={{ width: '100%' }}>
        <SelectMultipleBookingTypes
          selectedTypes={selectedTypes}
          type={Object.values(BookingType)}
          onChange={onTypeChange}
        />
        <SelectMultipleBookingStatus
          selectedStatus={selectedStatus}
          status={Object.values(BookingStatus)}
          onChange={onStatusChange}
        />
        <SelectMultipleCustomers
          selectedCustomers={selectedCustomers}
          customers={customers}
          onChange={onCustomerChange}
        />
        <Col xs={0} xl={24}>
          <SelectMultipleLanguages
            selectedLanguages={selectedLanguages}
            languages={languages}
            onChange={onServiceChange}
          />
        </Col>
        <Col xs={0} xl={24}>
          <SelectMultipleResources
            selectedResources={selectedResources}
            resources={resources}
            onChange={onResourceChange}
          />
        </Col>
      </Space>
      <Space style={{ width: '100%' }}>
        <Button
          aria-label='Monat zurück'
          size='small'
          onClick={() => onMonthChange(selectedDate.month(selectedDate.month() - 1))}
          icon={<ArrowLeftOutlined />}
        />
        <Button aria-label='Heute' size='small' onClick={() => onMonthChange(dayjs())}>
          {t('common:today')}
        </Button>
        <Button
          aria-label='Monat vorwärts'
          size='small'
          onClick={() => onMonthChange(selectedDate.month(selectedDate.month() + 1))}
          icon={<ArrowRightOutlined />}
        />
        <Col xs={0} xl={24}>
          <DatePicker
            aria-label='Monat auswählen'
            picker='month'
            size='small'
            value={selectedDate}
            onChange={onMonthChange}
            format={MONTH_YEAR_FORMAT}
          />
        </Col>
        <CreateBookingModal selectedDate={selectedDate} />
      </Space>
    </Flex>
  );
};
