import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, FormInstance, Space } from 'antd';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

type EditSaveButtonProps = {
  form: FormInstance<any>;
  isEditing: boolean;
  isDisabled: boolean;
  onCancel: () => void;
  onSave: () => void;
  onEdit: () => void;
  editButtonAriaLabel?: string;
};

export function EditSaveButton({
  isEditing,
  isDisabled,
  onCancel,
  onSave,
  onEdit,
  editButtonAriaLabel,
}: EditSaveButtonProps) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {isEditing ? (
        <Space>
          <Button aria-label='Verwerfen' type='default' icon={<CloseOutlined />} onClick={onCancel}>
            {t('forms:discard')}
          </Button>
          <Button aria-label='Speichern' type='primary' icon={<SaveOutlined />} onClick={onSave}>
            {t('forms:save')}
          </Button>
        </Space>
      ) : (
        <Button
          aria-label={editButtonAriaLabel}
          type='primary'
          icon={<EditOutlined />}
          onClick={onEdit}
          disabled={isDisabled}
        >
          {t('forms:edit')}
        </Button>
      )}
    </Fragment>
  );
}
