import { useMutation } from '@tanstack/react-query';

import { ReportsControllerGetBookingReportExcelParams, getSMPBackendAPIAdmin } from '@/proxy';

function getDashboardExport(params: ReportsControllerGetBookingReportExcelParams) {
  return getSMPBackendAPIAdmin().reportsControllerGetBookingReportExcel(params);
}

export function useDashboardExport() {
  return useMutation({ mutationFn: getDashboardExport });
}
