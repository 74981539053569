import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toSetting } from '@/services';

function getSettings() {
  return getSMPBackendAPIAdmin().settingsControllerFindAll();
}

export function useSettings() {
  return useQuery({
    queryKey: queryKeys.settings.lists(),
    queryFn: getSettings,
    select: (data) => toSetting(data),
  });
}
