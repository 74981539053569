import { App, Button, Col, Descriptions, Form, Input, Row, Typography } from 'antd';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CenterSpinner } from '@/components/shared';
import { useDelayedLoading } from '@/hooks';
import { BookingStatus, BookingType } from '@/models';
import { dateWithTimeAndDay, showErrorMessage } from '@/utils';
import { useGetVerfiyWebBooking, useVerifyWebBooking } from '@/web-api/bookings';

import { WebLayout } from '.';

const { Title, Paragraph } = Typography;

export function WebVerifyForm() {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { bookingId } = useParams();
  if (bookingId === undefined) {
    throw new Error('Expected bookingId to be defined');
  }

  const verifyWebBookingQuery = useGetVerfiyWebBooking(bookingId);
  const verifyWebBookingMutation = useVerifyWebBooking();

  const showDelayedLoading = useDelayedLoading(verifyWebBookingMutation.isPending);

  function onVerify(values: { issuer: string; clientReference?: string }) {
    bookingId &&
      verifyWebBookingMutation.mutate(
        { bookingId, params: values },
        {
          onSuccess: () => {
            void modal.success({
              title: t('forms:bookingVerificationFormSuccessTitle'),
              content: t('forms:bookingVerificationFormSuccessDescription'),
              onOk: () => {
                window.location.href = '/web/request';
              },
            });
          },
        }
      );
  }

  if (verifyWebBookingQuery.isLoading) {
    return <CenterSpinner isLoading />;
  }

  if (verifyWebBookingQuery.data?.status === BookingStatus.VERIFIED) {
    return (
      <Row justify='center' align='middle' style={{ height: '100vh', textAlign: 'center' }}>
        <Col span={16}>
          <Title level={1}>{t('forms:bookingVerifyFormAlreadyVerified')}</Title>
        </Col>
      </Row>
    );
  }

  if (verifyWebBookingQuery.isError) {
    return (
      <Row justify='center' align='middle' style={{ height: '100vh', textAlign: 'center' }}>
        <Col span={16}>
          <Title level={1}>{t(showErrorMessage(verifyWebBookingQuery.error.code))}</Title>
        </Col>
      </Row>
    );
  }

  return (
    <WebLayout isLoading={showDelayedLoading}>
      <Fragment>
        <Title level={1}>{t('forms:bookingVerifyFormTitle')}</Title>
        <Paragraph>{t('forms:bookingVerifyFormWelcome')}</Paragraph>
        <Title level={2}>{t('common:bookingDetail')}</Title>
        <Descriptions bordered column={1}>
          <Descriptions.Item label={t('common:reference')}>
            {verifyWebBookingQuery.data?.reference}
          </Descriptions.Item>
          <Descriptions.Item label={t('forms:startTime')}>
            {dateWithTimeAndDay(verifyWebBookingQuery.data?.date)}
          </Descriptions.Item>
          <Descriptions.Item label={t('common:language')}>
            {verifyWebBookingQuery.data?.language}
          </Descriptions.Item>
          <Descriptions.Item label={t('common:resource')}>
            {verifyWebBookingQuery.data?.resource}
          </Descriptions.Item>
          {verifyWebBookingQuery.data?.type && (
            <Descriptions.Item label={t('common:bookingType')}>
              {t(`common:${verifyWebBookingQuery.data.type}`)}
            </Descriptions.Item>
          )}
          {verifyWebBookingQuery?.data?.type === BookingType.DOCUMENT ? (
            <>
              <Descriptions.Item label={t('common:file')}>
                <a
                  href={verifyWebBookingQuery.data.verification.fileUrl}
                  target='_blank'
                  rel='noreferrer'
                >
                  {verifyWebBookingQuery.data.verification.fileName}
                </a>
              </Descriptions.Item>
              <Descriptions.Item label={t('forms:wordCount')}>
                {t('wordWithCount', { count: verifyWebBookingQuery.data?.verification.value })}
              </Descriptions.Item>
            </>
          ) : (
            <Descriptions.Item label={t('forms:duration')}>
              {t('minuteWithCount', {
                count: verifyWebBookingQuery.data?.verification?.value ?? 0,
              })}{' '}
              (
              {t('hourWithCount', {
                count: parseFloat(
                  ((verifyWebBookingQuery?.data?.verification?.value ?? 0) / 60).toFixed(2)
                ),
              })}
              )
            </Descriptions.Item>
          )}
        </Descriptions>
        <Form
          onFinish={onVerify}
          initialValues={{ clientReference: verifyWebBookingQuery.data?.clientReference }}
          layout={'vertical'}
        >
          <Form.Item
            name={'issuer'}
            label={t('forms:fullname')}
            tooltip={t('forms:issuerLabel')}
            rules={[{ required: true, min: 2 }]}
          >
            <Input placeholder={t('forms:fullnamePlaceholder')} />
          </Form.Item>
          <Form.Item
            label={t('forms:reference')}
            name={'clientReference'}
            tooltip={t('forms:referenceHelp')}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Button htmlType='submit' type='primary'>
            {t('common:confirm')}
          </Button>
        </Form>
      </Fragment>
    </WebLayout>
  );
}
