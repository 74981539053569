/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Admin)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */

export type ResourceDtoEmploymentType =
  (typeof ResourceDtoEmploymentType)[keyof typeof ResourceDtoEmploymentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceDtoEmploymentType = {
  MAIN: 'MAIN',
  SECONDARY: 'SECONDARY',
} as const;
