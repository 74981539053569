import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Col, Input, List, Pagination, Row, Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useDelayedLoading } from '@/hooks';
import { useCustomersPaginated } from '@/web-api/customers';

export const CustomerList = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<string | number>('desc');
  const [query, setQuery] = useState<string | undefined>();
  const customersQuery = useCustomersPaginated({
    limit: pageSize,
    page: currentPage,
    sort: sortOrder as string,
    query: query,
  });
  const showDelayedLoading = useDelayedLoading(customersQuery.isLoading);

  function handlePageChange(page: number, pageSize?: number) {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  }

  function handleSortChange(sortOrder: SegmentedValue) {
    setSortOrder(sortOrder);
  }

  return (
    <Row justify='center' gutter={[12, 12]}>
      <Col>
        <Segmented
          size='large'
          value={sortOrder}
          options={[
            { value: 'asc', label: <SortAscendingOutlined /> },
            { value: 'desc', label: <SortDescendingOutlined /> },
          ]}
          onChange={handleSortChange}
        />
      </Col>
      <Col span={24}>
        <List
          header={
            <Input
              value={query}
              placeholder={t('common:search')}
              allowClear
              onChange={(value) => setQuery(value.target.value)}
            />
          }
          size='small'
          itemLayout='horizontal'
          loading={showDelayedLoading}
          dataSource={customersQuery.data?.data}
          renderItem={(customer) => (
            <List.Item
              actions={[
                <Link key={customer.id} to={customer.id}>
                  {t('common:show')}
                </Link>,
              ]}
            >
              <List.Item.Meta
                style={{ alignItems: 'center' }}
                title={`${customer.company} (${customer.number})`}
                description={`${customer.contactFirstname} ${customer.contactLastname}`}
              />
            </List.Item>
          )}
        />
      </Col>
      <Col>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={customersQuery.data?.total || 0}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={(current, size) => handlePageChange(current, size)}
        />
      </Col>
    </Row>
  );
};
