import { useQueryClient } from '@tanstack/react-query';
import { Form, Space, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { queryKeys } from '@/lib/react-query';
import { showMessage } from '@/utils';
import { useResourceCloseBooking } from '@/web-api/bookings';

const { Paragraph, Text } = Typography;

type ConfirmTimeProps = {
  bookingId: string;
  form: FormInstance<any>;
};

export function ConfirmTime({ bookingId, form }: ConfirmTimeProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [startDateValue, setStartDateValue] = useState('');
  const [startTimeValue, setStartTimeValue] = useState('');
  const [endDateValue, setEndDateValue] = useState('');
  const [endTimeValue, setEndTimeValue] = useState('');

  const closeBookingMutation = useResourceCloseBooking();

  const onConfirmBooking = () => {
    const combinedStartDateTimeValue = `${startDateValue}T${startTimeValue}`;
    const combinedEndDateTimeValue = `${endDateValue}T${endTimeValue}`;
    const difference = dayjs(combinedEndDateTimeValue).diff(combinedStartDateTimeValue, 'minute');
    if (difference && difference > 0) {
      closeBookingMutation.mutate(
        { bookingId, value: difference, startTime: dayjs(combinedStartDateTimeValue).format() },
        {
          onSuccess: () => {
            showMessage('success', t('common:CONFIRMED'));
            void queryClient.invalidateQueries({ queryKey: queryKeys.resourceBookings.all });
            navigate(`/app/mybookings`);
          },
        }
      );
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark='optional'
      initialValues={{ date: startDateValue, time: startTimeValue }}
      onFinish={onConfirmBooking}
    >
      <Paragraph>{t('common:confirmBookingText')}</Paragraph>
      <Space direction={'vertical'}>
        <Text strong>{t('forms:startTime')}:</Text>
        <Space>
          <Form.Item name='startDate' noStyle>
            <input
              aria-label='Terminstart Datum'
              type='date'
              onChange={(e) => setStartDateValue(e.target.value)}
            />
          </Form.Item>
          <Form.Item name='startTime' noStyle>
            <input
              aria-label='Terminstart Zeit'
              type='time'
              onChange={(e) => setStartTimeValue(e.target.value)}
            />
          </Form.Item>
        </Space>
        <Text strong>{t('forms:endTime')}:</Text>
        <Space>
          <Form.Item name='endDate' noStyle>
            <input
              aria-label='Terminende Datum'
              type='date'
              onChange={(e) => setEndDateValue(e.target.value)}
            />
          </Form.Item>
          <Form.Item name='endTime' noStyle>
            <input
              aria-label='Terminende Zeit'
              type='time'
              onChange={(e) => setEndTimeValue(e.target.value)}
            />
          </Form.Item>
        </Space>
      </Space>
    </Form>
  );
}
