/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Admin)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */

export type ServiceBookingDtoType =
  (typeof ServiceBookingDtoType)[keyof typeof ServiceBookingDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceBookingDtoType = {
  DOCUMENT: 'DOCUMENT',
  ONSITE: 'ONSITE',
  REMOTE: 'REMOTE',
  PHONE: 'PHONE',
} as const;
