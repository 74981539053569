import dayjs from 'dayjs';

import {
  Booking,
  BookingGroup,
  BookingStatus,
  BookingType,
  DocumentBooking,
  OnsiteBooking,
  PaginatedBookings,
  PaginatedBookingsDto,
  PhoneBooking,
  RemoteBooking,
  ResourceBooking,
  ResourceJob,
  Surcharge,
  SurchargeType,
  Verification,
} from '@/models';
import {
  DocumentBookingDto,
  OnsiteBookingDto,
  PhoneBookingDto,
  RemoteBookingDto,
  ResourceJobDto,
  ResourceServiceBookingDto,
  ServiceBookingDto,
  ServiceBookingDtoStatus,
  ServiceBookingDtoType,
  ServiceBookingGroupDto,
  SurchargeDto,
  VerificationDto,
} from '@/proxy';
import { DEFAULT_DATE_FORMAT, centsToEuros, decimalToPercent } from '@/utils';

import { toCustomer } from './customer.service';
import { toLanguage } from './language.service';
import { toResource } from './resource.service';

import { toInvoice } from '.';

export const toPaginatedBooking = (data: PaginatedBookingsDto): PaginatedBookings => {
  return {
    data: data.data.map(toBooking),
    limit: data.limit,
    page: data.page,
    total: data.total,
  };
};

export const toBookingGroup = (dto: ServiceBookingGroupDto): BookingGroup => ({
  date: dto.date,
  bookings: dto.bookings.map(toBooking),
});

export const toBooking = (dto: ServiceBookingDto): Booking => ({
  id: dto.id,
  reference: dto.reference,
  date: dayjs(dto.date),
  customer: dto.customer && toCustomer(dto.customer),
  debtor: dto.debtor && toCustomer(dto.debtor),
  type: toBookingTypeEnum(dto.type),
  language: toLanguage(dto.language),
  alternativeLanguage: dto.alternativeLanguage ? toLanguage(dto.alternativeLanguage) : undefined,
  status: toBookingStatusEnum(dto.status),
  customerCharges: (dto.customerCharges ?? []).map(toSurcharge),
  resourceCharges: (dto.resourceCharges ?? []).map(toSurcharge),
  contactPerson: dto.contactPerson,
  notes: dto.notes,
  specialRate: dto.specialRate && centsToEuros(dto.specialRate),
  resource: dto.resource && toResource(dto.resource),
  acceptsAlternativeResource: dto?.acceptsAlternativeResource,
  requestExpiresAt: dto?.requestExpiresAt ? dayjs(dto.requestExpiresAt) : undefined,
  onsite: dto.onsite && toOnsiteBooking(dto.onsite),
  remote: dto.remote && toRemoteBooking(dto.remote),
  phone: dto.phone && toPhoneBooking(dto.phone),
  document: dto.document && toDocumentBooking(dto.document),
  verification: dto.verification ? toVerification(dto.verification) : undefined,
  client: dto.client && {
    name: dto.client?.name,
    birthDate: dto.client?.birthDate
      ? dayjs(dto.client?.birthDate).format(DEFAULT_DATE_FORMAT)
      : undefined,
    reference: dto.client?.reference,
  },
  gender: dto.gender,
  invoice: dto.invoice && toInvoice(dto.invoice),
  createdAt: dayjs(dto.created),
  updatedAt: dayjs(dto.updated),
});

export const toResourceBooking = (dto: ResourceServiceBookingDto): ResourceBooking => ({
  id: dto.id,
  reference: dto.reference,
  language: dto.language,
  customer: dto.customer,
  date: dayjs(dto.date),
  type: toBookingTypeEnum(dto.type),
  status: toBookingStatusEnum(dto.status),
  resourceCharges: dto.resourceCharges.map(toSurcharge),
  contactPerson: dto.contactPerson,
  specialRate: dto.specialRate,
  notes: dto.notes,
  resource: dto.resource && toResource(dto.resource),
  onsite: dto.onsite && toOnsiteBooking(dto.onsite),
  remote: dto.remote && toRemoteBooking(dto.remote),
  document: dto.document && toDocumentBooking(dto.document),
  phone: dto.phone && toPhoneBooking(dto.phone),
  verification: dto.verification ? toVerification(dto.verification) : undefined,
  gender: dto.gender,
});

export const toResourceJob = (dto: ResourceJobDto): ResourceJob => ({
  id: dto.id,
  reference: dto.reference,
  date: dayjs(dto.date),
  type: toBookingTypeEnum(dto.type),
  language: dto.language,
  alternativeLanguage: dto.alternativeLanguage,
  status: toBookingStatusEnum(dto.status),
  resourceCharges: dto.resourceCharges.map(toSurcharge),
  contactPerson: dto.contactPerson,
  specialRate: dto.specialRate && centsToEuros(dto.specialRate),
  notes: dto.notes,
  onsite: dto.onsite,
  remote: dto.remote,
  document: dto.document,
  phone: dto.phone,
  gender: dto.gender,
  requestExpirationDate: dto.requestExpirationDate ? dayjs(dto.requestExpirationDate) : undefined,
});

export const toOnsiteBooking = (dto: OnsiteBookingDto): OnsiteBooking => ({
  duration: dto.duration,
  alternativeService: dto.alternativeService,
  distance: dto.distance,
  location: dto.location,
});

export const toRemoteBooking = (dto: RemoteBookingDto): RemoteBooking => ({
  duration: dto.duration,
  emails: dto.emails,
  meetingUrl: dto.meetingUrl,
});

export const toPhoneBooking = (dto: PhoneBookingDto): PhoneBooking => ({
  duration: dto.duration,
  zoomConferenceNumber: dto.zoomConferenceNumber,
  zoomConferenceId: dto.zoomConferenceId,
  zoomConferencePassword: dto.zoomConferencePassword,
  dialInNumber: dto.dialInNumber,
  meetingUrl: dto.meetingUrl,
  clientPhone: dto.clientPhone,
  customerPhone: dto.customerPhone,
});

export const toDocumentBooking = (dto: DocumentBookingDto): DocumentBooking => ({
  fileId: dto.fileId,
  fileName: dto.fileName,
  viewUrl: dto.viewUrl,
  downloadUrl: dto.downloadUrl,
  wordCount: dto.wordCount,
});

const toBookingStatusEnum = (status: ServiceBookingDtoStatus): BookingStatus => {
  switch (status) {
    case ServiceBookingDtoStatus.OPEN:
      return BookingStatus.OPEN;
    case ServiceBookingDtoStatus.PUBLISHED:
      return BookingStatus.PUBLISHED;
    case ServiceBookingDtoStatus.CONFIRMED:
      return BookingStatus.CONFIRMED;
    case ServiceBookingDtoStatus.CLOSED:
      return BookingStatus.CLOSED;
    case ServiceBookingDtoStatus.VERIFIED:
      return BookingStatus.VERIFIED;
    case ServiceBookingDtoStatus.CANCELED:
      return BookingStatus.CANCELED;
    case ServiceBookingDtoStatus.REQUESTED:
      return BookingStatus.REQUESTED;
    case ServiceBookingDtoStatus.DECLINED:
      return BookingStatus.DECLINED;
    default:
      return BookingStatus.CANCELED;
  }
};

export const toBookingTypeEnum = (type: ServiceBookingDtoType) => {
  switch (type) {
    case ServiceBookingDtoType.DOCUMENT:
      return BookingType.DOCUMENT;
    case ServiceBookingDtoType.ONSITE:
      return BookingType.ONSITE;
    case ServiceBookingDtoType.REMOTE:
      return BookingType.REMOTE;
    case ServiceBookingDtoType.PHONE:
      return BookingType.PHONE;
  }
};

export const toSurcharge = (data: SurchargeDto): Surcharge => {
  return {
    name: data.name,
    type: data.type,
    value:
      data.type === SurchargeType.PERCENTAGE
        ? decimalToPercent(data.value)
        : centsToEuros(data.value),
    duration: data.duration,
    description: data.description,
  };
};

export function toVerification(dto: VerificationDto): Verification {
  return {
    id: dto.id,
    issuer: dto.issuer,
    fileUrl: dto.fileUrl,
    fileName: dto.fileName,
    value: dto.value,
    startTime: dto.startTime ? dayjs(dto.startTime) : undefined,
  };
}
