import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

export type GetBillableBookingsExportParams = {
  resourceId: string;
  month: number;
  year: number;
};

function getBillableBookingsExport(params: GetBillableBookingsExportParams): Promise<Blob> {
  return getSMPBackendAPIAdmin().resourceBookingsControllerGetBillableBookingsExport(
    params.resourceId,
    {
      month: params.month,
      year: params.year,
    }
  );
}

export function useBillableBookingsExport() {
  return useMutation({ mutationFn: getBillableBookingsExport });
}
