/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Admin)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */

export type ServiceBookingDtoStatus =
  (typeof ServiceBookingDtoStatus)[keyof typeof ServiceBookingDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceBookingDtoStatus = {
  OPEN: 'OPEN',
  PUBLISHED: 'PUBLISHED',
  CONFIRMED: 'CONFIRMED',
  CLOSED: 'CLOSED',
  VERIFIED: 'VERIFIED',
  CANCELED: 'CANCELED',
  REQUESTED: 'REQUESTED',
  DECLINED: 'DECLINED',
} as const;
