import {
  DownloadOutlined,
  EyeOutlined,
  FileAddOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStatus, BookingType, PhoneBooking } from '@/models';
import {
  DATETIME_WITH_DAY_FORMAT,
  disabledDate,
  disabledDateTime,
  getRange,
  isButtonDisabled,
} from '@/utils';

const { Paragraph, Link } = Typography;
const { Dragger } = Upload;

type BookingSubFormProps = {
  type?: BookingType;
  status?: BookingStatus;
  mode: 'create' | 'edit' | 'view';
};

export function BookingSubForm({ type, mode, status }: BookingSubFormProps) {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const isCreate = mode === 'create';
  const meetingUrl = form.getFieldValue(['remote', 'meetingUrl']);
  const phoneBooking = form.getFieldValue(['phone']) as PhoneBooking;
  const showConferenceCall =
    phoneBooking?.meetingUrl &&
    status !== BookingStatus.CLOSED &&
    status !== BookingStatus.VERIFIED &&
    status !== BookingStatus.CANCELED;
  const showMeetingUrl =
    meetingUrl &&
    status !== BookingStatus.CLOSED &&
    status !== BookingStatus.VERIFIED &&
    status !== BookingStatus.CANCELED;

  const phoneItems: DescriptionsProps['items'] = [
    {
      key: 'zoomConferenceNumber',
      label: t('common:zoomConferenceNumber'),
      children: phoneBooking?.zoomConferenceNumber,
    },
    {
      key: 'zoomConferenceId',
      label: t('common:zoomConferenceId'),
      children: phoneBooking?.zoomConferenceId,
    },
    {
      key: 'dialInNumber',
      label: t('common:dialInNumber'),
      children: phoneBooking?.dialInNumber,
    },
    {
      key: 'meetingUrl',
      label: t('common:meetingUrl'),
      children: (
        <Link copyable href={phoneBooking?.meetingUrl} target='_blank' rel='noreferrer'>
          {phoneBooking?.meetingUrl}
        </Link>
      ),
    },
  ];

  function isDisabled(
    allowedStatuses: BookingStatus[] = [BookingStatus.OPEN, BookingStatus.PUBLISHED]
  ) {
    if (mode === 'create' || status === undefined) {
      return false;
    }
    if (mode === 'edit') {
      return isButtonDisabled(status, allowedStatuses);
    }
    return true;
  }

  const normFile = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return e?.fileList as UploadFile[];
  };

  const props: UploadProps = {
    multiple: false,
    disabled: isDisabled([BookingStatus.OPEN]),
    onRemove: () => {
      form.setFieldValue(['document', 'file'], undefined);
    },
    beforeUpload: (file) => {
      form.setFieldValue(['document', 'file'], [file]);

      return false;
    },
  };

  const renderFormItemsByBookingType = (type?: BookingType) => {
    switch (type) {
      case BookingType.ONSITE:
        return (
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:startTime')} name='date' rules={[{ required: true }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  showNow={false}
                  needConfirm={false}
                  showTime={{
                    minuteStep: 5,
                  }}
                  format={DATETIME_WITH_DAY_FORMAT}
                  disabled={isDisabled([BookingStatus.OPEN])}
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:duration')}
                name={['onsite', 'duration']}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder={t('forms:select')}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={getRange(1, 8, 0.25).map((minutes) => ({
                    value: minutes,
                    label: `${t('common:minuteWithCount', { count: minutes })} (${t(
                      'common:hourWithCount',
                      { count: minutes / 60 }
                    )})`,
                  }))}
                  onSelect={(value) => form.setFieldValue(['onsite', 'duration'], value)}
                  disabled={isDisabled([BookingStatus.OPEN])}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('forms:location')}
                name={['onsite', 'location', 'name']}
                rules={[{ required: true }]}
              >
                <Input disabled={isDisabled([BookingStatus.OPEN])} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:street')}
                name={['onsite', 'location', 'street']}
                rules={[{ required: true }]}
              >
                <Input disabled={isDisabled([BookingStatus.OPEN])} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:supplement')}
                name={['onsite', 'location', 'supplement']}
                rules={[{ required: false }]}
              >
                <Input disabled={isDisabled([BookingStatus.OPEN])} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:zip')}
                name={['onsite', 'location', 'zip']}
                rules={[{ required: true }]}
              >
                <Input disabled={isDisabled([BookingStatus.OPEN])} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:city')}
                name={['onsite', 'location', 'city']}
                rules={[{ required: true }]}
              >
                <Input disabled={isDisabled([BookingStatus.OPEN])} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                hidden={true}
                label={t('forms:country')}
                name={['onsite', 'location', 'country']}
                rules={[{ required: true }]}
                initialValue={'Deutschland'}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                name={['onsite', 'alternativeService']}
                label={t('forms:alternativeServiceLabel')}
                valuePropName='checked'
              >
                <Checkbox disabled={isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED])}>
                  {t('forms:alternativeService')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        );
      case BookingType.REMOTE:
        return (
          <Row gutter={[12, 12]}>
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:startTime')} name='date' rules={[{ required: true }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  showNow={false}
                  needConfirm={false}
                  showTime={{
                    minuteStep: 5,
                  }}
                  format={DATETIME_WITH_DAY_FORMAT}
                  disabled={isDisabled([BookingStatus.OPEN])}
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:duration')}
                name={['remote', 'duration']}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder={t('forms:select')}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={getRange(1, 8, 0.25).map((minutes) => ({
                    value: minutes,
                    label: `${t('common:minuteWithCount', { count: minutes })} (${t(
                      'common:hourWithCount',
                      { count: minutes / 60 }
                    )})`,
                  }))}
                  onSelect={(value) => form.setFieldValue('durationInHours', value)}
                  disabled={isDisabled([BookingStatus.OPEN])}
                />
              </Form.Item>
            </Col>
            {showMeetingUrl && (
              <Space>
                <Space.Compact>
                  <Button disabled={false}>
                    <Paragraph copyable={{ text: meetingUrl }}>{meetingUrl}</Paragraph>
                  </Button>
                  <Button type={'primary'} href={meetingUrl} target={'_blank'} disabled={false}>
                    {t('common:joinMeeting')}
                  </Button>
                </Space.Compact>
              </Space>
            )}
            <Col span={24}>
              <Form.List
                name={['remote', 'emails']}
                rules={[
                  {
                    validator: async (_, emails: string[]) => {
                      if (!emails || emails.length < 2) {
                        return Promise.reject(new Error(t('forms:atLeastTwoParticipantsRequired')));
                      }
                    },
                    message: t('forms:atLeastTwoParticipantsRequired'),
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={t('participant', { count: index + 1 })}
                        key={field.key}
                        required={true}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message: t('forms:emailInvalid'),
                            },
                            {
                              type: 'email',
                              message: t('forms:emailInvalid'),
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            aria-label={`Participant email ${index + 1}`}
                            style={{ width: '90%' }}
                            disabled={isDisabled([
                              BookingStatus.OPEN,
                              BookingStatus.PUBLISHED,
                              BookingStatus.CONFIRMED,
                            ])}
                          />
                        </Form.Item>
                        {fields.length >= 1 ? (
                          <Button
                            danger
                            icon={<MinusOutlined />}
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: 12 }}
                            disabled={isDisabled([
                              BookingStatus.OPEN,
                              BookingStatus.PUBLISHED,
                              BookingStatus.CONFIRMED,
                            ])}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        disabled={isDisabled([
                          BookingStatus.OPEN,
                          BookingStatus.PUBLISHED,
                          BookingStatus.CONFIRMED,
                        ])}
                      >
                        {t('forms:addParticipant')}
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        );
      case BookingType.PHONE:
        return (
          <Row gutter={[12, 12]}>
            <Alert type='warning' showIcon description={t('forms:phoneAlert')} />
            <Col xs={24} md={12}>
              <Form.Item label={t('forms:startTime')} name='date' rules={[{ required: true }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  showNow={false}
                  needConfirm={false}
                  showTime={{
                    minuteStep: 5,
                  }}
                  format={DATETIME_WITH_DAY_FORMAT}
                  disabled={isDisabled([BookingStatus.OPEN])}
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:duration')}
                name={['phone', 'duration']}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder={t('forms:select')}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={getRange(1, 8, 0.25).map((minutes) => ({
                    value: minutes,
                    label: `${t('common:minuteWithCount', { count: minutes })} (${t(
                      'common:hourWithCount',
                      { count: minutes / 60 }
                    )})`,
                  }))}
                  onSelect={(value) => form.setFieldValue('durationInMinutes', value)}
                  disabled={isDisabled([BookingStatus.OPEN])}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={['phone', 'customerPhone']}
                label={t('forms:customerPhoneNumber')}
                rules={[{ required: true }]}
              >
                <Input
                  disabled={isDisabled([
                    BookingStatus.OPEN,
                    BookingStatus.PUBLISHED,
                    BookingStatus.CONFIRMED,
                  ])}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={['phone', 'clientPhone']}
                label={t('forms:clientPhoneNumber')}
                rules={[{ required: true }]}
              >
                <Input
                  disabled={isDisabled([
                    BookingStatus.OPEN,
                    BookingStatus.PUBLISHED,
                    BookingStatus.CONFIRMED,
                  ])}
                />
              </Form.Item>
            </Col>
            {showConferenceCall ? <Descriptions bordered column={2} items={phoneItems} /> : null}
          </Row>
        );
      case BookingType.DOCUMENT:
        return (
          <Fragment>
            <Form.Item hidden name={['document', 'fileId']} />
            <Row gutter={[12, 12]}>
              <Col xs={24} md={12}>
                <Form.Item label={t('forms:dueDate')} name='date' rules={[{ required: true }]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    showNow={false}
                    needConfirm={false}
                    showTime={{
                      minuteStep: 5,
                    }}
                    format={DATETIME_WITH_DAY_FORMAT}
                    disabled={isDisabled([BookingStatus.OPEN])}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                  />
                </Form.Item>
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={t('forms:wordCount')}
                      name={['document', 'wordCount']}
                      rules={[{ required: true }]}
                    >
                      <InputNumber<number>
                        precision={0}
                        controls={false}
                        addonAfter='Wörter'
                        formatter={(value) => (value ? `${value}` : '')}
                        disabled={isDisabled([BookingStatus.OPEN])}
                      />
                    </Form.Item>
                  </Col>
                  {mode !== 'create' && (
                    <Col xs={24} xxl={12}>
                      <Form.Item label={t('forms:currentFile')} required={true}>
                        <Space>
                          <Input
                            disabled={true}
                            value={form.getFieldValue(['document', 'fileName'])}
                          />
                          <Button
                            icon={<EyeOutlined />}
                            href={form.getFieldValue(['document', 'viewUrl']) as string}
                            target='_blank'
                            rel='noreferrer'
                            disabled={false}
                          >
                            {t('common:viewHere')}
                          </Button>
                          <Button
                            icon={<DownloadOutlined />}
                            href={form.getFieldValue(['document', 'downloadUrl']) as string}
                            target='_blank'
                            rel='noreferrer'
                            disabled={false}
                          >
                            {t('common:downloadHere')}
                          </Button>
                        </Space>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item noStyle>
                  <Form.Item
                    label={t('forms:dataUploadLabel')}
                    name={['document', 'file']}
                    valuePropName='fileList'
                    rules={[{ required: isCreate }]}
                    getValueFromEvent={normFile}
                  >
                    <Dragger {...props}>
                      <p className='ant-upload-drag-icon'>
                        <FileAddOutlined />
                      </p>
                      <p className='ant-upload-text'>{t('forms:uploadFileText')}</p>
                      <p className='ant-upload-hint'>{t('forms:uploadFileHint')}</p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
          </Fragment>
        );
      default:
        return <Typography.Text>{t('forms:selectService')}</Typography.Text>;
    }
  };

  return renderFormItemsByBookingType(type);
}
