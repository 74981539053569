import { InfoCircleOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Card, Space, Tooltip, Typography } from 'antd';
import { CardTabListType } from 'antd/es/card';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { BlockedResourceListFooter, CustomerBookings } from '@/components/customer';
import { ResourceList } from '@/components/resource';
import { useDelayedLoading } from '@/hooks';
import { queryClient, queryKeys } from '@/lib/react-query';
import { showMessage } from '@/utils';
import {
  useCustomer,
  usePutCustomerBlockedResource,
  useRemoveCustomerBlockedResource,
} from '@/web-api/customers';
import { useResources } from '@/web-api/resources';

export const CustomerDetailPage = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>('bookings');
  const { t } = useTranslation();
  const { customerId } = useParams();
  if (customerId === undefined) {
    throw new Error('Expected resourceId to be defined');
  }
  const { data, isLoading } = useCustomer(customerId);

  const showDelayedLoading = useDelayedLoading(isLoading);
  const { data: resources } = useResources();

  const putCustomerBlockedResource = usePutCustomerBlockedResource();
  const removeCustomerBlockedResource = useRemoveCustomerBlockedResource();

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const onPutBlockedResource = (resourceIds: string[]) => {
    putCustomerBlockedResource.mutate(
      { customerId, resourceIds },
      {
        onSuccess: () => {
          showMessage('success', t('forms:saved'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.customers.detail(customerId) });
        },
      }
    );
  };

  const onRemoveBlockedResource = (resourceId: string) => {
    removeCustomerBlockedResource.mutate(
      { customerId, resourceId },
      {
        onSuccess: () => {
          showMessage('success', t('forms:saved'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.customers.detail(customerId) });
        },
      }
    );
  };

  const tabList: CardTabListType[] = [
    {
      key: 'bookings',
      label: t('common:bookings'),
    },
    {
      key: 'blockList',
      label: t('common:blockedResources'),
    },
    {
      key: 'contact',
      label: t('common:contact'),
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    bookings: <CustomerBookings customerId={customerId} />,
    blockList: (
      <Fragment>
        <Alert
          message={t('common:blockedResourcesInformation')}
          description={t('common:blockedResourcesInformationDescription')}
          type='info'
          showIcon
        />
        <ResourceList
          isLoading={showDelayedLoading}
          resources={data?.blockedResources}
          onRemove={onRemoveBlockedResource}
          footer={
            <BlockedResourceListFooter
              resources={resources?.filter(
                (resource) =>
                  !data?.blockedResources
                    .map((blockedResource) => blockedResource.id)
                    .includes(resource.id)
              )}
              onSubmit={onPutBlockedResource}
            />
          }
        />
      </Fragment>
    ),
    contact: (
      <Card>
        <Typography.Title level={5}>{t('common:contactPerson')}</Typography.Title>
        <Typography.Paragraph>
          <Space>
            <UserOutlined />
            <Typography.Text>
              {data?.contactFirstname} {data?.contactLastname}
            </Typography.Text>
          </Space>
        </Typography.Paragraph>
        <Typography.Paragraph copyable={{ text: data?.contactEmail }}>
          <Space>
            <MailOutlined />
            <a href={`mailto:${data ? data?.contactEmail : ''}`}>{data?.contactEmail}</a>
          </Space>
        </Typography.Paragraph>
        <Typography.Paragraph copyable={{ text: data?.contactPhone }}>
          <Space>
            <PhoneOutlined />
            <a href={`tel:${data ? data?.contactPhone : ''}`}>{data?.contactPhone}</a>
          </Space>
        </Typography.Paragraph>
      </Card>
    ),
  };

  return (
    <Card
      title={
        <Space size='small'>
          <span>
            {data?.company} ({data?.number})
          </span>
          <Tooltip title={t('common:lexofficeCustomerHint')}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      }
      loading={showDelayedLoading}
      tabProps={{ size: 'small' }}
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      extra={
        <Link
          to={`https://app.lexoffice.de/contacts/${data ? data?.lexofficeId : ''}`}
          target='_blank'
          rel='norefferer noreferrer'
        >
          {t('common:editLexoffice')}
        </Link>
      }
      bodyStyle={{ overflowY: 'auto', height: '100%', paddingBottom: '6rem' }}
      style={{ height: '100%', overflow: 'hidden' }}
    >
      {contentList[activeTabKey]}
    </Card>
  );
};
