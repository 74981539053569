/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useQueryClient } from '@tanstack/react-query';
import { useResponsive } from 'ahooks';
import { Button, Divider, Form, InputNumber, Popconfirm, Space, Table } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { ExpressSurcharge } from '@/models';
import { logger, showMessage } from '@/utils';
import {
  useCreateExpressSurcharge,
  useDeleteExpressSurcharge,
  useUpdateExpressSurcharge,
} from '@/web-api/settings';

type ExpressSurchargesProps = {
  expressSurcharges: ExpressSurcharge[];
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  record: ExpressSurcharge;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  children,
  ...restProps
}) => {
  const inputNode =
    dataIndex === 'rate' || dataIndex === 'resourceShare' ? (
      <InputNumber min={1} precision={2} decimalSeparator=',' />
    ) : (
      <InputNumber min={1} />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export function ExpressSurcharges({ expressSurcharges }: ExpressSurchargesProps) {
  const [editingId, setEditingId] = useState('');
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [rowForm] = Form.useForm();
  const queryClient = useQueryClient();
  const breakpoint = useResponsive();

  const createExpressSurchargeMutation = useCreateExpressSurcharge();
  const deleteExpressSurchargeMutation = useDeleteExpressSurcharge();
  const updateExpressSurchargeMutation = useUpdateExpressSurcharge();

  const isEditing = (record: ExpressSurcharge) => record.id === editingId;

  const edit = (record: ExpressSurcharge) => {
    rowForm.setFieldValue('percentage', record.percentage);
    rowForm.setFieldValue('resourceShare', record.resourceShare);
    rowForm.setFieldValue('minutes', record.minutes);
    record.id && setEditingId(record.id);
  };

  const cancel = () => {
    setEditingId('');
  };

  const save = async () => {
    try {
      const row = (await rowForm.validateFields()) as ExpressSurcharge;
      updateExpressSurchargeMutation.mutate(
        {
          id: editingId,
          percentage: row.percentage,
          minutes: row.minutes,
          resourceShare: row.resourceShare,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
            showMessage('success', t('forms:saved'));
            setEditingId('');
          },
        }
      );
    } catch (errInfo) {
      logger(`Validate Failed: ${String(errInfo)}`, 'error');
    }
  };

  function onFinish(values: ExpressSurcharge) {
    createExpressSurchargeMutation.mutate(values, {
      onSuccess: () => {
        form.resetFields();
        queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
        showMessage('success', t('forms:saved'));
      },
    });
  }

  function onDelete(expressSurchargeId: string) {
    deleteExpressSurchargeMutation.mutate(expressSurchargeId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
        showMessage('success', t('forms:deleted'));
      },
    });
  }

  const columns = [
    {
      title: t('surcharge'),
      dataIndex: 'percentage',
      render: (value: ExpressSurcharge['percentage']) => `${value}%`,
      editable: true,
    },
    {
      title: t('surchargeResourceShare'),
      dataIndex: 'resourceShare',
      render: (value: ExpressSurcharge['resourceShare']) => `${value}%`,
      editable: true,
    },
    {
      title: t('forms:duration'),
      dataIndex: 'minutes',
      editable: true,
      render: (minutes: ExpressSurcharge['minutes']) => (
        <>
          {t('minuteWithCount', { count: minutes })} (
          {t('hourWithCount', { count: parseFloat((minutes / 60).toFixed(2)) })})
        </>
      ),
    },
    {
      title: t('actions'),
      width: '25%',
      render: (_: any, record: ExpressSurcharge) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <Space size='small'>
                <Button type='primary' size='small' onClick={save}>
                  {t('forms:save')}
                </Button>
                <Button size='small' onClick={cancel}>
                  {t('forms:cancel')}
                </Button>
              </Space>
            ) : (
              <Button
                type='text'
                size='small'
                disabled={editingId !== ''}
                onClick={() => edit(record)}
              >
                {t('forms:edit')}
              </Button>
            )}
            <Popconfirm
              title={t('forms:deleteEntry')}
              onConfirm={() => onDelete(record.id)}
              okButtonProps={{ danger: true }}
              okText={t('forms:delete')}
            >
              <Button danger type='text' size='small' disabled={!!editingId}>
                Löschen
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ExpressSurcharge) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Fragment>
      <Form form={rowForm} component={false}>
        <Table
          rowKey='id'
          size='small'
          bordered
          dataSource={expressSurcharges}
          columns={mergedColumns}
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
      <Divider orientation='left'>{t('add')}</Divider>
      <Form
        form={form}
        name='expressSurchargesForm'
        layout={breakpoint.xl ? 'inline' : 'vertical'}
        onFinish={onFinish}
      >
        <Form.Item name='percentage' label={t('common:surcharge')} rules={[{ required: true }]}>
          <InputNumber min={1} precision={2} addonAfter='%' decimalSeparator=',' />
        </Form.Item>
        <Form.Item
          name='resourceShare'
          label={t('common:surchargeResourceShare')}
          rules={[{ required: true }]}
          extra={t('forms:surchargeResourceShareExtra')}
        >
          <InputNumber min={1} precision={2} addonAfter='%' decimalSeparator=',' />
        </Form.Item>
        <Form.Item name='minutes' label={t('forms:duration')} rules={[{ required: true }]}>
          <InputNumber min={1} addonAfter={t('common:minute_other')} />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit'>{t('add')}</Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}
