/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useQueryClient } from '@tanstack/react-query';
import { useResponsive } from 'ahooks';
import { Button, Divider, Form, InputNumber, Popconfirm, Space, Table } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { TravelSurcharge } from '@/models';
import { formatCurrency, logger, showMessage } from '@/utils';
import {
  useCreateTravelSurcharge,
  useDeleteTravelSurcharge,
  useUpdateTravelSurcharge,
} from '@/web-api/settings';

type TravelSurchargesProps = {
  travelSurcharges: TravelSurcharge[];
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  record: TravelSurcharge;
  index: number;
  children: React.ReactNode;
}

export function TravelSurcharges({ travelSurcharges }: TravelSurchargesProps) {
  const [editingId, setEditingId] = useState('');
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [rowForm] = Form.useForm();
  const queryClient = useQueryClient();
  const breakpoint = useResponsive();

  const createTravelSurchargeMutation = useCreateTravelSurcharge();
  const updateTravelSurchargeMutation = useUpdateTravelSurcharge();
  const deleteTravelSurchargeMutation = useDeleteTravelSurcharge();

  const isEditing = (record: TravelSurcharge) => record.id === editingId;

  const edit = (record: TravelSurcharge) => {
    rowForm.setFieldValue('distance', record.distance);
    rowForm.setFieldValue('price', record.price);
    rowForm.setFieldValue('resourceShare', record.resourceShare);
    record.id && setEditingId(record.id);
  };

  const cancel = () => {
    setEditingId('');
  };

  const save = async () => {
    try {
      const row = (await rowForm.validateFields()) as TravelSurcharge;
      updateTravelSurchargeMutation.mutate(
        {
          id: editingId,
          distance: row.distance,
          price: row.price,
          resourceShare: row.resourceShare,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
            setEditingId('');
            showMessage('success', t('forms:saved'));
          },
        }
      );
    } catch (errInfo) {
      logger(`Validate Failed: ${String(errInfo)}`, 'error');
    }
  };

  function onFinish(values: TravelSurcharge) {
    createTravelSurchargeMutation.mutate(values, {
      onSuccess: () => {
        form.resetFields();
        queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
        showMessage('success', t('forms:saved'));
      },
    });
  }

  function onDelete(travelSurchargeId: string) {
    deleteTravelSurchargeMutation.mutate(travelSurchargeId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
        showMessage('success', t('forms:deleted'));
      },
    });
  }

  const columns = [
    {
      title: t('surcharge'),
      dataIndex: 'price',
      render: (value: TravelSurcharge['price']) => formatCurrency('de-DE', value),
      editable: true,
    },
    {
      title: t('surchargeResourceShare'),
      dataIndex: 'resourceShare',
      render: (value: TravelSurcharge['resourceShare']) => formatCurrency('de-DE', value),
      editable: true,
    },
    {
      title: t('distance'),
      dataIndex: 'distance',
      editable: true,
      render: (distance: TravelSurcharge['distance']) => `${distance} km`,
    },
    {
      title: t('actions'),
      width: '25%',
      render: (_: any, record: TravelSurcharge) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <Space size='small'>
                <Button type='primary' size='small' onClick={save}>
                  {t('forms:save')}
                </Button>
                <Button size='small' onClick={cancel}>
                  {t('forms:cancel')}
                </Button>
              </Space>
            ) : (
              <Button
                type='text'
                size='small'
                disabled={editingId !== ''}
                onClick={() => edit(record)}
              >
                {t('forms:edit')}
              </Button>
            )}
            <Popconfirm
              title={t('forms:deleteEntry')}
              onConfirm={() => onDelete(record.id)}
              okButtonProps={{ danger: true }}
              okText={t('forms:delete')}
            >
              <Button danger type='text' size='small' disabled={!!editingId}>
                Löschen
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TravelSurcharge) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    children,
    ...restProps
  }) => {
    const inputNode = () => {
      switch (dataIndex) {
        case 'price':
          return (
            <InputNumber<number>
              precision={2}
              controls={false}
              formatter={(value) => (value ? `${value}` : '')}
              onChange={(value) => value && rowForm.setFieldsValue({ price: value })}
              decimalSeparator=','
            />
          );
        case 'resourceShare':
          return (
            <InputNumber<number>
              precision={2}
              controls={false}
              formatter={(value) => (value ? `${value}` : '')}
              onChange={(value) => value && rowForm.setFieldsValue({ resourceShare: value })}
              decimalSeparator=','
            />
          );
        default:
          return <InputNumber min={1} addonAfter='km' />;
      }
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            {inputNode()}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <Fragment>
      <Form form={rowForm} component={false}>
        <Table
          rowKey='id'
          size='small'
          bordered
          dataSource={travelSurcharges}
          columns={mergedColumns}
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
      <Divider orientation='left'>{t('add')}</Divider>
      <Form
        form={form}
        name='travelSurchargesForm'
        layout={breakpoint.xl ? 'inline' : 'vertical'}
        onFinish={onFinish}
      >
        <Form.Item name='price' label={t('surcharge')} rules={[{ required: true }]}>
          <InputNumber<number>
            precision={2}
            controls={false}
            addonAfter='€'
            formatter={(value) => (value ? `${value}` : '')}
            onChange={(value) => value && form.setFieldsValue({ price: value })}
            decimalSeparator=','
          />
        </Form.Item>
        <Form.Item
          name='resourceShare'
          label={t('surchargeResourceShare')}
          rules={[{ required: true }]}
        >
          <InputNumber<number>
            precision={2}
            controls={false}
            addonAfter='€'
            formatter={(value) => (value ? `${value}` : '')}
            onChange={(value) => value && form.setFieldsValue({ resourceShare: value })}
            decimalSeparator=','
          />
        </Form.Item>
        <Form.Item name='distance' label={t('distance')} rules={[{ required: true }]}>
          <InputNumber min={1} addonAfter='km' />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit'>{t('add')}</Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}
