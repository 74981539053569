import { DashboardBooking, DashboardRequest } from '@/models';
import { BookingStatusCountsResponseDto, CustomerRequestsReportDto } from '@/proxy';

export function toDashboardRequest(dto: CustomerRequestsReportDto): DashboardRequest {
  return {
    start: dto.start,
    end: dto.end,
    total: dto.total,
    data: (dto.data ?? []).map((set) => ({
      date: set.date,
      count: set.count,
    })),
  };
}

export function toDashboardBooking(dto: BookingStatusCountsResponseDto): DashboardBooking {
  return {
    start: dto.start,
    end: dto.end,
    data: (dto.data ?? []).map((set) => ({
      date: set.date,
      status: set.status,
      count: set.count,
    })),
  };
}
