import { Select } from 'antd';
import { t } from 'i18next';

import { Language } from '@/models';

type SelectLanguageProps = {
  selectedLanguage: string | undefined;
  languages?: Language[];
  onChange: (value?: string) => void;
  isDisabled?: boolean;
  ariaLabel?: string;
};

export const SelectLanguage = ({
  selectedLanguage,
  languages,
  onChange,
  isDisabled,
  ariaLabel,
}: SelectLanguageProps) => {
  return (
    <Select
      aria-label={ariaLabel}
      disabled={isDisabled}
      showSearch
      value={selectedLanguage}
      placeholder={t('forms:select')}
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={languages?.map((s) => ({
        value: s.id,
        label: s.name,
      }))}
      onChange={onChange}
      onClear={() => onChange(undefined)}
      allowClear
    />
  );
};
