import { Select } from 'antd';
import { t } from 'i18next';

import { Resource } from '@/models';
import { formatResourceName } from '@/utils/resourceHelper';

type SelectResourceProps = {
  selectedResource: string | undefined;
  resources?: Resource[];
  onChange: (value?: string) => void;
  disabled?: boolean;
};

export const SelectResource = ({
  selectedResource,
  resources,
  onChange,
  disabled,
}: SelectResourceProps) => {
  return (
    <Select
      value={selectedResource}
      popupMatchSelectWidth
      disabled={disabled}
      showSearch
      placeholder={t('forms:select')}
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={resources?.map((s) => ({
        value: s.id,
        label: formatResourceName(s.firstname, s.lastname, 'formal'),
      }))}
      onChange={onChange}
      onClear={() => onChange(undefined)}
      allowClear
    />
  );
};
