import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toResourceBooking } from '@/services';

function getBooking(bookingId: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerFindBookingId(bookingId);
}

export function useResourceBooking(bookingId: string) {
  return useQuery({
    queryKey: queryKeys.resourceBookings.detail(bookingId),
    queryFn: () => getBooking(bookingId),
    select: (data) => toResourceBooking(data),
    enabled: !!bookingId,
  });
}
