import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  List,
  Row,
  Space,
  Statistic,
  Tag,
  theme,
  Typography,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useDelayedLoading } from '@/hooks';
import { BillableServiceBooking, BookingType, CancellationFee } from '@/models';
import { ROUTE_CONSTANTS } from '@/routes';
import {
  centsToEuros,
  formatCurrency,
  getBookingTypeIcon,
  getStatusColor,
  MONTH_YEAR_FORMAT,
} from '@/utils';
import { useBillableBookings, useBillableBookingsExport } from '@/web-api/bookings';

type ResourceStatsProps = {
  resourceId: string;
};

export function ResourceStats({ resourceId }: ResourceStatsProps) {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const { t } = useTranslation();
  const { data, isLoading } = useBillableBookings({
    resourceId: resourceId,
    month: selectedDate.month(),
    year: selectedDate.year(),
  });
  const { mutate: billableBookingsExportMutate, isPending: exportIsLoading } =
    useBillableBookingsExport();
  const showDelayedLoading = useDelayedLoading(isLoading);
  const billableCount = data?.billableBookings?.length ?? 0;
  const canceledCount = data?.cancellationFees?.length ?? 0;

  function onMonthChange(date: Dayjs) {
    if (date) {
      setSelectedDate(date);
    }
  }

  function openBillableBookingsExport() {
    billableBookingsExportMutate(
      {
        resourceId: resourceId,
        month: selectedDate.month(),
        year: selectedDate.year(),
      },
      {
        onSuccess: (data) => {
          window.open(URL.createObjectURL(data), '_blank');
        },
      }
    );
  }

  const items: CollapseProps['items'] = [
    {
      key: 'verified',
      label: t('common:completed'),
      children: (
        <List
          size='small'
          dataSource={data?.billableBookings}
          renderItem={(item: BillableServiceBooking) => (
            <List.Item
              extra={
                <Link to={`/admin/${ROUTE_CONSTANTS.BOOKINGS_PATH}/${item.id}`}>
                  {t('common:show')}
                </Link>
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{ backgroundColor: colorPrimary }}
                    icon={React.createElement(getBookingTypeIcon(item.type), {
                      style: {
                        rotate: item.type === BookingType.PHONE ? '90deg' : '',
                      },
                    })}
                  />
                }
                title={
                  <Space split={'-'}>
                    <Typography.Text>{item.reference}</Typography.Text>
                    <Typography.Text>{item.customer}</Typography.Text>
                    <Typography.Text> {item.date}</Typography.Text>
                  </Space>
                }
                description={
                  <>
                    <Tag key={item.id} color={getStatusColor(item.status)}>
                      {t(item.status)}
                    </Tag>
                    <Typography.Text>{formatCurrency(undefined, item.total)}</Typography.Text>
                  </>
                }
              />
            </List.Item>
          )}
        />
      ),
    },
    {
      key: 'canceled',
      label: t('common:CANCELED'),
      children: (
        <List
          size='small'
          dataSource={data?.cancellationFees ?? []}
          renderItem={(item: CancellationFee) => (
            <List.Item>
              <List.Item.Meta
                title={`${item.name}: ${centsToEuros(item.value)}€`}
                description={item.description}
              />
            </List.Item>
          )}
        />
      ),
    },
  ];

  return (
    <Card
      title={t('common:bookings')}
      extra={
        <Space>
          <DatePicker
            aria-label='Monat auswählen'
            picker='month'
            value={selectedDate}
            onChange={onMonthChange}
            format={MONTH_YEAR_FORMAT}
            allowClear={false}
            style={{ minWidth: 150 }}
          />
          <Button type='primary' onClick={openBillableBookingsExport} loading={exportIsLoading}>
            {t('common:showAsPDF')}
          </Button>
        </Space>
      }
      loading={showDelayedLoading}
    >
      <Row gutter={[12, 12]}>
        <Col xs={24} md={8}>
          <Statistic title={t('common:completed')} value={billableCount} />
        </Col>
        <Col xs={24} md={8}>
          <Statistic title={t('common:CANCELED')} value={canceledCount} />
        </Col>
        <Col xs={24} md={8}>
          <Statistic
            title={t('common:billable')}
            value={Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
              data?.billableTotal ?? 0
            )}
          />
        </Col>
        <Col span={24}>
          <Collapse items={items} defaultActiveKey={['verified']} />
        </Col>
      </Row>
    </Card>
  );
}
