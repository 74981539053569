import {
  CalendarOutlined,
  ClockCircleOutlined,
  EuroCircleOutlined,
  IdcardOutlined,
  LaptopOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { Card, List, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ResourceBooking, ResourceJob } from '@/models';
import { DATETIME_WITHOUT_DAY_FORMAT, getStatusColor, showGender } from '@/utils';

import { JobBookingCardItemProps } from './detailsContent';

const { Text } = Typography;

export function RemoteBookingCardItem({ jobBooking, isJob, extra }: JobBookingCardItemProps) {
  const { t } = useTranslation();
  return (
    <List.Item>
      <Card
        size='small'
        title={`${jobBooking.language}${jobBooking.alternativeLanguage ? `/${jobBooking.alternativeLanguage}` : ''}`}
        extra={
          <Space>
            {jobBooking.gender && (
              <Tag color={jobBooking.gender === 'FEMALE' ? 'pink-inverse' : 'blue-inverse'}>
                {showGender(jobBooking.gender)}
              </Tag>
            )}
            <Tag color={getStatusColor(jobBooking.status)}>{t(jobBooking.status)}</Tag>
            <Tag icon={<LaptopOutlined />}>{t(jobBooking.type)}</Tag>
          </Space>
        }
        actions={extra ? [extra] : undefined}
        style={{ borderWidth: '1px', borderColor: '#000' }}
      >
        <Space direction='vertical'>
          {!isJob && (
            <Text>
              <IdcardOutlined />
              &nbsp;
              {(jobBooking as ResourceBooking).customer}
            </Text>
          )}
          <Text>
            <CalendarOutlined />
            &nbsp;
            {dayjs(jobBooking.date).format(DATETIME_WITHOUT_DAY_FORMAT)}
          </Text>
          <Text>
            <ClockCircleOutlined />
            &nbsp;
            {t('minuteWithCount', { count: jobBooking.remote?.duration })}
          </Text>
          <Text>
            <MailOutlined />{' '}
            {t('participant', {
              count: isJob
                ? (jobBooking as ResourceJob).remote?.participantsCount
                : (jobBooking as ResourceBooking).remote?.emails.length,
            })}
          </Text>
          {jobBooking.specialRate ? (
            <Text>
              <EuroCircleOutlined style={{ color: 'red' }} />
              &nbsp;
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {t('forms:specialPrice').toUpperCase()}: {jobBooking.specialRate} €
              </span>
            </Text>
          ) : null}
        </Space>
      </Card>
    </List.Item>
  );
}
