import { Navigate, RouteObject } from 'react-router-dom';

import { AdminIndex } from '@/apps/admin/AdminIndex';
import { AppIndex } from '@/apps/app/AppIndex';
import {
  AppBookingDetailPage,
  AppBookingsPage,
  AppContactPage,
  AppJobDetailsPage,
  AppJobRequestDetailsPage,
  AppJobsPage,
  AppProfilePage,
} from '@/apps/app/pages';
import { WebCancelForm, WebRequestForm, WebVerifyForm } from '@/apps/web';
import { AuthenticationGuard } from '@/components/auth';
import { UserDetailsPage } from '@/components/user';
import { BookingDetailPage, BookingsPage } from '@/pages/bookings';
import { CallbackPage } from '@/pages/CallbackPage';
import { CustomerDetailPage, CustomersPage } from '@/pages/customers';
import { DashboardPage } from '@/pages/dashboard';
import { BillingPage } from '@/pages/invoices';
import { LanguageDetailPage, LanguagesPage } from '@/pages/languages';
import { ResourceDetailPage, ResourcesPage } from '@/pages/resources';
import { SettingsPage } from '@/pages/settings';
import { ProfilePage, UsersPage } from '@/pages/user';
import { Welcome } from '@/pages/Welcome';

export const ROUTE_CONSTANTS = {
  HOME_PATH: '/',
  ROOT_PATH: '.',
  ADMIN_PATH: 'admin',
  APP_PATH: 'app',
  WEB_PATH: 'web',
  PROFILE_PATH: 'profile',
  USERS_PATH: 'users',
  BOOKINGS_PATH: 'bookings',
  CALENDAR_PATH: 'calendar',
  RESOURCES_PATH: 'resources',
  LANGUAGES_PATH: 'languages',
  CUSTOMERS_PATH: 'customers',
  SETTINGS_PATH: 'settings',
  BILLING_PATH: 'billing',
  DASHBOARD_PATH: 'dashboard',
  APP: {
    MYBOOKINGS: 'mybookings',
    JOBS: 'jobs',
    JOB_REQUEST: 'jobrequest',
    PROFILE: 'profile',
    CONTACT: 'contact',
  },
  WEB: {
    REQUEST: 'request',
    CANCEL: 'cancel',
    VERIFY: 'verify',
  },
};

export const appMenuItems = [
  {
    key: ROUTE_CONSTANTS.APP.MYBOOKINGS,
    label: 'common:bookings',
  },
  {
    key: ROUTE_CONSTANTS.APP.JOBS,
    label: 'common:jobs',
  },
  // {
  //   key: 'profile',
  //   label: 'header:yourProfile',
  // },
  {
    key: ROUTE_CONSTANTS.APP.CONTACT,
    label: 'common:contact',
  },
];

export const appRoutes: RouteObject[] = [
  {
    index: true,
    element: <Welcome />,
  },
  {
    path: `${ROUTE_CONSTANTS.HOME_PATH}`,
    children: [
      {
        path: `${ROUTE_CONSTANTS.ADMIN_PATH}`,
        element: <AuthenticationGuard roles={['admin', 'user']} component={AdminIndex} />,
        children: [
          {
            path: `${ROUTE_CONSTANTS.PROFILE_PATH}`,
            element: <ProfilePage />,
          },
          {
            path: `${ROUTE_CONSTANTS.USERS_PATH}`,
            element: <AuthenticationGuard roles={['admin']} component={UsersPage} />,
          },
          {
            path: `${ROUTE_CONSTANTS.USERS_PATH}/:userId`,
            element: <UserDetailsPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.RESOURCES_PATH}`,
            element: <ResourcesPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.RESOURCES_PATH}/:resourceId`,
            element: <ResourceDetailPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.LANGUAGES_PATH}`,
            element: <LanguagesPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.LANGUAGES_PATH}/:languageId`,
            element: <LanguageDetailPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.BOOKINGS_PATH}`,
            element: <BookingsPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.BOOKINGS_PATH}/:bookingId`,
            element: <BookingDetailPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.CUSTOMERS_PATH}`,
            element: <CustomersPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.CUSTOMERS_PATH}/:customerId`,
            element: <CustomerDetailPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.BILLING_PATH}`,
            element: <AuthenticationGuard roles={['admin']} component={BillingPage} />,
          },
          {
            path: `${ROUTE_CONSTANTS.SETTINGS_PATH}`,
            element: <AuthenticationGuard roles={['admin']} component={SettingsPage} />,
          },
          {
            path: `${ROUTE_CONSTANTS.DASHBOARD_PATH}`,
            element: <AuthenticationGuard roles={['admin']} component={DashboardPage} />,
          },
          {
            path: '*',
            element: <Navigate to={`.`} replace />,
          },
        ],
      },
      {
        path: `${ROUTE_CONSTANTS.APP_PATH}`,
        element: <AuthenticationGuard roles={['resource']} component={AppIndex} />,
        children: [
          {
            path: ROUTE_CONSTANTS.APP.MYBOOKINGS,
            element: <AppBookingsPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.APP.MYBOOKINGS}/:bookingId`,
            element: <AppBookingDetailPage />,
          },
          {
            path: ROUTE_CONSTANTS.APP.JOBS,
            element: <AppJobsPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.APP.JOBS}/:jobId`,
            element: <AppJobDetailsPage />,
          },
          {
            path: `${ROUTE_CONSTANTS.APP.JOB_REQUEST}/:jobId`,
            element: <AppJobRequestDetailsPage />,
          },
          {
            path: ROUTE_CONSTANTS.APP.PROFILE,
            element: <AppProfilePage />,
          },
          {
            path: ROUTE_CONSTANTS.APP.CONTACT,
            element: <AppContactPage />,
          },
          {
            path: '*',
            element: <Navigate to={`.`} replace />,
          },
        ],
      },
      {
        path: ROUTE_CONSTANTS.WEB_PATH,
        children: [
          {
            index: true,
            path: ROUTE_CONSTANTS.WEB.REQUEST,
            element: <WebRequestForm />,
          },
          {
            path: `${ROUTE_CONSTANTS.WEB.CANCEL}/:bookingId`,
            element: <WebCancelForm />,
          },
          {
            path: `${ROUTE_CONSTANTS.WEB.VERIFY}/:bookingId`,
            element: <WebVerifyForm />,
          },
          {
            path: '*',
            element: <Navigate to={`./${ROUTE_CONSTANTS.WEB.REQUEST}`} replace />,
          },
        ],
      },
      {
        path: 'callback',
        element: <CallbackPage />,
      },
    ],
  },
];
