import { LogoutOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Layout, Row, Space, Typography, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTE_CONSTANTS } from '@/routes';
import { formatResourceName } from '@/utils';
import { useCurrentUser } from '@/web-api/users';

const { Header: AntHeader } = Layout;
const { Text } = Typography;

export function Header() {
  const { data } = useCurrentUser();
  const { t } = useTranslation('header');
  const { token } = theme.useToken();
  const { logout } = useAuth0();

  return (
    <AntHeader style={{ backgroundColor: token.colorWhite, paddingLeft: 0 }}>
      <Row align='middle' justify='space-between'>
        <Text strong>SMP Sprachmittlerpool Berlin</Text>
        <Space size='large'>
          <Link to={ROUTE_CONSTANTS.PROFILE_PATH}>
            {formatResourceName(data?.firstname ?? '', data?.lastname ?? '', 'non-formal')}
          </Link>
          <Button
            danger
            type='text'
            icon={<LogoutOutlined />}
            onClick={() =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            }
          >
            {t('logout')}
          </Button>
        </Space>
      </Row>
    </AntHeader>
  );
}
