import { useMutation } from '@tanstack/react-query';

import { CreateUpdateTravelSurchargeDto, getSMPBackendAPIAdmin } from '@/proxy';
import { eurosToCents, kmToMeters } from '@/utils';

function toRequest({
  distance,
  price,
  resourceShare,
}: CreateUpdateTravelSurchargeDto): CreateUpdateTravelSurchargeDto {
  return {
    distance: kmToMeters(distance),
    price: eurosToCents(price),
    resourceShare: eurosToCents(resourceShare),
  };
}

function createTravelSurcharge(travel: CreateUpdateTravelSurchargeDto) {
  return getSMPBackendAPIAdmin().settingsControllerCreateTravelSurcharge(toRequest(travel));
}

export function useCreateTravelSurcharge() {
  return useMutation({ mutationFn: createTravelSurcharge });
}
