import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function deleteUser(userId: string) {
  return getSMPBackendAPIAdmin().usersControllerDelete(userId);
}

export function useDeleteUser() {
  return useMutation({ mutationFn: deleteUser });
}
