import { useMutation } from '@tanstack/react-query';

import { Surcharge, SurchargeType } from '@/models';
import { UpdateSurchargeRequest, getSMPBackendAPIAdmin } from '@/proxy';
import { eurosToCents, percentToDecimal } from '@/utils';

const toRequest = (surcharges: Surcharge[]): UpdateSurchargeRequest => ({
  charges: surcharges.map((charge) => ({
    name: charge.name,
    value:
      charge.type === SurchargeType.PERCENTAGE
        ? percentToDecimal(charge.value)
        : eurosToCents(charge.value),
    type: charge.type,
    description: charge.description,
    duration: charge.duration,
  })),
});

function updateResourceCharges(data: { id: string; model: Surcharge[] }) {
  const modelRequest = toRequest(data.model);
  return getSMPBackendAPIAdmin().serviceBookingsControllerUpdateResourceSurcharges(
    data.id,
    modelRequest
  );
}

export function useUpdateResourceCharges() {
  return useMutation({ mutationFn: updateResourceCharges });
}
