/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Admin)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */

export type ResourceDtoGermanLanguageLevel =
  (typeof ResourceDtoGermanLanguageLevel)[keyof typeof ResourceDtoGermanLanguageLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceDtoGermanLanguageLevel = {
  A1: 'A1',
  A2: 'A2',
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  C2: 'C2',
} as const;
