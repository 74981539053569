import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function declineJobRequest(id: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerDeclineRequestedServiceBooking(id);
}

export function useDeclineJobRequest() {
  return useMutation({ mutationFn: declineJobRequest });
}
