import { ThemeConfig, theme } from 'antd';

const globalDesignToken: ThemeConfig['token'] = {
  colorPrimary: '#eab308',
  colorBgLayout: '#0f172a0d',
};

export const themeConfig: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: globalDesignToken,
};
