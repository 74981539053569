import { useMutation } from '@tanstack/react-query';

import { CustomerRequest } from '@/models';
import { CreateCustomerRequest, getSMPBackendAPIAdmin } from '@/proxy';

const toRequest = (customer: CustomerRequest): CreateCustomerRequest => ({
  company: customer.company,
  street: customer.street,
  zip: customer.zip,
  city: customer.city,
  country: customer.country,
  firstName: customer.firstName,
  lastName: customer.lastName,
  email: customer.email,
  phone: customer.phone,
});

function createCustomer(customer: CustomerRequest) {
  const data = toRequest(customer);
  return getSMPBackendAPIAdmin().customersControllerCreate(data);
}

export function useCreateCustomer() {
  return useMutation({ mutationFn: createCustomer });
}
