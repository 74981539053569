import { Select } from 'antd';
import { t } from 'i18next';

import { Resource } from '@/models';
import { formatResourceName } from '@/utils/resourceHelper';

type SelectMultipleResourceProps = {
  selectedResources: string[];
  resources?: Resource[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
};

export const SelectMultipleResources = ({
  selectedResources,
  resources,
  onChange,
  disabled,
}: SelectMultipleResourceProps) => {
  return (
    <Select
      aria-label='Ressourcen filtern'
      mode='multiple'
      value={selectedResources}
      popupMatchSelectWidth={false}
      maxTagCount={'responsive'}
      disabled={disabled}
      showSearch
      placeholder={t('common:resource')}
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={resources?.map((resource) => ({
        value: resource.id,
        label: `${formatResourceName(resource.firstname, resource.lastname, 'formal')} - ${
          resource.email
        }`,
      }))}
      onChange={onChange}
      onClear={() => onChange([])}
      allowClear
      style={{ minWidth: 100 }}
    />
  );
};
