import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { defaultStaleTime, queryKeys } from '@/lib/react-query';
import { PaginatedBookingsDto } from '@/models';
import { CustomersControllerFindCustomerBookingsParams, getSMPBackendAPIAdmin } from '@/proxy';
import { toPaginatedBooking } from '@/services';

function getCustomerBookings(id: string, params: CustomersControllerFindCustomerBookingsParams) {
  return getSMPBackendAPIAdmin().customersControllerFindCustomerBookings(
    id,
    params
  ) as Promise<PaginatedBookingsDto>;
}

export function useCustomerBookings(
  id: string,
  params: CustomersControllerFindCustomerBookingsParams
) {
  return useQuery({
    queryKey: queryKeys.customers.bookings(id, params),
    queryFn: () => getCustomerBookings(id, params),
    select: (data) => toPaginatedBooking(data),
    placeholderData: keepPreviousData,
    staleTime: defaultStaleTime,
  });
}
