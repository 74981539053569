import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useResponsive } from 'ahooks';
import { Button, Layout, Menu, Row, Select, Typography, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { appMenuItems } from '@/routes';

const { Sider, Content, Header } = Layout;
const { Text } = Typography;

export function AppLayout() {
  const { i18n } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [currentRoute, setCurrentRoute] = useState('');
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const breakpoint = useResponsive();

  useEffect(() => {
    if (location.pathname.includes('mybookings')) {
      setCurrentRoute('mybookings');
    } else if (location.pathname.includes('jobs')) {
      setCurrentRoute('jobs');
    } else if (location.pathname.includes('profile')) {
      setCurrentRoute('profile');
    } else if (location.pathname.includes('contact')) {
      setCurrentRoute('contact');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ height: '100vh', width: '100vw', flexDirection: 'row', overflow: 'hidden' }}>
      <Sider
        breakpoint='lg'
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth='0'
        onBreakpoint={(broken) => {
          broken ? setCollapsed(true) : setCollapsed(false);
        }}
        theme='light'
        width={breakpoint.lg ? 200 : '100%'}
      >
        <Row
          justify='space-between'
          align='middle'
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            height: 64,
          }}
        >
          {breakpoint.lg ? (
            <img src='/smpLogo180.png' alt='SMP Logo' height={48} width={48} />
          ) : (
            <Text strong>SMP Sprachmittlerpool Berlin GmbH</Text>
          )}
          {!breakpoint.lg && (
            <Button type='text' onClick={() => setCollapsed(true)} icon={<CloseOutlined />} />
          )}
        </Row>
        <Menu
          theme='light'
          mode='inline'
          selectedKeys={[currentRoute]}
          items={appMenuItems.map(({ key, label }) => ({
            key,
            label: t(label),
          }))}
          onClick={(info) => navigate(info.key)}
        />
        <Button
          danger
          type='text'
          onClick={() => logout()}
          style={{ position: 'relative', left: 12 }}
        >
          {t('header:logout')}
        </Button>
        <br />
        <Select
          value={i18n.language}
          style={{ width: '65%', marginLeft: 24, marginTop: 24 }}
          onChange={(event) => i18n.changeLanguage(event)}
        >
          <Select.Option value='de-DE'>Deutsch</Select.Option>
          <Select.Option value='en-GB'>English</Select.Option>
        </Select>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Row
            justify='space-between'
            align='middle'
            style={{ paddingLeft: 24, paddingRight: 24, height: '100%' }}
          >
            {breakpoint.lg ? (
              <Text strong>SMP Sprachmittlerpool Berlin GmbH</Text>
            ) : (
              <img src='/smpLogo180.png' alt='SMP Logo' height={48} width={48} />
            )}
            {!breakpoint.lg && (
              <Button type='default' icon={<MenuOutlined />} onClick={() => setCollapsed(false)} />
            )}
          </Row>
        </Header>
        <Content style={{ margin: '12px 12px 0' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
