import { Alert, Col, Row, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

type CenterSpinnerProps = {
  isLoading: boolean;
  isRequest?: boolean;
};

export function CenterSpinner(props: CenterSpinnerProps) {
  const { t } = useTranslation();
  if (!props.isLoading) {
    return null;
  }

  return (
    <Row
      justify='center'
      align='middle'
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        textAlign: 'center',
        backdropFilter: 'blur(2px)',
        zIndex: 1000,
      }}
    >
      <Col span={16}>
        <Space direction='vertical'>
          <Spin size='large' spinning />
          {props.isRequest && <Alert type='warning' message={t('forms:isProcessing')} />}
        </Space>
      </Col>
    </Row>
  );
}
