import { Card, Col, Form, Row, Space, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { LanguageList } from '@/components/language';
import { LanguageListFooter, ResourceForm } from '@/components/resource';
import { DeleteButton, EditSaveButton } from '@/components/shared';
import { useDelayedLoading } from '@/hooks';
import { queryClient, queryKeys } from '@/lib/react-query';
import { Resource } from '@/models';
import { ROUTE_CONSTANTS } from '@/routes';
import { formatResourceName, showGender, showMessage } from '@/utils';
import { useLanguages } from '@/web-api/languages';
import {
  useAddLanguageToResource,
  useDeleteResource,
  useReactivateResource,
  useRemoveLanguageFromResource,
  useResource,
  useUpdateResource,
} from '@/web-api/resources';

import { ResourceStats } from '.';
const { Text } = Typography;

export const ResourceDetailPage = () => {
  const [isEditingMode, setEditingMode] = useState(false);
  const { t } = useTranslation();
  const { resourceId } = useParams();
  if (resourceId === undefined) {
    throw new Error('Expected resourceId to be defined');
  }
  const { data, isLoading } = useResource(resourceId);
  const showDelayedLoading = useDelayedLoading(isLoading);
  const languagesQuery = useLanguages();
  const deleteResourceMutation = useDeleteResource();
  const reactivateResourceMutation = useReactivateResource();
  const updateResourceMutation = useUpdateResource();
  const addLanguageToResource = useAddLanguageToResource();
  const removeLanguageFromResource = useRemoveLanguageFromResource();

  const navigate = useNavigate();

  const [form] = Form.useForm<Resource>();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const onSave = () => form.submit();
  const onCancel = () => {
    form.resetFields();
    setEditingMode(!isEditingMode);
  };

  function onReactivate() {
    if (resourceId === undefined) {
      throw new Error('Expected resourceId to be defined');
    }
    reactivateResourceMutation.mutate(resourceId, {
      onSuccess: () => {
        showMessage('success', t('common:reactivated'));
      },
    });
  }

  function onDelete() {
    if (resourceId === undefined) {
      throw new Error('Expected resourceId to be defined');
    }
    deleteResourceMutation.mutate(resourceId, {
      onSuccess: () => {
        showMessage('success', t('forms:deleted'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.resources.all });
        navigate(`/admin/${ROUTE_CONSTANTS.RESOURCES_PATH}`, { replace: true });
      },
    });
  }

  const onFormFinish = (resource: Resource) => {
    updateResourceMutation.mutate(resource, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.resources.detail(resource.id) });
        setEditingMode(false);
      },
      onError: (error) => {
        console.error('Error updating resource', error);
      },
    });
  };

  const onSelectSubmit = (serviceIds: string[]) => {
    addLanguageToResource.mutate(
      { resourceId, languageIds: serviceIds },
      {
        onSuccess: () => {
          showMessage('success', t('forms:saved'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.resources.detail(resourceId) });
          setEditingMode(false);
        },
      }
    );
  };

  const onRemoveService = (serviceId: string) => {
    removeLanguageFromResource.mutate(
      { resourceId, languageId: serviceId },
      {
        onSuccess: () => {
          showMessage('success', t('forms:saved'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.resources.detail(resourceId) });
          setEditingMode(false);
        },
      }
    );
  };

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xl={24}>
        <Card
          title={
            <Space direction='horizontal'>
              {formatResourceName(data?.firstname ?? '', data?.lastname ?? '', 'non-formal')}
              {showGender(data?.gender)}
              {data?.isArchived ? (
                <Tag color='red'>{t('common:archived')}</Tag>
              ) : (
                <Tag color='success'>{t('common:active')}</Tag>
              )}
            </Space>
          }
          extra={
            <Space>
              <DeleteButton
                onDelete={onDelete}
                onReactivate={onReactivate}
                isArchived={data?.isArchived}
              />
              <EditSaveButton
                form={form}
                onEdit={() => setEditingMode(true)}
                isEditing={isEditingMode}
                onSave={onSave}
                onCancel={onCancel}
                isDisabled={false}
              />
            </Space>
          }
          loading={showDelayedLoading}
        >
          <Text copyable={{ tooltips: false, text: data?.number.toString() }}>
            {t('common:resourceNumber')}: {data?.number}
          </Text>
          <ResourceForm
            form={form}
            resource={data}
            onFinish={onFormFinish}
            isEditingMode={isEditingMode}
            mode='update'
          />
        </Card>
      </Col>
      <Col xs={24} xl={12}>
        <Card title={t('common:languages')}>
          <LanguageList
            languages={data?.languages}
            onRemove={onRemoveService}
            isLoading={isLoading}
            footer={
              <LanguageListFooter
                languages={
                  data?.languages?.length
                    ? languagesQuery?.data?.filter(
                        (language) =>
                          !data?.languages
                            ?.map((resourceLanguages) => resourceLanguages.id)
                            .includes(language.id)
                      )
                    : languagesQuery.data
                }
                onSubmit={onSelectSubmit}
              />
            }
          />
        </Card>
      </Col>
      <Col xs={24} xl={12}>
        <ResourceStats resourceId={resourceId} />
      </Col>
    </Row>
  );
};
