import { IdcardOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Modal } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryClient, queryKeys } from '@/lib/react-query';
import { CustomerRequest } from '@/models';
import { showMessage } from '@/utils';
import { useCreateCustomer } from '@/web-api/customers';

import { CustomerForm } from './CustomerForm';

export const CreateCustomerModal = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm<CustomerRequest>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRageMode, setIsRageMode] = useState(false);
  const createCustomerMutation = useCreateCustomer();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onOk = () => form.submit();
  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFormFinish = (customer: CustomerRequest) => {
    createCustomerMutation.mutate(customer, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.customers.all });
        form.resetFields();
        setIsModalOpen(isRageMode);
      },
    });
  };

  return (
    <Fragment>
      <Button type='primary' icon={<IdcardOutlined />} onClick={() => showModal()}>
        {t('common:newCustomer')}
      </Button>
      <Modal
        title={t('common:newCustomer')}
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        okText={t('forms:save')}
        destroyOnClose={true}
        width={'75%'}
      >
        <CustomerForm form={form} onFinish={onFormFinish} />
        <Checkbox checked={isRageMode} onChange={(e) => setIsRageMode(e.target.checked)}>
          {t('common:createMoreCustomers')}
        </Checkbox>
      </Modal>
    </Fragment>
  );
};
