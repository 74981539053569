import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateCustomerModal, CustomerList } from '@/components/customer';

export const CustomersPage = () => {
  const { t } = useTranslation();

  return (
    <Card title={t('common:customers')} extra={<CreateCustomerModal />}>
      <CustomerList />
    </Card>
  );
};
