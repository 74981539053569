import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Resource } from '@/models/resource.model';
import { CreateUpdateResourceRequest, getSMPBackendAPIAdmin } from '@/proxy';
import { DEFAULT_DATE_FORMAT, eurosToCents } from '@/utils';

const toRequest = (resource: Resource): CreateUpdateResourceRequest => ({
  email: resource.email,
  gender: resource.gender,
  title: resource.title,
  firstname: resource.firstname,
  lastname: resource.lastname,
  rate: eurosToCents(resource.rate),
  phone: resource.phone,
  notes: resource.notes,
  serviceBookingTypes: resource.bookingTypes,
  street: resource.street,
  zip: resource.zip,
  city: resource.city,
  country: resource.country,
  taxId: resource.taxId,
  taxNumber: resource.taxNumber,
  originCountry: resource.originCountry,
  placeOfBirth: resource.placeOfBirth,
  dateOfBirth: resource.dateOfBirth
    ? dayjs.utc(resource.dateOfBirth, DEFAULT_DATE_FORMAT).toISOString()
    : undefined,
  contractStartDate: resource.contractStartDate
    ? dayjs.utc(resource.contractStartDate, DEFAULT_DATE_FORMAT).toISOString()
    : undefined,
  contractEndDate: resource.contractEndDate
    ? dayjs.utc(resource.contractEndDate, DEFAULT_DATE_FORMAT).toISOString()
    : undefined,
  gdprConsent: resource.gdprConsent,
  imageConsent: resource.imageConsent,
  ndaConsent: resource.ndaConsent,
  cvAvailable: resource.cvAvailable,
  certificateAvailable: resource.certificateAvailable,
  imageAvailable: resource.imageAvailable,
  nameTagReceived: resource.nameTagReceived,
  employmentType: resource.employmentType,
  employer: resource.employer,
  criminalRecordDate: resource.criminalRecordDate
    ? dayjs.utc(resource.criminalRecordDate, DEFAULT_DATE_FORMAT).toISOString()
    : undefined,
  specialization: resource.specialization ?? [],
  educationLevel: resource.educationLevel,
  germanLanguageLevel: resource.germanLanguageLevel,
});

function createResource(resource: Resource) {
  const data = toRequest(resource);
  return getSMPBackendAPIAdmin().resourcesControllerCreate(data);
}

export function useCreateResource() {
  return useMutation({ mutationFn: createResource });
}
