import { Button, List, Space, Tag } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Language } from '@/models';
import { Resource } from '@/models/resource.model';
import { ROUTE_CONSTANTS } from '@/routes';
import { formatResourceName, showGender } from '@/utils/resourceHelper';

type ResourceListProps = {
  header?: ReactNode;
  footer?: ReactNode;
  resources?: Resource[];
  isLoading?: boolean;
  onRemove?: (id: string) => void;
};

export const ResourceList = ({
  header,
  footer,
  resources,
  isLoading,
  onRemove,
}: ResourceListProps) => {
  const { t } = useTranslation();

  return (
    <List
      size='small'
      header={header}
      footer={footer}
      itemLayout='horizontal'
      dataSource={resources}
      loading={isLoading}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Link key={item.id} to={`/admin/${ROUTE_CONSTANTS.RESOURCES_PATH}/${item.id}`}>
              {t('common:show')}
            </Link>,
            onRemove ? (
              <Button
                type='link'
                danger
                key={`remove-${item.id}`}
                onClick={() => onRemove(item.id)}
              >
                {t('common:remove')}
              </Button>
            ) : null,
          ]}
        >
          <List.Item.Meta
            title={
              <Space direction='horizontal' size='small' split='-'>
                {`${formatResourceName(item.firstname, item.lastname, 'formal')}`}
                {item.number}
                {item.email}
                {showGender(item.gender)}
                {item.isArchived && <Tag color='red'>{t('common:archived')}</Tag>}
              </Space>
            }
            description={<ResourceListItemContent languages={item.languages ?? []} />}
          />
        </List.Item>
      )}
    />
  );
};

const ResourceListItemContent = ({ languages }: { languages: Language[] }) => {
  return (
    <>
      {languages.map((language: Language) => (
        <Tag key={language.id}>{language.name}</Tag>
      ))}
    </>
  );
};
