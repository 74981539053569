import { useMutation } from '@tanstack/react-query';

import { DateTimeSurcharge } from '@/models';
import { CreateUpdateDateTimeSurchargeDto, getSMPBackendAPIAdmin } from '@/proxy';
import { percentToDecimal } from '@/utils';

function toRequest({
  name,
  recurrenceType,
  interval,
  percentage,
  startTime,
  endTime,
  resourceShare,
}: DateTimeSurcharge): CreateUpdateDateTimeSurchargeDto {
  return {
    name,
    recurrenceType,
    interval,
    percentage: percentToDecimal(percentage),
    startTime: startTime.format(),
    endTime: endTime.format(),
    resourceShare: percentToDecimal(resourceShare),
  };
}

function updateTimeSurcharge(dateTimeData: DateTimeSurcharge) {
  const data = toRequest(dateTimeData);
  return getSMPBackendAPIAdmin().settingsControllerUpdateDateTimeSurcharge(dateTimeData.id, data);
}

export function useUpdateTimeSurcharge() {
  return useMutation({ mutationFn: updateTimeSurcharge });
}
