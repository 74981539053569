import { Button, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { WebLayout } from '@/apps/web';

export function Welcome() {
  const { t } = useTranslation();
  return (
    <WebLayout isLoading={false}>
      <Flex justify={'space-between'} vertical align={'center'} gap={'middle'}>
        <Typography.Title level={3}>{t('common:welcomeMessage')}</Typography.Title>
        <Link to='/web/request'>
          <Button type={'primary'} size={'large'}>
            {t('common:newRequest')}
          </Button>
        </Link>
        <Flex justify={'space-between'} align={'center'} gap={'small'}>
          <Link to='/app/mybookings'>{t('common:resourceLogin')}</Link>
          <Link to='/admin/bookings'>{t('common:adminLogin')}</Link>
        </Flex>
      </Flex>
    </WebLayout>
  );
}
