import dayjs from 'dayjs';

import { Billable, Invoice, ResourceBillable } from '@/models';
import { ChargeableServiceBookingsDto, InvoiceDto, ResourceBillableDto } from '@/proxy';
import { DATETIME_WITHOUT_DAY_FORMAT, centsToEuros } from '@/utils';

import { toSurcharge } from '.';

export function toBillable(dto: ChargeableServiceBookingsDto): Billable {
  const mappedBillable = dto.chargeable.map((item) => ({
    customer: {
      id: item.customer.id,
      company: item.customer.name,
      number: item.customer.number,
    },
    customerBookings: item.serviceBookings.map((booking) => ({
      id: booking.id,
      reference: booking.reference,
      status: booking.status,
      type: booking.type,
      date: dayjs(booking.date),
      specialRate: booking.specialRate,
      contactPerson: booking.contactPerson,
    })),
  }));
  return { billable: mappedBillable };
}

export function toInvoice(dto: InvoiceDto): Invoice {
  return {
    id: dto.id,
    number: dto.number,
    status: dto.status,
    created: dayjs(dto.created),
    updated: dayjs(dto.updated),
    customer: {
      id: dto.customer.id,
      company: dto.customer.name,
      number: dto.customer.number,
    },
  };
}

export function toResourceBillable(dto: ResourceBillableDto): ResourceBillable {
  return {
    year: dto.year,
    month: dto.month,
    billableBookings: dto.billableBookings.map((item) => ({
      id: item.id,
      reference: item.reference,
      date: dayjs(item.date).format(DATETIME_WITHOUT_DAY_FORMAT),
      customer: item.customer,
      resourceCharges: item.resourceCharges.map(toSurcharge),
      status: item.status,
      total: centsToEuros(item.total),
      type: item.type,
    })),
    cancellationFees: dto.cancellationFees.map((item) => ({
      name: item.name,
      description: item.description,
      bookingDate: dayjs(item.bookingDate).format(DATETIME_WITHOUT_DAY_FORMAT),
      value: item.value,
    })),
    billableTotal: centsToEuros(dto.billableTotal),
  };
}
