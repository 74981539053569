import dayjs from 'dayjs';

import { EducationLevel, EmploymentType, LanguageLevel, Resource } from '@/models';
import {
  ResourceDto,
  ResourceDtoEducationLevel,
  ResourceDtoEmploymentType,
  ResourceDtoGermanLanguageLevel,
} from '@/proxy';
import { toBookingTypeEnum } from '@/services/booking.service';
import { DEFAULT_DATE_FORMAT, centsToEuros } from '@/utils';

import { toLanguage } from './language.service';

export const toResource = (data: ResourceDto): Resource => ({
  id: data.id,
  number: data.number,
  gender: data.gender,
  title: data.title,
  firstname: data.firstname,
  lastname: data.lastname,
  rate: centsToEuros(data.rate),
  email: data.email,
  phone: data.phone,
  notes: data.notes,
  isArchived: data.isArchived,
  bookingTypes: data.serviceBookingTypes ? data.serviceBookingTypes.map(toBookingTypeEnum) : [],
  languages: data.languages ? data?.languages.map(toLanguage) : [],
  dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth).format(DEFAULT_DATE_FORMAT) : undefined,
  placeOfBirth: data.placeOfBirth,
  originCountry: data.originCountry,
  street: data.street,
  zip: data.zip,
  city: data.city,
  country: data.country,
  taxId: data.taxId,
  taxNumber: data.taxNumber,
  contractStartDate: data.contractStartDate
    ? dayjs(data.contractStartDate).format(DEFAULT_DATE_FORMAT)
    : undefined,
  contractEndDate: data.contractEndDate
    ? dayjs(data.contractEndDate).format(DEFAULT_DATE_FORMAT)
    : undefined,
  employmentType: toEmploymentTypeEnum(data.employmentType),
  employer: data.employer,
  criminalRecordDate: data.criminalRecordDate
    ? dayjs(data.criminalRecordDate).format(DEFAULT_DATE_FORMAT)
    : undefined,
  cvAvailable: data.cvAvailable,
  certificateAvailable: data.certificateAvailable,
  imageAvailable: data.imageAvailable,
  gdprConsent: data.gdprConsent,
  imageConsent: data.imageConsent,
  ndaConsent: data.ndaConsent,
  nameTagReceived: data.nameTagReceived,
  educationLevel: toEducationLevelEnum(data.educationLevel),
  germanLanguageLevel: toLanguageLevelEnum(data.germanLanguageLevel),
  specialization: data.specialization,
});

export const toEmploymentTypeEnum = (
  data: ResourceDtoEmploymentType | undefined
): EmploymentType | undefined => {
  switch (data) {
    case ResourceDtoEmploymentType.MAIN:
      return EmploymentType.MAIN;
    case ResourceDtoEmploymentType.SECONDARY:
      return EmploymentType.SECONDARY;
    default:
      return undefined;
  }
};

export const toEducationLevelEnum = (
  data: ResourceDtoEducationLevel | undefined
): EducationLevel | undefined => {
  switch (data) {
    case ResourceDtoEducationLevel.NONE:
      return EducationLevel.NONE;
    case ResourceDtoEducationLevel.APPRENTICESHIP:
      return EducationLevel.APPRENTICESHIP;
    case ResourceDtoEducationLevel.BACHELOR:
      return EducationLevel.BACHELOR;
    case ResourceDtoEducationLevel.MASTER:
      return EducationLevel.MASTER;
    case ResourceDtoEducationLevel.PHD:
      return EducationLevel.PHD;
    default:
      return undefined;
  }
};

export const toLanguageLevelEnum = (
  data: ResourceDtoGermanLanguageLevel | undefined
): LanguageLevel | undefined => {
  switch (data) {
    case ResourceDtoGermanLanguageLevel.A1:
      return LanguageLevel.A1;
    case ResourceDtoGermanLanguageLevel.A2:
      return LanguageLevel.A2;
    case ResourceDtoGermanLanguageLevel.B1:
      return LanguageLevel.B1;
    case ResourceDtoGermanLanguageLevel.B2:
      return LanguageLevel.B2;
    case ResourceDtoGermanLanguageLevel.C1:
      return LanguageLevel.C1;
    case ResourceDtoGermanLanguageLevel.C2:
      return LanguageLevel.C2;
    default:
      return undefined;
  }
};
