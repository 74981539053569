import { Card } from 'antd';
import { CardTabListType } from 'antd/es/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OpeningHours, Rates } from '@/components/settings';
import { useDelayedLoading } from '@/hooks';
import { useSettings } from '@/web-api/settings';

export function SettingsPage() {
  const [activeTabKey, setActiveTabKey] = useState<string>('rates');
  const { t } = useTranslation();

  const settingsQuery = useSettings();
  const showDelayedLoading = useDelayedLoading(settingsQuery.isLoading);

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const tabList: CardTabListType[] = [
    { key: 'rates', tab: t('forms:rate') },
    { key: 'openingTimes', tab: t('common:openingTimes') },
  ];

  const contentList: Record<string, React.ReactNode> = {
    rates: settingsQuery.data && <Rates setting={settingsQuery.data} />,
    openingTimes: <OpeningHours setting={settingsQuery.data?.openingHours} />,
  };

  return (
    <Card
      title={t('common:settings')}
      loading={showDelayedLoading}
      tabList={tabList}
      onTabChange={onTabChange}
      tabProps={{ size: 'small' }}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}
