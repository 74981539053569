import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function deleteExpressSurcharge(expressSurchargeId: string) {
  return getSMPBackendAPIAdmin().settingsControllerDeleteExpressSurcharge(expressSurchargeId);
}

export function useDeleteExpressSurcharge() {
  return useMutation({ mutationFn: deleteExpressSurcharge });
}
