import { useLocalStorageState, useMount } from 'ahooks';
import { App, Button, Descriptions, DescriptionsProps, Form, Space, Typography } from 'antd';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { WebBookingForm } from '@/components/booking';
import { useDelayedLoading } from '@/hooks';
import { useAutoFillFormOnCtrlD } from '@/hooks/useAutoFillForm';
import { Client } from '@/models';
import { WebBooking } from '@/models/web.booking.model';
import { useWebCreateBookingReqeust } from '@/web-api/public';

import { WebLayout } from '.';

const { Title, Paragraph, Link } = Typography;

export function WebRequestForm() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const [localFormData, setLocalFormData] =
    useLocalStorageState<Pick<WebBooking, 'customerNumber' | 'contactPerson' | 'onsite'>>(
      'webBookingForm'
    );

  useMount(() => {
    if (localFormData) {
      const items: DescriptionsProps['items'] = [
        {
          key: 'customerNumber',
          label: t('forms:customerNumber'),
          children: localFormData.customerNumber,
        },
        {
          key: 'contactInfoName',
          label: t('forms:name'),
          children: localFormData.contactPerson?.name,
        },
        {
          key: 'contactInfoEmail',
          label: t('forms:email'),
          children: localFormData.contactPerson?.email,
        },
        {
          key: 'contactInfoPhone',
          label: t('forms:phone'),
          children: localFormData.contactPerson?.phone,
        },
        ...(localFormData.onsite
          ? [
              {
                key: 'onsiteLocation',
                label: t('forms:location'),
                children: (
                  <Space direction='vertical'>
                    {localFormData.onsite?.location?.name}
                    {localFormData.onsite?.location?.street}
                    {localFormData.onsite?.location?.supplement}
                    <span>
                      {localFormData.onsite?.location?.zip}&nbsp;
                      {localFormData.onsite?.location?.city}
                    </span>
                  </Space>
                ),
              },
            ]
          : []),
      ];

      void modal.confirm({
        centered: true,
        title: t('common:bookingProcessingFormAutoFillTitle'),
        okText: t('common:useAutoFill'),
        onOk: () => form.setFieldsValue(localFormData),
        content: (
          <Fragment>
            <Paragraph>{t('common:bookingProcessingFormAutoFillDescription')}</Paragraph>
            <Descriptions column={1} bordered size='small' items={items} />
          </Fragment>
        ),
        width: '60%',
      });
    }
  });

  useAutoFillFormOnCtrlD(form);

  const createBookingRequestMutation = useWebCreateBookingReqeust();
  const showDelayedLoading = useDelayedLoading(createBookingRequestMutation.isPending);

  function onFinish(values: WebBooking & { client?: Client; rememberMe?: boolean }) {
    createBookingRequestMutation.mutate(values, {
      onSuccess: () => {
        form.resetFields();
        if (values.rememberMe) {
          setLocalFormData({
            customerNumber: values.customerNumber,
            contactPerson: values.contactPerson,
            onsite: values.onsite,
          });
        } else {
          setLocalFormData(undefined);
        }
        void modal.success({
          title: t('forms:bookingProcessingFormSuccessTitle'),
          content: t('forms:bookingProcessingFormSuccessContent'),
        });
      },
    });
  }

  return (
    <WebLayout isLoading={showDelayedLoading}>
      <Fragment>
        <Title level={2}>{t('forms:bookingProcessingFormTitle')}</Title>
        <Paragraph>
          {t('forms:bookingProcessingFormDescription')}:
          <Link href='https://smp-berlin.de/konditionen' target='_blank' rel='noreferrer'>
            &nbsp;{t('common:termsOfUse')}
          </Link>
        </Paragraph>
        <Paragraph>{t('forms:bookingProcessingFormIntro')}:</Paragraph>
        <WebBookingForm form={form} onFinish={onFinish} />
        <Button
          type='primary'
          onClick={form.submit}
          disabled={createBookingRequestMutation.isPending}
        >
          {t('forms:sendRequest')}
        </Button>
      </Fragment>
    </WebLayout>
  );
}
