import { useMutation } from '@tanstack/react-query';

import { TravelSurcharge } from '@/models';
import { CreateUpdateTravelSurchargeDto, getSMPBackendAPIAdmin } from '@/proxy';
import { eurosToCents } from '@/utils';

function toRequest(travel: TravelSurcharge): CreateUpdateTravelSurchargeDto {
  return {
    distance: travel.distance,
    price: eurosToCents(travel.price),
    resourceShare: eurosToCents(travel.resourceShare),
  };
}

function updateTravelSurcharge(travel: TravelSurcharge) {
  const data = toRequest(travel);
  return getSMPBackendAPIAdmin().settingsControllerUpdateTravelSurcharge(travel.id, data);
}

export function useUpdateTravelSurcharge() {
  return useMutation({ mutationFn: updateTravelSurcharge });
}
