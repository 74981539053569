import { useQueryClient } from '@tanstack/react-query';
import { Card, Divider, Form, Select, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditSaveButton } from '@/components/shared';
import { UserForm } from '@/components/user';
import { queryKeys } from '@/lib/react-query';
import { User } from '@/models';
import { showMessage } from '@/utils';
import { useCurrentUser, useUpdateCurrentUser } from '@/web-api/users';

export const ProfilePage = () => {
  const [isEditingMode, setEditingMode] = useState(false);
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();
  const updateCurrentUserMutation = useUpdateCurrentUser();

  const [form] = Form.useForm<User>();

  const onSave = () => form.submit();
  const onCancel = () => {
    form.resetFields();
    setEditingMode(!isEditingMode);
  };

  const onFinish = (user: User) => {
    updateCurrentUserMutation.mutate(user, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.currentUser.all });
        setEditingMode(false);
      },
    });
  };

  function handleLanguageChange(value: string) {
    setLanguage(value);
    void i18n.changeLanguage(value);
  }

  return currentUser ? (
    <Card
      title={`${t('header:yourProfile')} (${currentUser.email})`}
      extra={
        <EditSaveButton
          form={form}
          isEditing={isEditingMode}
          onCancel={onCancel}
          onSave={onSave}
          onEdit={() => setEditingMode(true)}
          isDisabled={false}
        />
      }
    >
      <UserForm
        form={form}
        isEditingMode={isEditingMode}
        onFinish={onFinish}
        user={currentUser}
        isProfile
      />
      <Divider orientation='left'>{t('common:language')}</Divider>
      <Select popupMatchSelectWidth value={language} onChange={handleLanguageChange}>
        <Select.Option value='de-DE'>Deutsch</Select.Option>
        <Select.Option value='en-GB'>English</Select.Option>
      </Select>
    </Card>
  ) : (
    <Spin />
  );
};
