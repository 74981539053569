import { Dayjs } from 'dayjs';

import { BookingStatus } from '@/models';

export const MONTH_YEAR_FORMAT = 'MMMM YYYY';
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATETIME_WITH_DAY_FORMAT = 'dddd, DD.MM.YYYY [um] HH:mm [Uhr]';
export const DATETIME_WITHOUT_DAY_FORMAT = 'DD.MM.YYYY [um] HH:mm [Uhr]';
export const TIME_FORMAT = 'HH:mm [Uhr]';
export const TIME_WITH_PIPE_FORMAT = 'HH:mm [|] ';
export const DATE_IN_REQEUST_FORMAT = 'YYYY-MM-DD';

export const getStatusColor = (status: BookingStatus) => {
  switch (status) {
    case BookingStatus.OPEN:
      return 'orange';
    case BookingStatus.PUBLISHED:
      return 'blue';
    case BookingStatus.CONFIRMED:
      return 'purple';
    case BookingStatus.CLOSED:
      return 'green';
    case BookingStatus.VERIFIED:
      return 'cyan';
    case BookingStatus.CANCELED:
      return 'red';
    case BookingStatus.REQUESTED:
      return 'magenta';
    case BookingStatus.DECLINED:
      return '#d4380d';
  }
};

export const dateWithTimeAndDay = (date?: Dayjs) =>
  date ? date.format(DATETIME_WITH_DAY_FORMAT) : '';

export const dateWithTime = (date?: Dayjs) =>
  date ? date.format(DATETIME_WITHOUT_DAY_FORMAT) : '';

export const dateWithOnlyTime = (date?: Dayjs) => (date ? date.format(TIME_FORMAT) : '');
