import { useMutation } from '@tanstack/react-query';

import { User } from '@/models';
import { CreateUpdateUserRequest, getSMPBackendAPIAdmin } from '@/proxy';

const toRequest = (user: User): CreateUpdateUserRequest => ({
  firstname: user.firstname,
  lastname: user.lastname,
  email: user.email,
});

function updateCurrentUser(user: User) {
  return getSMPBackendAPIAdmin().usersControllerUpdateMe(toRequest(user));
}

export function useUpdateCurrentUser() {
  return useMutation({ mutationFn: updateCurrentUser });
}
