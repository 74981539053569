/* eslint-disable @typescript-eslint/no-floating-promises */
import { useQueryClient } from '@tanstack/react-query';
import { Col, Form, InputNumber, Row, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditSaveButton } from '@/components/shared';
import { queryKeys } from '@/lib/react-query';
import { BookingType, LanguageRate } from '@/models';
import { showMessage } from '@/utils';
import { useUpdateRatesSettings } from '@/web-api/settings';

const { Paragraph } = Typography;

export type FormBookingTypeValues = {
  [BookingType.ONSITE]: number;
  [BookingType.REMOTE]: number;
  [BookingType.PHONE]: number;
  [BookingType.DOCUMENT]: number;
};

type LanguageRatesProps = {
  rates: LanguageRate[];
};

export function BookingTypeRates({ rates }: LanguageRatesProps) {
  const [isEditingMode, setEditingMode] = useState(false);
  const [form] = Form.useForm<FormBookingTypeValues>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const updateRatesMutation = useUpdateRatesSettings();

  function onSave(values: FormBookingTypeValues) {
    updateRatesMutation.mutate(values, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
        setEditingMode(false);
        showMessage('success', t('forms:saved'));
      },
    });
  }

  function onCancel() {
    setEditingMode(false);
    form.resetFields();
  }

  const order = ['ONSITE', 'REMOTE', 'PHONE', 'DOCUMENT'];

  const initialValues = rates
    .sort((a, b) => {
      return order.indexOf(a.languageBookingType) - order.indexOf(b.languageBookingType);
    })
    .reduce((acc, rate) => {
      acc[rate.languageBookingType] = rate.rate;
      return acc;
    }, {} as FormBookingTypeValues);

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xl={18}>
        <Paragraph>{t('common:bookingTypeRatesParagraph')}</Paragraph>
      </Col>
      <Col xs={24} xl={6}>
        <EditSaveButton
          form={form}
          isEditing={isEditingMode}
          onEdit={() => setEditingMode(true)}
          onSave={form.submit}
          onCancel={onCancel}
          isDisabled={false}
        />
      </Col>
      <Col span={24}>
        <Form
          name='bookingTypesForm'
          form={form}
          layout='vertical'
          initialValues={initialValues}
          onFinish={onSave}
        >
          <Row gutter={[12, 12]}>
            {rates.map((rate) => (
              <Col key={rate.id} xs={24} md={6}>
                <Form.Item
                  label={t(rate.languageBookingType)}
                  name={rate.languageBookingType}
                  rules={[{ required: true }]}
                >
                  <InputNumber<number>
                    disabled={!isEditingMode}
                    precision={2}
                    controls={false}
                    addonAfter='€'
                    formatter={(value) => (value ? `${value}` : '')}
                    onChange={(value) =>
                      value && form.setFieldsValue({ [rate.languageBookingType]: value })
                    }
                    decimalSeparator=','
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
