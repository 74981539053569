import dayjs from 'dayjs';

import { BookingCancelation, BookingVerification } from '@/models';
import { SurchargeDtoType } from '@/proxy';
import { ServiceBookingCancellationDto, ServiceBookingVerificationDto } from '@/proxy-web';
import { centsToEuros, decimalToPercent } from '@/utils';

export function toCancelBooking(dto: ServiceBookingCancellationDto): BookingCancelation {
  return {
    id: dto.id,
    date: dayjs(dto.date),
    customer: dto.customer,
    language: dto.language,
    type: dto.type,
    status: dto.status,
    bookingCharges: dto.bookingCharges,
    cancelationCharges: dto.cancellationCharges.map(toCancellationCharge),
  };
}

function toCancellationCharge(
  dto: ServiceBookingCancellationDto['cancellationCharges'][0]
): BookingCancelation['cancelationCharges'][0] {
  return {
    name: dto.name,
    type: dto.type,
    value:
      dto.type === SurchargeDtoType.PERCENTAGE
        ? decimalToPercent(dto.value)
        : centsToEuros(dto.value),
    description: dto.description,
    duration: dto.duration,
  };
}

export function toVerifyBooking(dto: ServiceBookingVerificationDto): BookingVerification {
  return {
    id: dto.id,
    reference: dto.reference,
    date: dayjs(dto.date),
    customer: dto.customer,
    language: dto.language,
    resource: dto.resource,
    status: dto.status,
    type: dto.type,
    clientReference: dto.clientReference,
    verification: dto.verification,
  };
}
