import { Divider, Empty, Flex, List, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingGroup, BookingStatus } from '@/models';
import { getStatusColor, renderBooking } from '@/utils';

const { Text } = Typography;

type BookingListProps = {
  bookingData?: BookingGroup[];
};

export function BookingList({ bookingData }: BookingListProps) {
  const { t } = useTranslation();

  return (
    <Flex vertical gap='large'>
      <Flex wrap gap='small' align='center'>
        <Text>{t('common:status')}:</Text>
        {Object.values(BookingStatus).map((value) => (
          <Tag key={value} color={getStatusColor(value)}>
            {t(value)}
          </Tag>
        ))}
      </Flex>
      {bookingData && bookingData.length === 0 && <Empty description={t('noBookingsThisMonth')} />}
      {bookingData &&
        bookingData.map(({ date, bookings }) => (
          <Fragment key={date}>
            <Divider orientation='left'>{dayjs(date).format('dddd, DD.MM.YYYY')}</Divider>
            <List size='small' dataSource={bookings} renderItem={renderBooking} />
          </Fragment>
        ))}
    </Flex>
  );
}
