import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserList } from '@/components/user';
import { useDelayedLoading } from '@/hooks';
import { useUsers } from '@/web-api/users';

import { CreateUserModal } from '.';

export const UsersPage = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useUsers();
  const showDelayedLoading = useDelayedLoading(isLoading);

  return (
    <Card title={t('common:users')} extra={<CreateUserModal />} loading={showDelayedLoading}>
      <UserList users={data} />
    </Card>
  );
};
