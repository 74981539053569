import { InvoiceStatus } from '@/models';

export function stopPropagation(e: React.MouseEvent) {
  e.stopPropagation();
}

export function getInvoiceStatusColor(status: InvoiceStatus) {
  switch (status) {
    case InvoiceStatus.DRAFT:
      return 'gray';
    case InvoiceStatus.OPEN:
      return 'blue';
    case InvoiceStatus.PAID:
      return 'green';
    case InvoiceStatus.VOIDED:
      return 'red';
  }
}

export function logger(message: string, level = 'info') {
  const env = process.env.NODE_ENV;

  if (env === 'development') {
    switch (level) {
      case 'info':
        console.info(message);
        break;
      case 'warn':
        console.warn(message);
        break;
      case 'error':
        console.error(message);
        break;
      default:
        console.log(message);
        break;
    }
  }
}
