import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function deleteResource(languageId: string) {
  return getSMPBackendAPIAdmin().resourcesControllerDelete(languageId);
}

export function useDeleteResource() {
  return useMutation({ mutationFn: deleteResource });
}
