import { useRoutes } from 'react-router-dom';

import { AppProvider } from './providers';
import { appRoutes } from './routes';

export function App() {
  const element = useRoutes(appRoutes);

  return <AppProvider>{element}</AppProvider>;
}
