import { Select } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer } from '@/models';
import { useCustomers } from '@/web-api/customers';

type SelectCustomerBillingProps = {
  customerState: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
};

export function SelectCustomerBilling({ customerState }: SelectCustomerBillingProps) {
  const [selectedCustomer, setSelectedCustomer] = customerState;
  const { t } = useTranslation();

  const customersQuery = useCustomers();

  function onCustomerChange(customerId: Customer['id']) {
    if (customerId) {
      setSelectedCustomer(customerId);
    } else {
      setSelectedCustomer(undefined);
    }
  }

  return (
    <Select
      value={selectedCustomer}
      showSearch
      placeholder={t('common:customer')}
      style={{ width: '25%' }}
      loading={customersQuery.isLoading}
      onChange={onCustomerChange}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={customersQuery.data?.map((customer) => ({
        label: `${customer.company} (${customer.number})`,
        value: customer.id,
      }))}
      allowClear
    />
  );
}
