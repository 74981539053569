import { useQuery } from '@tanstack/react-query';

import { defaultStaleTime, queryKeys } from '@/lib/react-query';
import { BookingType, Gender } from '@/models';
import { ResourcesControllerFindAllParams, getSMPBackendAPIAdmin } from '@/proxy';
import { toResource } from '@/services/resource.service';

export type GetResourcesParams = {
  includeLanguages?: string[];
  excludeCustomers?: string[];
  serviceBookingTypes?: BookingType[];
  gender?: Gender;
};

function toRequestParams(params?: GetResourcesParams): ResourcesControllerFindAllParams {
  return {
    includeLanguages: params?.includeLanguages?.filter((lang) => lang !== ''),
    excludeCustomers: params?.excludeCustomers,
    serviceBookingTypes: params?.serviceBookingTypes,
    gender: params?.gender ? params.gender.toString() : undefined,
  };
}

function getResources(params?: GetResourcesParams) {
  const requestParams = toRequestParams(params);
  return getSMPBackendAPIAdmin().resourcesControllerFindAll(requestParams);
}

export function useResources(params?: GetResourcesParams) {
  return useQuery({
    queryKey: queryKeys.resources.lists(params),
    queryFn: () => getResources(params),
    select: (data) => data.map(toResource),
    staleTime: defaultStaleTime,
  });
}
