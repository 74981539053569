import { useMutation } from '@tanstack/react-query';
import { UploadFile } from 'antd';

import { UpsertVerificationRequest, getSMPBackendAPIAdmin } from '@/proxy';

type UpdateBookingVerification = {
  id: string;
  updateVerificationRequest: Omit<UpsertVerificationRequest, 'file'> & { file?: UploadFile[] };
};

function updateBookingVerification({ id, updateVerificationRequest }: UpdateBookingVerification) {
  return getSMPBackendAPIAdmin().serviceBookingsControllerUpdateVerification(id, {
    issuer: updateVerificationRequest.issuer,
    value: updateVerificationRequest.value,
    file: updateVerificationRequest.file && updateVerificationRequest.file[0]?.originFileObj,
    startTime: updateVerificationRequest.startTime,
    autoVerify: updateVerificationRequest.autoVerify,
  });
}

export function useUpdateBookingVerification() {
  return useMutation({ mutationFn: updateBookingVerification });
}
