import {
  BankOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CodeOutlined,
  ContactsOutlined,
  FlagOutlined,
  IdcardOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout, Menu } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ROUTE_CONSTANTS } from '@/routes';
import { checkRoutePath, isUserAdmin } from '@/utils';

import packageJson from '../../../package.json';

const { Sider: AntSider } = Layout;

export function Sider() {
  const [currentRoute, setCurrentRoute] = useState('');

  const { i18n, t } = useTranslation('common');
  const { user } = useAuth0();
  const location = useLocation();

  const isAdmin = isUserAdmin(user);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setCurrentRoute(checkRoutePath(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const menuItems = useMemo(
    () => [
      {
        key: 'bookings',
        icon: <CalendarOutlined />,
        label: <Link to={ROUTE_CONSTANTS.BOOKINGS_PATH}>{t('bookings')}</Link>,
      },
      {
        key: 'languages',
        icon: <FlagOutlined />,
        label: <Link to={ROUTE_CONSTANTS.LANGUAGES_PATH}>{t('languages')}</Link>,
      },
      {
        key: 'resources',
        icon: <ContactsOutlined />,
        label: <Link to={ROUTE_CONSTANTS.RESOURCES_PATH}>{t('resources')}</Link>,
      },
      {
        key: 'customers',
        icon: <IdcardOutlined />,
        label: <Link to={ROUTE_CONSTANTS.CUSTOMERS_PATH}>{t('customers')}</Link>,
      },
      ...(isAdmin
        ? [
            {
              key: 'dashboard',
              icon: <BarChartOutlined />,
              label: <Link to={ROUTE_CONSTANTS.DASHBOARD_PATH}>{t('dashboard')}</Link>,
            },
            {
              key: 'users',
              icon: <UserOutlined />,
              label: <Link to={ROUTE_CONSTANTS.USERS_PATH}>{t('users')}</Link>,
            },
            {
              key: 'billing',
              icon: <BankOutlined />,
              label: <Link to={ROUTE_CONSTANTS.BILLING_PATH}>{t('billing')}</Link>,
            },
            {
              key: 'settings',
              icon: <SettingOutlined />,
              label: <Link to={ROUTE_CONSTANTS.SETTINGS_PATH}>{t('settings')}</Link>,
            },
          ]
        : []),
      {
        key: 'version',
        icon: <CodeOutlined />,
        label: `Version ${packageJson.version}`,
        disabled: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdmin, i18n.language]
  );

  return (
    <AntSider breakpoint='lg' collapsedWidth={0} theme='light'>
      <div style={{ height: 32, margin: 16 }}>
        <Link to='/admin' replace>
          <img src='/smpLogo180.png' alt='SMP Logo' height={32} width={32} />
        </Link>
      </div>
      <Menu theme='light' mode='inline' selectedKeys={[currentRoute]} items={menuItems} />
    </AntSider>
  );
}
