import { FlagOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectMultipleLanguages } from '@/components/language';
import { Language } from '@/models';

type LanguageListFooterProps = {
  languages: Language[] | undefined;
  onSubmit: (resourceIds: string[]) => void;
};

export const LanguageListFooter = ({ languages, onSubmit }: LanguageListFooterProps) => {
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const { t } = useTranslation();

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} md={16}>
        <SelectMultipleLanguages
          selectedLanguages={selectedLanguages}
          languages={languages}
          onChange={(value) => setSelectedLanguages(value)}
        />
      </Col>
      <Col xs={24} md={8}>
        <Button
          aria-label='Sprache hinzufügen'
          type={'primary'}
          icon={<FlagOutlined />}
          onClick={() => {
            onSubmit(selectedLanguages);
            setSelectedLanguages([]);
          }}
          disabled={selectedLanguages.length === 0}
        >
          {t('common:add')}
        </Button>
      </Col>
    </Row>
  );
};
