import { useMutation } from '@tanstack/react-query';

import { User } from '@/models';
import { CreateUpdateUserRequest, getSMPBackendAPIAdmin } from '@/proxy';

const toRequest = (user: User): CreateUpdateUserRequest => ({
  firstname: user.firstname,
  lastname: user.lastname,
  email: user.email,
});

function updateUser(user: User) {
  return getSMPBackendAPIAdmin().usersControllerUpdate(user.id, toRequest(user));
}

export function useUpdateUser() {
  return useMutation({ mutationFn: updateUser });
}
