import { Alert, Checkbox, Collapse, CollapseProps, Space, Tag, Typography } from 'antd';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Billable } from '@/models';
import { ROUTE_CONSTANTS } from '@/routes';
import { DATETIME_WITH_DAY_FORMAT, centsToEuros, getStatusColor, stopPropagation } from '@/utils';

interface CustomerBookingsListProps {
  chargeable: Billable['billable'];
  customerState: [
    Record<string, boolean>,
    React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
  ];
  bookingState: [
    Record<string, boolean>,
    React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
  ];
}

export function CustomerBillablesList({
  chargeable,
  customerState,
  bookingState,
}: CustomerBookingsListProps) {
  const [selectedCustomers, setSelectedCustomers] = customerState;
  const [selectedBookings, setSelectedBookings] = bookingState;

  const { t } = useTranslation();

  const getCustomerCheckState = (customerId: string) => {
    const chargeableItem = chargeable.find((item) => item.customer.id === customerId);
    if (!chargeableItem) return { checked: false, indeterminate: false };
    const bookingIds = chargeableItem.customerBookings.map((booking) => booking.id);
    const selectedBookingIds = bookingIds.filter((id) => selectedBookings[id]);
    return {
      checked: selectedBookingIds.length === bookingIds.length && bookingIds.length > 0,
      indeterminate: selectedBookingIds.length > 0 && selectedBookingIds.length < bookingIds.length,
    };
  };

  const handleCustomerCheck = (customerId: string, isChecked: boolean) => {
    setSelectedCustomers((prevState) => ({ ...prevState, [customerId]: isChecked }));
    if (isChecked) {
      const newSelectedBookings: Record<string, boolean> = {};
      chargeable.forEach((chargeableItem) => {
        if (chargeableItem.customer.id === customerId) {
          chargeableItem.customerBookings.forEach((booking) => {
            newSelectedBookings[booking.id] = true;
          });
        }
      });
      setSelectedBookings((prevState) => ({ ...prevState, ...newSelectedBookings }));
    } else {
      const newSelectedBookings = { ...selectedBookings };
      chargeable.forEach((chargeableItem) => {
        if (chargeableItem.customer.id === customerId) {
          chargeableItem.customerBookings.forEach((booking) => {
            delete newSelectedBookings[booking.id];
          });
        }
      });
      setSelectedBookings(newSelectedBookings);
    }
  };

  const handleBookingCheck = (bookingId: string, isChecked: boolean) => {
    setSelectedBookings((prevState) => {
      if (isChecked) {
        return { ...prevState, [bookingId]: true };
      } else {
        const newState = { ...prevState };
        delete newState[bookingId];
        return newState;
      }
    });
  };

  // Collapse items by customer
  const items: CollapseProps['items'] = chargeable.map((chargeableItem, index) => {
    const { checked, indeterminate } = getCustomerCheckState(chargeableItem.customer.id);

    return {
      key: index,
      label: (
        <Space>
          <Checkbox
            checked={checked}
            onChange={(e) => handleCustomerCheck(chargeableItem.customer.id, e.target.checked)}
            onClick={stopPropagation}
            indeterminate={indeterminate}
          />
          {chargeableItem.customer.company} ({chargeableItem.customer.number})
        </Space>
      ),
      children: (
        <Space direction='vertical'>
          {chargeableItem.customerBookings.map((booking) => (
            <Space key={booking.id} style={{ marginLeft: 24 }} align={'start'}>
              <Checkbox
                checked={selectedBookings[booking.id] || false}
                onChange={(e) => handleBookingCheck(booking.id, e.target.checked)}
              />
              <Link to={`/admin/${ROUTE_CONSTANTS.BOOKINGS_PATH}/${booking.id}`} target='_blank'>
                {booking.reference}
              </Link>
              <Typography.Text type={'secondary'}>
                {booking.date.format(DATETIME_WITH_DAY_FORMAT)} ({t(`common:${booking.type}`)})
              </Typography.Text>
              <Tag color={getStatusColor(booking.status)}>{t(`common:${booking.status}`)}</Tag>
              {booking.specialRate && (
                <Tag color='red-inverse'>
                  {t('forms:specialPrice')}: {centsToEuros(booking.specialRate)}€
                </Tag>
              )}
            </Space>
          ))}
        </Space>
      ),
    };
  });

  return (
    <Fragment>
      {chargeable.length === 0 && <Alert showIcon message={t('common:noBillable')} />}
      <Collapse items={items} expandIconPosition='end' />
    </Fragment>
  );
}
