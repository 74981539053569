/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Admin)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */

export type SurchargeDtoType = (typeof SurchargeDtoType)[keyof typeof SurchargeDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SurchargeDtoType = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
  HOURLY: 'HOURLY',
} as const;
