import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

type RemoveLanguageFromResourceRequest = {
  resourceId: string;
  languageId: string;
};

function removeLanguageFromResource(request: RemoveLanguageFromResourceRequest) {
  return getSMPBackendAPIAdmin().resourcesControllerRemoveLanguage(
    request.resourceId,
    request.languageId
  );
}

export function useRemoveLanguageFromResource() {
  return useMutation({ mutationFn: removeLanguageFromResource });
}
