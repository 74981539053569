import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function deleteTravelSurcharge(travelId: string) {
  return getSMPBackendAPIAdmin().settingsControllerDeleteTravelSurcharge(travelId);
}

export function useDeleteTravelSurcharge() {
  return useMutation({ mutationFn: deleteTravelSurcharge });
}
