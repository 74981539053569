import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { DateTimeSurcharge, DateTimeSurchargeRecurrenceType } from '@/models';
import { DATETIME_FORMAT, logger, showMessage } from '@/utils';
import {
  useCreateTimeSurcharge,
  useDeleteTimeSurcharge,
  useUpdateTimeSurcharge,
} from '@/web-api/settings';

const { RangePicker } = DatePicker;

type WithoutTime = Omit<DateTimeSurcharge, 'startTime' | 'endTime'>;
type DateTimeSurchargeWithArrayTime = WithoutTime & {
  time: Dayjs[];
};

type TimeSurchargesProps = {
  timeSurcharges: DateTimeSurcharge[];
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: keyof DateTimeSurcharge;
  record: DateTimeSurcharge;
  index: number;
  children: React.ReactNode;
}

export function TimeSurcharges({ timeSurcharges }: TimeSurchargesProps) {
  const [editingId, setEditingId] = useState('');
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [rowForm] = Form.useForm();
  const queryClient = useQueryClient();

  const createExpressSurchargeMutation = useCreateTimeSurcharge();
  const deleteExpressSurchargeMutation = useDeleteTimeSurcharge();
  const updateExpressSurchargeMutation = useUpdateTimeSurcharge();

  const isEditing = (record: DateTimeSurcharge) => record.id === editingId;

  const edit = (record: DateTimeSurcharge) => {
    rowForm.setFieldValue('name', record.name);
    rowForm.setFieldValue('percentage', record.percentage);
    rowForm.setFieldValue('resourceShare', record.resourceShare);
    rowForm.setFieldValue('startTime', dayjs(record.startTime));
    rowForm.setFieldValue('endTime', dayjs(record.endTime));
    rowForm.setFieldValue('recurrenceType', record.recurrenceType);
    rowForm.setFieldValue('interval', record.interval);
    record.id && setEditingId(record.id);
  };

  const cancel = () => {
    setEditingId('');
  };

  const save = async () => {
    try {
      const row = (await rowForm.validateFields()) as Omit<
        DateTimeSurcharge,
        'startTime' | 'endTime'
      > & {
        startTime: Dayjs;
        endTime: Dayjs;
      };
      updateExpressSurchargeMutation.mutate(
        {
          id: editingId,
          name: row.name,
          percentage: row.percentage,
          startTime: row.startTime,
          endTime: row.endTime,
          recurrenceType: row.recurrenceType ?? null,
          interval: row.interval ?? null,
          resourceShare: row.resourceShare,
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
            setEditingId('');
            showMessage('success', t('forms:saved'));
          },
        }
      );
    } catch (errInfo) {
      logger(`Validate Failed: ${String(errInfo)}`, 'error');
    }
  };

  function onFinish(values: DateTimeSurchargeWithArrayTime) {
    const submitValues = {
      ...values,
      startTime: values.time[0],
      endTime: values.time[1],
    };
    createExpressSurchargeMutation.mutate(submitValues, {
      onSuccess: () => {
        form.resetFields();
        void queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
        showMessage('success', t('forms:saved'));
      },
    });
  }

  function onDelete(expressSurchargeId: string) {
    deleteExpressSurchargeMutation.mutate(expressSurchargeId, {
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: queryKeys.settings.all });
        showMessage('success', t('forms:deleted'));
      },
    });
  }

  const columns = [
    {
      title: t('forms:name'),
      dataIndex: 'name',
      editable: true,
    },
    {
      title: t('common:surcharge'),
      dataIndex: 'percentage',
      editable: true,
      render: (value: DateTimeSurcharge['percentage']) => `${value}%`,
    },
    {
      title: t('common:surchargeResourceShare'),
      dataIndex: 'resourceShare',
      editable: true,
      render: (value: DateTimeSurcharge['resourceShare']) => `${value}%`,
    },
    {
      title: t('forms:startTime'),
      dataIndex: 'startTime',
      editable: true,
      render: (value: DateTimeSurcharge['startTime']) => value.format(DATETIME_FORMAT),
    },
    {
      title: t('forms:endTime'),
      dataIndex: 'endTime',
      editable: true,
      render: (value: DateTimeSurcharge['endTime']) => value.format(DATETIME_FORMAT),
    },
    {
      title: t('forms:recurrenceType'),
      dataIndex: 'recurrenceType',
      editable: true,
      render: (data: DateTimeSurchargeRecurrenceType) => (data ? t(`forms:${data}`) : ''),
    },
    {
      title: t('forms:interval'),
      dataIndex: 'interval',
      editable: true,
    },
    {
      title: t('actions'),
      width: '25%',
      render: (_: any, record: DateTimeSurcharge) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <Space size='small'>
                <Button type='primary' size='small' onClick={save}>
                  {t('forms:save')}
                </Button>
                <Button size='small' onClick={cancel}>
                  {t('forms:cancel')}
                </Button>
              </Space>
            ) : (
              <Button
                type='text'
                size='small'
                disabled={editingId !== ''}
                onClick={() => edit(record)}
              >
                {t('forms:edit')}
              </Button>
            )}
            <Popconfirm
              title={t('forms:deleteEntry')}
              onConfirm={() => onDelete(record.id)}
              okButtonProps={{ danger: true }}
              okText={t('forms:delete')}
            >
              <Button danger type='text' size='small' disabled={!!editingId}>
                Löschen
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DateTimeSurcharge) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    children,
    ...restProps
  }) => {
    function renderInputNode() {
      switch (dataIndex) {
        case 'name':
          return <Input />;
        case 'percentage':
          return <InputNumber min={1} precision={2} decimalSeparator=',' />;
        case 'resourceShare':
          return <InputNumber min={1} precision={2} decimalSeparator=',' />;
        case 'startTime':
        case 'endTime':
          return <DatePicker format='DD.MM.YYYY HH:mm' showTime needConfirm={false} />;
        case 'recurrenceType':
          return (
            <Select
              options={Object.entries(DateTimeSurchargeRecurrenceType)
                .filter(([, value]) => value !== null)
                .map(([key, value]) => ({
                  value: key,
                  label: value ? t(`forms:${value}`) : '',
                }))}
              allowClear
            />
          );
        case 'interval':
          return <InputNumber min={1} controls />;
        default:
          return null;
      }
    }

    const isRequired = ['name', 'percentage', 'resourceShare', 'startTime', 'endTime'].includes(
      dataIndex
    );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: isRequired,
              },
              {
                validator(_, value) {
                  if (
                    dataIndex === 'recurrenceType' &&
                    !!rowForm.getFieldValue('interval') &&
                    !value
                  ) {
                    return Promise.reject(new Error(`${t('forms:recurrenceTypeInputMessage')}`));
                  }
                  if (
                    dataIndex === 'interval' &&
                    !!rowForm.getFieldValue('recurrenceType') &&
                    !value
                  ) {
                    return Promise.reject(new Error(`${t('forms:intervalInputMessage')}`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {renderInputNode()}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <Fragment>
      <Form name='tableForm' form={rowForm} component={false}>
        <Table
          rowKey='id'
          size='small'
          bordered
          dataSource={timeSurcharges}
          columns={mergedColumns}
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
      <Divider orientation='left'>{t('add')}</Divider>
      <Form form={form} name='expressSurchargesForm' layout='vertical' onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={8}>
            <Form.Item name='name' label={t('forms:name')} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name='percentage' label={t('common:surcharge')} rules={[{ required: true }]}>
              <InputNumber
                min={1}
                precision={2}
                addonAfter='%'
                decimalSeparator=','
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name='resourceShare'
              label={t('common:surchargeResourceShare')}
              rules={[{ required: true }]}
              extra={t('forms:surchargeResourceShareExtra')}
            >
              <InputNumber
                min={1}
                precision={2}
                addonAfter='%'
                decimalSeparator=','
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col xs={24} md={8}>
            <Form.Item
              name='time'
              label={t('forms:dateRange')}
              rules={[{ type: 'array' as const, required: true }]}
            >
              <RangePicker
                showTime
                format='DD.MM.YYYY HH:mm'
                needConfirm={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              shouldUpdate={(prevValues: DateTimeSurcharge, curValues) =>
                prevValues.interval !== curValues.interval
              }
            >
              {() => {
                return (
                  <Form.Item
                    name='recurrenceType'
                    label={t('forms:recurrenceType')}
                    rules={[
                      {
                        required: !!form.getFieldValue('interval'),
                        message: `${t('forms:recurrenceTypeInputMessage')}`,
                      },
                    ]}
                  >
                    <Select
                      options={Object.entries(DateTimeSurchargeRecurrenceType)
                        .filter(([, value]) => value !== null)
                        .map(([key, value]) => ({
                          value: key,
                          label: value ? t(`forms:${value}`) : '',
                        }))}
                      style={{ width: '100%' }}
                      allowClear
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              shouldUpdate={(prevValues: DateTimeSurcharge, curValues) =>
                prevValues.recurrenceType !== curValues.recurrenceType
              }
            >
              {() => {
                return (
                  <Form.Item
                    name='interval'
                    label={t('forms:interval')}
                    rules={[
                      {
                        required: !!form.getFieldValue('recurrenceType'),
                        message: `${t('forms:intervalInputMessage')}`,
                      },
                    ]}
                  >
                    <InputNumber min={1} controls style={{ width: '100%' }} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button htmlType='submit'>{t('add')}</Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
}
