import { CalendarOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Modal } from 'antd';
import { Dayjs } from 'dayjs';
import { Fragment, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingForm } from '@/components/booking';
import { queryClient, queryKeys } from '@/lib/react-query';
import { Booking, Client } from '@/models';
import { bookingReducer, initialBookingState, showMessage } from '@/utils';
import { useCreateServiceBooking } from '@/web-api/bookings';

type CreateBookingModalProps = {
  selectedDate?: Dayjs;
};

export const CreateBookingModal = ({ selectedDate }: CreateBookingModalProps) => {
  const { t } = useTranslation();
  const [bookingState, bookingDispatch] = useReducer(bookingReducer, initialBookingState);

  const [form] = Form.useForm<Booking & { debtorId: string; client: Client }>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRageMode, setIsRageMode] = useState(false);
  const createBookingMutation = useCreateServiceBooking();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onOk = () => form.submit();
  const onCancel = () => {
    form.resetFields();
    bookingDispatch({ type: 'RESET' });
    setIsModalOpen(false);
  };

  const onFormFinish = (booking: Booking & { client: Client }) => {
    createBookingMutation.mutate(booking, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.all });
        form.resetFields();
        setIsModalOpen(isRageMode);
      },
    });
  };

  return (
    <Fragment>
      <Button
        aria-label='Neue Buchung'
        type='primary'
        icon={<CalendarOutlined />}
        onClick={() => showModal()}
      >
        {t('common:newBooking')}
      </Button>
      <Modal
        title={t('common:newBooking')}
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        okText={t('forms:save')}
        cancelText={t('forms:cancel')}
        destroyOnClose={true}
        styles={{ body: { height: '80vh', overflowY: 'auto', overflowX: 'hidden' } }}
        centered
        maskClosable={false}
        width='50vw'
        confirmLoading={createBookingMutation.isPending}
      >
        <BookingForm
          form={form}
          onFinish={onFormFinish}
          isEditingMode={true}
          defaultDate={selectedDate}
          isCreateModal
          bookingState={[bookingState, bookingDispatch]}
        />
        <Checkbox checked={isRageMode} onChange={(e) => setIsRageMode(e.target.checked)}>
          {t('common:createMoreOrders')}
        </Checkbox>
      </Modal>
    </Fragment>
  );
};
