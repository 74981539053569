import dayjs from 'dayjs';

import {
  DateTimeSurcharge,
  ExpressSurcharge,
  LanguageRate,
  Setting,
  TravelSurcharge,
} from '@/models';
import {
  DateTimeSurchargeDto,
  ExpressSurchargeDto,
  ServiceRateDto,
  SettingsDto,
  TravelSurchargeDto,
} from '@/proxy';
import { centsToEuros, decimalToPercent, metersToKm } from '@/utils';

export function toSetting(data: SettingsDto): Setting {
  return {
    languageRates: data.serviceRates.map(toLanguageRate),
    expressSurcharges: data.expressSurcharges.map(toExpressSurcharges),
    dayDateSurcharges: data.dayDateSurcharges.map(toDateTimeSurcharge),
    travelSurcharges: data.travelSurcharges.map(toTravelSurcharge),
    openingHours: data.openingHours,
  };
}

function toLanguageRate(data: ServiceRateDto): LanguageRate {
  return {
    id: data.id,
    rate: centsToEuros(data.rate),
    languageBookingType: data.serviceBookingType,
  };
}

function toExpressSurcharges(data: ExpressSurchargeDto): ExpressSurcharge {
  return {
    id: data.id,
    percentage: decimalToPercent(data.percentage),
    minutes: data.minutes,
    resourceShare: decimalToPercent(data.resourceShare),
  };
}

function toDateTimeSurcharge(data: DateTimeSurchargeDto): DateTimeSurcharge {
  return {
    id: data.id,
    name: data.name,
    startTime: dayjs(data.startTime),
    endTime: dayjs(data.endTime),
    percentage: decimalToPercent(data.percentage),
    resourceShare: decimalToPercent(data.resourceShare),
    interval: data.interval,
    recurrenceType: data.recurrenceType,
  };
}

function toTravelSurcharge(data: TravelSurchargeDto): TravelSurcharge {
  return {
    id: data.id,
    distance: metersToKm(data.distance),
    price: centsToEuros(data.price),
    resourceShare: centsToEuros(data.resourceShare),
  };
}
