import { Customer, PaginatedCustomers, PaginatedCustomersDto } from '@/models';
import { CustomerDto } from '@/proxy';
import { toResource } from '@/services';

import { toBooking } from './booking.service';

export const toPaginatedCustomers = (data: PaginatedCustomersDto): PaginatedCustomers => {
  return {
    data: data.data.map(toCustomer),
    limit: data.limit,
    page: data.page,
    total: data.total,
  };
};
export const toCustomer = (data: CustomerDto): Customer => {
  return {
    id: data.id,
    lexofficeId: data.lexofficeId,
    company: data.name,
    number: data.number,
    contactFirstname: data.contactFirstname,
    contactLastname: data.contactLastname,
    contactEmail: data.contactEmail,
    contactPhone: data.contactPhone,
    bookings: data.serviceBookings ? data?.serviceBookings.map(toBooking) : [],
    blockedResources: data.blockedResources ? data?.blockedResources.map(toResource) : [],
  };
};
