import { useQueryClient } from '@tanstack/react-query';
import { Alert, App, Button, Space } from 'antd';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { Booking, BookingStatus } from '@/models';
import { isButtonDisabled, showMessage } from '@/utils';
import { usePublishServiceBooking } from '@/web-api/bookings';

type PublishBookingButtonProps = {
  isEditingMode: boolean;
  setEditingMode: (value: SetStateAction<boolean>) => void;
  data: Booking;
};

export function PublishBookingButton({
  isEditingMode,
  setEditingMode,
  data,
}: PublishBookingButtonProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { modal } = App.useApp();

  const publishServiceBookingMutation = usePublishServiceBooking();

  const onPublishBooking = () => {
    if (data) {
      publishServiceBookingMutation.mutate(data.id, {
        onSuccess: () => {
          showMessage('success', t('common:PUBLISHED'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.detail(data.id) });
          setEditingMode(false);
        },
      });
    }
  };

  function showModal() {
    void modal.confirm({
      title: t('common:publish'),
      content: (
        <Space direction='vertical'>
          <p>{t('common:confirmPublishBookingText')}</p>
          <Alert
            showIcon
            type='info'
            message={t('common:confirmPublishBookingInfo')}
            description={
              <ul>
                <li>{t('common:ONSITE')}</li>
                <li>{t('forms:startTime')}</li>
                <li>{t('forms:duration')}</li>
                <li>{t('common:DOCUMENT')}</li>
                <li>{t('forms:wordCount')}</li>
                <li>{t('forms:dueDate')}</li>
              </ul>
            }
          />
        </Space>
      ),
      onOk: onPublishBooking,
      okText: t('common:confirm'),
      width: '50%',
    });
  }

  const isDisabled = isEditingMode || isButtonDisabled(data?.status, [BookingStatus.OPEN]);

  return (
    <Button aria-label='Buchung veröffentlichen' onClick={showModal} disabled={isDisabled}>
      {t('common:publish')}
    </Button>
  );
}
