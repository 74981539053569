import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Popover, Space, type FormInstance } from 'antd';
import { SetStateAction } from 'react';

import { Booking, Client } from '@/models';

import { TransformBookingButton } from './TransformBookingButton';

import { CancelBookingButton, PublishBookingButton } from '.';

type BookingActionsProps = {
  isEditingMode: boolean;
  setEditingMode: (value: SetStateAction<boolean>) => void;
  data: Booking;
  form: FormInstance<Booking & { debtorId: string; client: Client }>;
};

export function BookingActions({ data, form, isEditingMode, setEditingMode }: BookingActionsProps) {
  return (
    <Popover
      trigger={['click']}
      placement='bottom'
      content={
        <Space direction='vertical'>
          <CancelBookingButton
            data={data}
            isEditingMode={isEditingMode}
            setEditingMode={setEditingMode}
            form={form}
          />
          <PublishBookingButton
            isEditingMode={isEditingMode}
            setEditingMode={setEditingMode}
            data={data}
          />
          <TransformBookingButton bookingId={data?.id} status={data?.status} />
        </Space>
      }
    >
      <Button aria-label='Mehr Aktionen' icon={<EllipsisOutlined />} />
    </Popover>
  );
}
