import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { Booking } from '@/models';
import { ErrorDto, getSMPBackendAPIAdmin, ServiceBookingDto } from '@/proxy';
import { toBooking } from '@/services';

function getBooking(id: string) {
  return getSMPBackendAPIAdmin().serviceBookingsControllerFindOne(id);
}

export function useBooking(id: string) {
  return useQuery<ServiceBookingDto, ErrorDto, Booking>({
    queryKey: queryKeys.bookings.detail(id),
    queryFn: () => getBooking(id),
    select: (data) => toBooking(data),
  });
}
