import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function publishServiceBooking(id: string) {
  return getSMPBackendAPIAdmin().serviceBookingsControllerPublish(id);
}

export function usePublishServiceBooking() {
  return useMutation({ mutationFn: publishServiceBooking });
}
