import { useEffect, useState } from 'react';

export function useDelayedLoading(isLoading: boolean, delay = 1000): boolean {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;

    if (isLoading) {
      timerId = setTimeout(() => setShowLoading(true), delay);
    } else {
      clearTimeout(timerId);
      setShowLoading(false);
    }

    return () => clearTimeout(timerId);
  }, [isLoading, delay]);

  return showLoading;
}
