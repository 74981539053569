import { Col, Row, Space, Typography } from 'antd';

const { Paragraph, Text, Link } = Typography;

export function Footer() {
  return (
    <Col span={24} style={{ backgroundColor: '#faf0e5', padding: 24 }}>
      <Row align='top' gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Space
            direction='vertical'
            style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src='/smpLogo180.png' alt='SMP Logo' />
            <Space split='|'>
              <Link href='https://www.smp-berlin.de/impressum' target='_blank' rel='noreferrer'>
                Impressum
              </Link>
              <Link href='https://www.smp-berlin.de/datenschutz' target='_blank' rel='noreferrer'>
                Datenschutz
              </Link>
            </Space>
          </Space>
        </Col>
        <Col xs={24} md={8}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <Paragraph>
              <Text strong>SMP Sprachmittlerpool Berlin GmbH</Text>
              <br />
              Internationales Sprach- und Kulturmittlerzentrum
            </Paragraph>
            <Paragraph>
              Wilhelmstraße 23
              <br />
              10963 Berlin
            </Paragraph>
            <Paragraph>
              Mail: buchung@smp-berlin.de
              <br /> Tel.: +49 (0)30 521 07 87 00
              <br /> Mobil: +49 (0)162 947 35 49
            </Paragraph>
          </Space>
        </Col>
        <Col xs={24} md={8}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <Paragraph>
              <Text strong>Amtsgericht Charlottenburg</Text>
              <br />
              HRB 196679 B
              <br />
              Steuernummer: 37/556/50643
              <br />
              USt.-ID: DE318608615
            </Paragraph>
            <Paragraph>
              <Text strong>Bankverbindung</Text>
              <br />
              Deutsche Bank
              <br />
              IBAN: DE30 1007 01 24 0224 29 07 00
              <br />
              BIC: DEUTDEDB101
            </Paragraph>
          </Space>
        </Col>
      </Row>
    </Col>
  );
}
