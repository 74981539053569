import { useMutation } from '@tanstack/react-query';

import { CreatInvoiceRequest, getSMPBackendAPIAdmin } from '@/proxy';

function postInvoiceIds(payload: CreatInvoiceRequest) {
  return getSMPBackendAPIAdmin().invoiceControllerCreateInvoice(payload);
}

export function useCreateInvoice() {
  return useMutation({ mutationFn: postInvoiceIds });
}
