import { useMutation } from '@tanstack/react-query';
import { UploadFile } from 'antd';

import { getSMPBackendAPIAdmin } from '@/proxy';

type CloseParams = {
  bookingId: string;
  value: number;
  file?: UploadFile[];
  startTime?: string;
};

function closeBooking({ bookingId, value, file, startTime }: CloseParams) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerClose(bookingId, {
    value,
    file: file?.[0].originFileObj,
    startTime: startTime,
  });
}

export function useResourceCloseBooking() {
  return useMutation({ mutationFn: closeBooking });
}
