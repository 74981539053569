import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function acceptPublishedBooking(id: string) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerAcceptPublishedServiceBooking(id);
}

export function useAcceptPublishedBooking() {
  return useMutation({ mutationFn: acceptPublishedBooking });
}
