import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Col, List, Pagination, Row, Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { useState } from 'react';

import { renderBooking } from '@/utils';
import { useCustomerBookings } from '@/web-api/customers';

type Props = {
  customerId: string;
};

export function CustomerBookings({ customerId }: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<string | number>('desc');

  const customerBookingsQuery = useCustomerBookings(customerId, {
    limit: pageSize,
    page: currentPage,
    sort: sortOrder as string,
  });

  function handlePageChange(page: number, pageSize?: number) {
    setCurrentPage(page);
    if (pageSize) setPageSize(pageSize);
  }

  function handleSortChange(sortOrder: SegmentedValue) {
    setSortOrder(sortOrder);
  }

  return (
    <Row justify='center' gutter={[12, 12]}>
      <Col>
        <Segmented
          size='large'
          value={sortOrder}
          options={[
            { value: 'asc', label: <SortAscendingOutlined /> },
            { value: 'desc', label: <SortDescendingOutlined /> },
          ]}
          onChange={handleSortChange}
        />
      </Col>
      <Col span={24}>
        <List
          size='small'
          loading={customerBookingsQuery.isLoading}
          dataSource={customerBookingsQuery.data?.data}
          renderItem={renderBooking}
        />
      </Col>
      <Col>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={customerBookingsQuery.data?.total || 0}
          onChange={handlePageChange}
          showSizeChanger
          onShowSizeChange={(current, size) => handlePageChange(current, size)}
        />
      </Col>
    </Row>
  );
}
