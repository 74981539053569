import { CompassOutlined, HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { generateMapUrl } from '@/utils';

const { Text, Link, Title } = Typography;

type ContactBoxProps = {
  isResource: boolean;
};

export function ContactBox({ isResource }: ContactBoxProps) {
  const { i18n, t } = useTranslation();
  return (
    <Col style={{ padding: 24 }} xs={24} md={6}>
      <Row justify={'start'}>
        <Select
          value={i18n.language}
          style={{ width: '100%' }}
          onChange={(event) => i18n.changeLanguage(event)}
        >
          <Select.Option value='de-DE'>Deutsch</Select.Option>
          <Select.Option value='en-GB'>English</Select.Option>
        </Select>
        <Divider orientation='center'>{t('common:contact')}</Divider>
        <Title level={3}>SMP Sprachmittlerpool Berlin GmbH</Title>
        <Text>Internationales Sprach- und Kulturmittlerzentrum</Text>
        <Space size={0} direction='vertical' style={{ margin: '24px 0', width: '100%' }}>
          <Text>Wilhelmstraße 23</Text>
          <Text>10963 Berlin</Text>
          <Space>
            <CompassOutlined />
            <Link
              href={generateMapUrl({
                street: 'Wilhelmstraße 23',
                zip: '10963',
                city: 'Berlin',
              })}
              target='_blank'
              rel='noreferrer'
            >
              {t('viewOnMap')}
            </Link>
          </Space>
        </Space>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Text>
            <MailOutlined />
            &nbsp;
            {isResource ? (
              <Link href='mailto:sprachmittler@smp-berlin.de'>sprachmittler@smp-berlin.de</Link>
            ) : (
              <Link href='mailto:buchung@smp-berlin.de'>buchung@smp-berlin.de</Link>
            )}
          </Text>
          <Text>
            <HomeOutlined />
            &nbsp;
            <Link href='tel:004930521078700'>+49 (0)30 521 07 87 00</Link>
          </Text>
          <Text>
            <PhoneOutlined rotate={90} />
            &nbsp;
            <Link href='tel:00491629473549'>+49 (0)162 947 35 49</Link>
          </Text>
          {!isResource && (
            <a href='https://app.smp-berlin.de/app/mybookings' target='_blank' rel='noreferrer'>
              Zum Sprachmittler Login
            </a>
          )}
        </Space>
      </Row>
    </Col>
  );
}
