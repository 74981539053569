import { useQuery } from '@tanstack/react-query';

import { defaultStaleTime, queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIPublic } from '@/proxy-web';
import { toLanguage } from '@/services';

function getAvailableLanguages() {
  return getSMPBackendAPIPublic().requestControllerGetAllLanguages();
}

export function useAvailableLanguages() {
  return useQuery({
    queryKey: queryKeys.languages.public(),
    queryFn: () => getAvailableLanguages(),
    select: (data) => data.map(toLanguage),
    staleTime: defaultStaleTime,
  });
}
