/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Public)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */
import { customAxios } from '../lib/axios';

import type {
  LanguageDto,
  ServiceBookingRequest,
  ServiceBookingCancellationDto,
  ServiceBookingVerificationDto,
  ServiceBookingVerificationRequestDto,
} from './web-dtos';

export const getSMPBackendAPIPublic = () => {
  const requestControllerGetAllLanguages = () => {
    return customAxios<LanguageDto[]>({ url: `/api/v1/request/languages`, method: 'get' });
  };

  const requestControllerSendRequest = (serviceBookingRequest: ServiceBookingRequest) => {
    const formData = new FormData();
    if (serviceBookingRequest.file !== undefined) {
      formData.append('file', serviceBookingRequest.file);
    }
    if (serviceBookingRequest.customerNumber !== undefined) {
      formData.append('customerNumber', serviceBookingRequest.customerNumber.toString());
    }
    if (serviceBookingRequest.customer !== undefined) {
      formData.append('customer', JSON.stringify(serviceBookingRequest.customer));
    }
    if (serviceBookingRequest.debtorNumber !== undefined) {
      formData.append('debtorNumber', serviceBookingRequest.debtorNumber.toString());
    }
    if (serviceBookingRequest.resourceNumber !== undefined) {
      formData.append('resourceNumber', serviceBookingRequest.resourceNumber.toString());
    }
    formData.append('date', serviceBookingRequest.date);
    formData.append('languageId', serviceBookingRequest.languageId);
    if (serviceBookingRequest.alternativeLanguageId !== undefined) {
      formData.append('alternativeLanguageId', serviceBookingRequest.alternativeLanguageId);
    }
    formData.append('contactPerson', JSON.stringify(serviceBookingRequest.contactPerson));
    formData.append('type', serviceBookingRequest.type);
    if (serviceBookingRequest.onsite !== undefined) {
      formData.append('onsite', JSON.stringify(serviceBookingRequest.onsite));
    }
    if (serviceBookingRequest.phone !== undefined) {
      formData.append('phone', JSON.stringify(serviceBookingRequest.phone));
    }
    if (serviceBookingRequest.remote !== undefined) {
      formData.append('remote', JSON.stringify(serviceBookingRequest.remote));
    }
    if (serviceBookingRequest.document !== undefined) {
      formData.append('document', JSON.stringify(serviceBookingRequest.document));
    }
    if (serviceBookingRequest.client !== undefined) {
      formData.append('client', JSON.stringify(serviceBookingRequest.client));
    }
    if (serviceBookingRequest.gender !== undefined) {
      formData.append('gender', serviceBookingRequest.gender);
    }
    if (serviceBookingRequest.acceptAlternativeResource !== undefined) {
      formData.append(
        'acceptAlternativeResource',
        serviceBookingRequest.acceptAlternativeResource.toString()
      );
    }
    if (serviceBookingRequest.specialRate !== undefined) {
      formData.append('specialRate', serviceBookingRequest.specialRate.toString());
    }
    if (serviceBookingRequest.notes !== undefined) {
      formData.append('notes', serviceBookingRequest.notes);
    }

    return customAxios<void>({
      url: `/api/v1/request/booking`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };

  const requestControllerCancelRequest = (id: string) => {
    return customAxios<ServiceBookingCancellationDto>({
      url: `/api/v1/request/booking/${id}/cancel`,
      method: 'get',
    });
  };

  const requestControllerConfirmCancelRequest = (id: string) => {
    return customAxios<void>({ url: `/api/v1/request/booking/${id}/cancel`, method: 'post' });
  };

  const requestControllerGetBookingVerification = (id: string) => {
    return customAxios<ServiceBookingVerificationDto>({
      url: `/api/v1/request/booking/${id}/verify`,
      method: 'get',
    });
  };

  const requestControllerVerifyBooking = (
    id: string,
    serviceBookingVerificationRequestDto: ServiceBookingVerificationRequestDto
  ) => {
    return customAxios<void>({
      url: `/api/v1/request/booking/${id}/verify`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: serviceBookingVerificationRequestDto,
    });
  };

  return {
    requestControllerGetAllLanguages,
    requestControllerSendRequest,
    requestControllerCancelRequest,
    requestControllerConfirmCancelRequest,
    requestControllerGetBookingVerification,
    requestControllerVerifyBooking,
  };
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type RequestControllerGetAllLanguagesResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIPublic>['requestControllerGetAllLanguages']>>
>;
export type RequestControllerSendRequestResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIPublic>['requestControllerSendRequest']>>
>;
export type RequestControllerCancelRequestResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIPublic>['requestControllerCancelRequest']>>
>;
export type RequestControllerConfirmCancelRequestResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIPublic>['requestControllerConfirmCancelRequest']>
  >
>;
export type RequestControllerGetBookingVerificationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof getSMPBackendAPIPublic>['requestControllerGetBookingVerification']>
  >
>;
export type RequestControllerVerifyBookingResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getSMPBackendAPIPublic>['requestControllerVerifyBooking']>>
>;
