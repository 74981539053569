import { useQuery } from '@tanstack/react-query';

import { BookingCancelation } from '../../models';
import { ErrorDto } from '../../proxy';
import { getSMPBackendAPIPublic, ServiceBookingCancellationDto } from '../../proxy-web';
import { toCancelBooking } from '../../services';

function getCancelWebBooking(bookingId: string) {
  return getSMPBackendAPIPublic().requestControllerCancelRequest(bookingId);
}

export function useGetCancelWebBooking(bookingId: string) {
  return useQuery<ServiceBookingCancellationDto, ErrorDto, BookingCancelation>({
    queryKey: ['cancel', bookingId],
    queryFn: () => getCancelWebBooking(bookingId),
    enabled: !!bookingId,
    select: (data) => toCancelBooking(data),
  });
}
