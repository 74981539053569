import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

type AddLanguageToResourceRequest = {
  resourceId: string;
  languageIds: string[];
};

function addLanguageToResource(request: AddLanguageToResourceRequest) {
  return getSMPBackendAPIAdmin().resourcesControllerAddLanguage(request.resourceId, {
    languageIds: request.languageIds,
  });
}

export function useAddLanguageToResource() {
  return useMutation({ mutationFn: addLanguageToResource });
}
