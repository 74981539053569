import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toResource } from '@/services/resource.service';

function getResource(id: string) {
  return getSMPBackendAPIAdmin().resourcesControllerFindOne(id);
}

export function useResource(id: string) {
  return useQuery({
    queryKey: queryKeys.resources.detail(id),
    queryFn: () => getResource(id),
    select: (data) => toResource(data),
    enabled: !!id,
  });
}
