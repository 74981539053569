import { FileAddOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Tag,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { Dayjs } from 'dayjs';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { Booking, BookingStatus, BookingType } from '@/models';
import { DATETIME_WITH_DAY_FORMAT, getStatusColor, showMessage } from '@/utils';
import { useUpdateBookingVerification } from '@/web-api/bookings';

type BookingVerifyStatusProps = {
  bookingStatus?: BookingStatus;
  bookingVerification: Booking['verification'];
  bookingType: Booking['type'];
  bookingId: Booking['id'];
  invoice?: Booking['invoice'];
  editButtonAriaLabel?: string;
};

const { Dragger } = Upload;
const { Text } = Typography;

export function BookingVerifyStatus({
  bookingStatus,
  bookingVerification,
  bookingType,
  bookingId,
  invoice,
  editButtonAriaLabel,
}: BookingVerifyStatusProps) {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const watchAutoVerify = Form.useWatch('autoVerify', form);

  const updateVerificationMutation = useUpdateBookingVerification();

  function onFinish(
    values: Booking['verification'] & { range?: [Dayjs, Dayjs] } & { autoVerify: boolean }
  ) {
    const startTime = values?.range?.[0]?.format();
    delete values.range;
    if (values) {
      if (values.issuer === undefined) {
        values.issuer = '';
      }
      updateVerificationMutation.mutate(
        {
          id: bookingId,
          updateVerificationRequest: { ...values, startTime },
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.detail(bookingId) });
            setIsEditing(false);
            form.resetFields();
            showMessage('success', t('forms:saved'));
          },
        }
      );
    }
  }

  function onCancel() {
    setIsEditing(false);
    form.resetFields();
  }

  function renderStatus() {
    if (bookingVerification === undefined) {
      return <Text>{t('common:bookingNotClosedByResource')}</Text>;
    }
    const inHours = Number((bookingVerification?.value / 60).toFixed(2));
    switch (bookingStatus) {
      case BookingStatus.VERIFIED:
      case BookingStatus.CLOSED:
        return (
          <Fragment>
            {bookingStatus === BookingStatus.CLOSED ? t('common:bookingClosedByResource') : null}
            <Descriptions bordered column={1}>
              <Descriptions.Item label={t('forms:issuer')}>
                {bookingVerification?.issuer ?? ''}
              </Descriptions.Item>
              {bookingType === BookingType.DOCUMENT ? (
                <>
                  <Descriptions.Item label={t('common:file')}>
                    <a href={bookingVerification.fileUrl} target='_blank' rel='noreferrer'>
                      {bookingVerification.fileName}
                    </a>
                  </Descriptions.Item>
                  <Descriptions.Item label={t('forms:wordCount')}>
                    {t('wordWithCount', { count: bookingVerification?.value })}
                  </Descriptions.Item>
                </>
              ) : (
                <>
                  <Descriptions.Item label={t('forms:duration')}>
                    {t('minuteWithCount', { count: bookingVerification?.value })} (
                    {t('hourWithCount', {
                      count: inHours,
                    })}
                    )
                  </Descriptions.Item>
                  <Descriptions.Item label={t('forms:startTime')}>
                    {bookingVerification?.startTime?.format(DATETIME_WITH_DAY_FORMAT)}
                  </Descriptions.Item>
                </>
              )}
              {bookingStatus && (
                <Descriptions.Item label={t('common:status')}>
                  <Tag color={getStatusColor(bookingStatus)}>{t(`common:${bookingStatus}`)}</Tag>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Fragment>
        );
      default:
        return <Text>{t('common:bookingNotClosedByResource')}</Text>;
    }
  }

  const isEditingDisabled =
    !(
      bookingStatus === BookingStatus.CLOSED ||
      bookingStatus === BookingStatus.VERIFIED ||
      bookingStatus === BookingStatus.CONFIRMED
    ) || !!invoice;

  const normFile = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return e?.fileList as UploadFile[];
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: () => {
      form.setFieldValue('file', undefined);
    },
    beforeUpload: (file) => {
      form.setFieldValue('file', [file]);
      return false;
    },
  };

  return (
    <Fragment>
      <Divider orientation='left'>{t('forms:bookingVerify')}</Divider>
      {renderStatus()}
      {isEditing && (
        <Form
          form={form}
          name='verificationForm'
          onFinish={onFinish}
          layout='vertical'
          initialValues={{
            ...bookingVerification,
            autoVerify: false,
            range: [
              bookingVerification?.startTime,
              bookingVerification?.startTime?.add(bookingVerification?.value, 'minutes'),
            ],
          }}
          requiredMark={false}
        >
          <Form.Item name='id' hidden />
          <Row align='middle' justify='space-evenly' style={{ marginTop: 24 }}>
            <Form.Item name='autoVerify' valuePropName='checked'>
              <Checkbox>{t('forms:autoVerifyCheckbox')}</Checkbox>
            </Form.Item>
            {watchAutoVerify && (
              <Form.Item
                name='issuer'
                label={t('forms:issuer')}
                rules={[{ required: true }]}
                tooltip={t('Name des bestätigenden Kunden')}
              >
                <Input />
              </Form.Item>
            )}

            {bookingType === BookingType.DOCUMENT ? (
              <Form.Item name='value' label={t('forms:wordCount')} rules={[{ required: true }]}>
                <InputNumber min={1} placeholder={t('forms:wordCount')} style={{ width: '100%' }} />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  name='range'
                  label={t('forms:enterStartEndTime')}
                  rules={[{ required: true }]}
                >
                  <DatePicker.RangePicker
                    showTime
                    format='DD.MM.YYYY HH:mm'
                    needConfirm={false}
                    onChange={(values) => {
                      if (values && values[0] && values[1]) {
                        const startTime = values[0].startOf('minute');
                        const endTime = values[1].startOf('minute');

                        const difference = endTime.diff(startTime, 'minutes');

                        form.setFieldValue('value', difference);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='value'
                  label={t('forms:duration')}
                  tooltip={t('common:minute_other')}
                >
                  <InputNumber min={1} disabled />
                </Form.Item>
              </>
            )}
            {bookingType === BookingType.DOCUMENT && (
              <Form.Item noStyle>
                <Form.Item
                  label={t('forms:dataUploadLabel')}
                  name='file'
                  valuePropName='fileList'
                  rules={[{ required: true }]}
                  getValueFromEvent={normFile}
                >
                  <Dragger {...props}>
                    <p className='ant-upload-drag-icon'>
                      <FileAddOutlined />
                    </p>
                    <p className='ant-upload-text'>{t('forms:uploadFileText')}</p>
                    <p className='ant-upload-hint'>{t('forms:uploadFileHint')}</p>
                  </Dragger>
                </Form.Item>
              </Form.Item>
            )}
          </Row>
        </Form>
      )}
      {isEditing && (
        <Alert
          showIcon
          type='warning'
          message={t(`forms:${watchAutoVerify ? 'autoVerifyTrue' : 'autoVerifyFalse'}`)}
        />
      )}
      <Row justify='end' style={{ margin: '24px 0' }}>
        {isEditing && <Button onClick={onCancel}>{t('forms:cancel')}</Button>}
        {isEditing ? (
          <Button type='primary' onClick={() => form.submit()} style={{ marginLeft: 12 }}>
            {t('forms:save')}
          </Button>
        ) : (
          <Button
            aria-label={editButtonAriaLabel}
            type='primary'
            onClick={() => setIsEditing(true)}
            style={{ marginLeft: 12 }}
            disabled={isEditingDisabled}
          >
            {t('forms:edit')}
          </Button>
        )}
      </Row>
    </Fragment>
  );
}
