import { UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Modal } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserForm } from '@/components/user';
import { queryClient, queryKeys } from '@/lib/react-query';
import { User } from '@/models';
import { showMessage } from '@/utils';
import { useCreateUser } from '@/web-api/users';

export function CreateUserModal() {
  const { t } = useTranslation();

  const [form] = Form.useForm<User>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRageMode, setIsRageMode] = useState(false);
  const createUserMutation = useCreateUser();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onOk = () => form.submit();
  const onCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFormFinish = (user: User) => {
    createUserMutation.mutate(user, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.users.all });
        form.resetFields();
        setIsModalOpen(isRageMode);
      },
    });
  };

  return (
    <Fragment>
      <Button type='primary' icon={<UserOutlined />} onClick={() => showModal()}>
        {t('common:newUser')}
      </Button>
      <Modal
        title={t('common:newUser')}
        open={isModalOpen}
        onOk={onOk}
        onCancel={onCancel}
        okText={t('forms:save')}
        destroyOnClose={true}
      >
        <UserForm form={form} onFinish={onFormFinish} isEditingMode isCreate />
        <Checkbox checked={isRageMode} onChange={(e) => setIsRageMode(e.target.checked)}>
          {t('common:createMoreUsers')}
        </Checkbox>
      </Modal>
    </Fragment>
  );
}
