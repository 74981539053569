import { useAuth0 } from '@auth0/auth0-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { App, ConfigProvider } from 'antd';
import localeDe from 'antd/locale/de_DE';
import localeEnGb from 'antd/locale/en_GB';
import dayjs from 'dayjs';
import { PropsWithChildren, Suspense, createContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ErrorFallback } from '@/components/error';
import { queryClient } from '@/lib/react-query';
import { themeConfig } from '@/styles';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export function AppProvider({ children }: PropsWithChildren) {
  const [locale, setLocale] = useState(localeDe);
  const { user } = useAuth0();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  function onErrorReset() {
    if (user) {
      navigate('./admin', { replace: true });
    } else {
      navigate('./web/request', { replace: true });
    }
  }

  useEffect(() => {
    const language = i18n.language;
    switch (language) {
      case 'de-DE':
        setLocale(localeDe);
        dayjs.locale('de');
        break;
      case 'en-GB':
        setLocale(localeEnGb);
        dayjs.locale('en-gb');
        break;
      default:
        setLocale(localeDe);
        dayjs.locale('de');
        break;
    }
  }, [i18n.language]);

  return (
    <ConfigProvider locale={locale} form={{ requiredMark: 'optional' }} theme={themeConfig}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={onErrorReset}>
        <App>
          <Suspense>
            <QueryClientProvider client={queryClient}>
              {children}
              <ReactQueryDevtools position='bottom' initialIsOpen={false} />
            </QueryClientProvider>
          </Suspense>
        </App>
      </ErrorBoundary>
    </ConfigProvider>
  );
}
