export enum ErrorMessage {
  UNKNOWN = 'UNKNOWN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  PRISMA_UNKNOWN = 'PRISMA_UNKNOWN',
  PRISMA_RECORD_NOT_FOUND = 'PRISMA_RECORD_NOT_FOUND',
  PRISMA_UNIQUE_CONSTRAINT_FAILED = 'PRISMA_UNIQUE_CONSTRAINT_FAILED',
  CUSTOMER_DATA_MISSING = 'CUSTOMER_DATA_MISSING',
  RESOURCE_NOT_AVAILABLE = 'RESOURCE_NOT_AVAILABLE',
  RESOURCE_NOT_MATCHING_CRITERIA = 'RESOURCE_NOT_MATCHING_CRITERIA',
  RESOURCE_BLOCKED_FOR_CUSTOMER = 'RESOURCE_BLOCKED_FOR_CUSTOMER',
  SERVICE_BOOKING_DATE_INVALID = 'SERVICE_BOOKING_DATE_INVALID',
  SERVICE_BOOKING_DATA_INVALID = 'SERVICE_BOOKING_DATA_INVALID',
  SERVICE_BOOKING_NOT_AVAILABLE = 'SERVICE_BOOKING_NOT_AVAILABLE',
  SERVICE_BOOKING_NOT_PUBLISHED = 'SERVICE_BOOKING_NOT_PUBLISHED',
  SERVICE_BOOKING_NOT_CLOSEABLE = 'SERVICE_BOOKING_NOT_CLOSEABLE',
  SERVICE_BOOKING_NOT_CLOSED = 'SERVICE_BOOKING_NOT_CLOSED',
  SERVICE_BOOKING_NOT_CANCELLABLE = 'SERVICE_BOOKING_NOT_CANCELLABLE',
  SERVICE_BOOKING_NOT_PUBLISHABLE = 'SERVICE_BOOKING_NOT_PUBLISHABLE',
  SERVICE_BOOKING_VERIFICATION_NOT_EDITABLE = 'SERVICE_BOOKING_VERIFICATION_NOT_EDITABLE',
  SERVICE_BOOKING_NOT_TRANSFORMABLE = 'SERVICE_BOOKING_NOT_TRANSFORMABLE',
  SERVICE_BOOKING_REQUEST_EXPIRED = 'SERVICE_BOOKING_REQUEST_EXPIRED',
  SERVICE_RATES_NOT_FOUND = 'SERVICE_RATES_NOT_FOUND',
  OBJECT_NOT_DELETABLE = 'OBJECT_NOT_DELETABLE',
  HERE_MAPS_VERIFY_ERROR = 'HERE_MAPS_VERIFY_ERROR',
  HERE_MAPS_ROUTE_ERROR = 'HERE_MAPS_ROUTE_ERROR',
  LEXOFFICE_INVOICE_ERROR = 'LEXOFFICE_INVOICE_ERROR',
  GOOGLE_FILE_UPLOAD_ERROR = 'GOOGLE_FILE_UPLOAD_ERROR',
  AUTH0_UNKNOWN = 'AUTH0_UNKNOWN',
  AUTH0_USER_ALREADY_EXISTS = 'AUTH0_USER_ALREADY_EXISTS',
  EMAIL_VALIDATION_ERROR = 'EMAIL_VALIDATION_ERROR',
}
