import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toResourceBillable } from '@/services';

export type GetBillableBookingsParams = {
  resourceId: string;
  month: number;
  year: number;
};

function getBillableBookings(params: GetBillableBookingsParams) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerGetBillableBookings(params.resourceId, {
    month: params.month,
    year: params.year,
  });
}

export function useBillableBookings(params: GetBillableBookingsParams) {
  return useQuery({
    queryKey: queryKeys.resourceBookings.billable(params),
    queryFn: () => getBillableBookings(params),
    select: (data) => toResourceBillable(data),
    enabled: !!params,
  });
}
