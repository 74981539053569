import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';

function reactivateLanguage(languageId: string) {
  return getSMPBackendAPIAdmin().languagesControllerReactivate(languageId);
}

export function useReactivateLanguage() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reactivateLanguage,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys.languages.all });
    },
  });
}
