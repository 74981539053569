import { useMutation } from '@tanstack/react-query';

import { UpsertOpeningHoursRequest, getSMPBackendAPIAdmin } from '@/proxy';

function createUpdateOpeningHours(openingHours: UpsertOpeningHoursRequest) {
  return getSMPBackendAPIAdmin().settingsControllerUpsertOpeningHours(openingHours);
}

export function useCreateUpdateOpeningHours() {
  return useMutation({ mutationFn: createUpdateOpeningHours });
}
