import { message } from 'antd';

import { ErrorMessage } from '@/models';

export function showMessage(type: 'success' | 'error', content: string) {
  const duration = content === ErrorMessage.EMAIL_VALIDATION_ERROR ? 15 : 5;
  void message.open({ type, content, duration: duration });
}

/***
 * @example onError: (error) => {
 showMessage('error', t(showErrorMessage(error as string)));
 },
 */
export function showErrorMessage(error: string) {
  return error in ErrorMessage ? `errorCodes:${error}` : 'forms:failed';
}
