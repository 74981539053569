/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * SMP Backend API (Admin)
 * The SMP backend API description
 * OpenAPI spec version: 1.0
 */

export type ResourceDtoEducationLevel =
  (typeof ResourceDtoEducationLevel)[keyof typeof ResourceDtoEducationLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResourceDtoEducationLevel = {
  NONE: 'NONE',
  APPRENTICESHIP: 'APPRENTICESHIP',
  BACHELOR: 'BACHELOR',
  MASTER: 'MASTER',
  PHD: 'PHD',
} as const;
