import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { InvoiceControllerGetInvoicesParams, getSMPBackendAPIAdmin } from '@/proxy';
import { toInvoice } from '@/services';

function getInvoices(params: InvoiceControllerGetInvoicesParams) {
  return getSMPBackendAPIAdmin().invoiceControllerGetInvoices(params);
}

export function useInvoices(params: InvoiceControllerGetInvoicesParams) {
  return useQuery({
    queryKey: queryKeys.billing.invoices(params),
    queryFn: () => getInvoices(params),
    select: (data) => data.map(toInvoice),
    enabled: !!params,
  });
}
