import { useQuery } from '@tanstack/react-query';

import { defaultStaleTime, queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toLanguage } from '@/services';

function getLanguages(archived: boolean) {
  return getSMPBackendAPIAdmin().languagesControllerFindAll({ archived });
}

export function useLanguages(archived = false) {
  return useQuery({
    queryKey: queryKeys.languages.lists(archived),
    queryFn: () => getLanguages(archived),
    select: (data) => data.map(toLanguage),
    staleTime: defaultStaleTime,
  });
}
