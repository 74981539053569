import { useMutation } from '@tanstack/react-query';

import { ServiceBookingVerificationRequestDto, getSMPBackendAPIPublic } from '@/proxy-web';

type VerifyBookingRequest = {
  bookingId: string;
  params: ServiceBookingVerificationRequestDto;
};

function verifyWebBooking({ bookingId, params }: VerifyBookingRequest) {
  return getSMPBackendAPIPublic().requestControllerVerifyBooking(bookingId, {
    issuer: params.issuer,
    clientReference: params.clientReference,
  });
}

export function useVerifyWebBooking() {
  return useMutation({ mutationFn: verifyWebBooking });
}
