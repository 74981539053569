import { Area, Line } from '@ant-design/charts';
import { FileExcelOutlined, ReloadOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Card, DatePicker, Divider, Row, Segmented, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDelayedLoading } from '@/hooks';
import { queryKeys } from '@/lib/react-query';
import {
  DATETIME_WITH_DAY_FORMAT,
  DATE_IN_REQEUST_FORMAT,
  DEFAULT_DATE_FORMAT,
  MONTH_YEAR_FORMAT,
} from '@/utils';
import {
  useDashboardBookings,
  useDashboardExport,
  useDashboardRequests,
} from '@/web-api/dashboard';

const { Text } = Typography;

export function DashboardPage() {
  const [areaPreset, setAreaPreset] = useState({
    value: 7,
    filter: [
      dayjs().format(DATE_IN_REQEUST_FORMAT),
      dayjs().subtract(7, 'day').format(DATE_IN_REQEUST_FORMAT),
    ],
  });

  const [lineMonth, setLineMonth] = useState({
    start: dayjs().startOf('month').format(DATE_IN_REQEUST_FORMAT),
    end: dayjs().endOf('month').format(DATE_IN_REQEUST_FORMAT),
  });

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data, isLoading } = useDashboardRequests({
    start: areaPreset.filter[1],
    end: areaPreset.filter[0],
  });
  const delayedLoading = useDelayedLoading(isLoading, 250);
  const dashboardBookingsQuery = useDashboardBookings({
    start: lineMonth.start,
    end: lineMonth.end,
  });
  const dashboardExportMutation = useDashboardExport();

  function handleReloadData() {
    void queryClient.invalidateQueries({ queryKey: queryKeys.dashboard.all });
  }

  const options = [
    { value: 7, label: t('common:dashboardLastCountDays', { count: 7 }) },
    { value: 30, label: t('common:dashboardLastCountDays', { count: 30 }) },
    { value: 3, label: t('common:dashboardLastCountMonths', { count: 3 }) },
    { value: 12, label: t('common:dashboardLastCountMonths', { count: 12 }) },
  ];

  function handleSegmentedChange(value: number) {
    const isMonth = value === 3 || value === 12;
    setAreaPreset({
      value,
      filter: [
        dayjs().format(DATE_IN_REQEUST_FORMAT),
        dayjs()
          .subtract(value, isMonth ? 'month' : 'day')
          .format(DATE_IN_REQEUST_FORMAT),
      ],
    });
  }

  function handleMonthChange(date: dayjs.Dayjs | null) {
    if (date) {
      setLineMonth({
        start: date.startOf('month').format(DATE_IN_REQEUST_FORMAT),
        end: date.endOf('month').format(DATE_IN_REQEUST_FORMAT),
      });
    }
  }

  function handleExport() {
    dashboardExportMutation.mutate(
      {
        start: lineMonth.start,
        end: lineMonth.end,
      },
      {
        onSuccess(data) {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${t('common:bookings')} ${dayjs(lineMonth.start).format(MONTH_YEAR_FORMAT)}.csv`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      }
    );
  }

  const lastUpdatedAt = queryClient.getQueryState(
    queryKeys.dashboard.requests({ start: areaPreset.filter[1], end: areaPreset.filter[0] })
  )?.dataUpdatedAt;

  return (
    <Card
      title={t('common:dashboard')}
      extra={[
        <Space key={'reload'}>
          <Text>
            {t('common:updatedAt')}: {dayjs(lastUpdatedAt).format(DATETIME_WITH_DAY_FORMAT)}
          </Text>
          <Button loading={delayedLoading} icon={<ReloadOutlined />} onClick={handleReloadData}>
            {t('common:reload')}
          </Button>
        </Space>,
      ]}
    >
      <Row justify={'end'}>
        <Segmented<number>
          options={options}
          value={areaPreset.value}
          onChange={handleSegmentedChange}
          disabled={isLoading}
        />
      </Row>
      <Area
        title={t('common:dashboardRequestsTitle')}
        xField='date'
        yField={t('common:bookings')}
        data={data?.data.map((d) => ({
          date: dayjs(d.date).format(DEFAULT_DATE_FORMAT),
          [t('common:bookings')]: d.count,
        }))}
        style={{ fill: 'linear-gradient(-90deg, white 0%, #eab308 100%)' }}
        line={{
          tooltip: false,
          style: {
            stroke: '#eab308',
            strokeWidth: 2,
          },
        }}
        axis={{
          x: {
            labelFormatter: (v: string) => dayjs(v, DEFAULT_DATE_FORMAT).format('DD.MM.'),
          },
        }}
      />
      <Divider />
      <Row justify={'end'}>
        <Space direction='horizontal'>
          <Button type='text' icon={<FileExcelOutlined />} onClick={handleExport}>
            Excel-Export
          </Button>
          <DatePicker
            picker='month'
            value={dayjs(lineMonth.start)}
            onChange={handleMonthChange}
            format={MONTH_YEAR_FORMAT}
            allowClear={false}
            style={{ minWidth: 150 }}
          />
        </Space>
      </Row>
      <Line
        title={t('common:dashboardBookingsTitle')}
        xField='date'
        yField='count'
        colorField='status'
        scale={{
          color: {
            domain: [
              t('OPEN'),
              t('PUBLISHED'),
              t('CONFIRMED'),
              t('CLOSED'),
              t('VERIFIED'),
              t('CANCELED'),
              t('REQUESTED'),
              t('DECLINED'),
            ],
            range: ['orange', 'blue', 'purple', 'green', 'cyan', 'red', 'magenta', '#d4380d'],
          },
        }}
        data={dashboardBookingsQuery.data?.data?.map((d) => ({
          date: dayjs(d.date).format('DD.MM.YYYY'),
          count: d.count,
          status: t(d.status),
        }))}
        animate={{
          enter: {
            delay: 250,
            duration: 1000,
            easing: 'linear',
            type: 'pathIn',
          },
        }}
      />
    </Card>
  );
}
