import { Form, FormInstance, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormLanguage } from '.';

type LanguageFormProps = {
  form: FormInstance<FormLanguage>;
  language?: FormLanguage;
  onFinish: (language: FormLanguage) => void;
  isEditingMode: boolean;
  isCreateModal: boolean;
  isRenameable: boolean;
  isArchived: boolean;
};

export const LanguageForm = ({
  form,
  language,
  onFinish,
  isEditingMode,
  isCreateModal,
  isRenameable,
}: LanguageFormProps) => {
  const { t } = useTranslation('forms');

  return (
    <Form
      form={form}
      initialValues={language}
      layout='vertical'
      disabled={!isEditingMode}
      onFinish={onFinish}
    >
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label={t('name')}
        name='name'
        rules={[{ required: true }]}
        extra={!isRenameable && !isCreateModal ? t('nameNoRename') : null}
      >
        <Input disabled={!isEditingMode || (!isRenameable && !isCreateModal)} />
      </Form.Item>
      <Form.Item
        label={t('rateOnsite')}
        name='onsiteRate'
        extra={
          language?.onsiteRate === null ? t('rateExtraTextHint') : t('rateExtraOverwrittenHint')
        }
      >
        <InputNumber<number>
          disabled={!isEditingMode}
          precision={2}
          controls={false}
          addonAfter='€'
          formatter={(value) => (value ? `${value}` : '')}
          onChange={(value) => value && form.setFieldsValue({ onsiteRate: value })}
          decimalSeparator=','
        />
      </Form.Item>
      <Form.Item
        label={t('rateRemote')}
        name='remoteRate'
        extra={
          language?.remoteRate === null ? t('rateExtraTextHint') : t('rateExtraOverwrittenHint')
        }
      >
        <InputNumber<number>
          disabled={!isEditingMode}
          precision={2}
          controls={false}
          addonAfter='€'
          formatter={(value) => (value ? `${value}` : '')}
          onChange={(value) => value && form.setFieldsValue({ remoteRate: value })}
          decimalSeparator=','
        />
      </Form.Item>
      <Form.Item
        label={t('ratePhone')}
        name='phoneRate'
        extra={
          language?.phoneRate === null ? t('rateExtraTextHint') : t('rateExtraOverwrittenHint')
        }
      >
        <InputNumber<number>
          disabled={!isEditingMode}
          precision={2}
          controls={false}
          addonAfter='€'
          formatter={(value) => (value ? `${value}` : '')}
          onChange={(value) => value && form.setFieldsValue({ phoneRate: value })}
          decimalSeparator=','
        />
      </Form.Item>
      <Form.Item
        label={t('rateDocument')}
        name='documentRate'
        extra={
          language?.documentRate === null ? t('rateExtraTextHint') : t('rateExtraOverwrittenHint')
        }
      >
        <InputNumber<number>
          disabled={!isEditingMode}
          precision={2}
          controls={false}
          addonAfter='€'
          formatter={(value) => (value ? `${value}` : '')}
          onChange={(value) => value && form.setFieldsValue({ documentRate: value })}
          decimalSeparator=','
        />
      </Form.Item>
    </Form>
  );
};
