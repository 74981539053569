import { User } from '@/models/user.model';
import { UserDto } from '@/proxy';

export const toUser = (data: UserDto): User => ({
  id: data.id,
  email: data.email,
  firstname: data.firstname,
  lastname: data.lastname,
  permissions: data.permissions,
});
