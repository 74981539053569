import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIAdmin } from '@/proxy';

function deleteLanguage(languageId: string) {
  return getSMPBackendAPIAdmin().languagesControllerDelete(languageId);
}

export function useDeleteLanguage() {
  return useMutation({ mutationFn: deleteLanguage });
}
