import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Booking, BookingType, Client } from '@/models';
import { UpdateServiceBookingRequest, getSMPBackendAPIAdmin } from '@/proxy';
import { eurosToCents } from '@/utils';

const toRequest = (model: Booking & { client: Client }): UpdateServiceBookingRequest => ({
  date: model.date.toISOString(),
  notes: model.notes,
  customerId: model.customer.id,
  debtorId: model.debtor?.id,
  resourceId: model.resource?.id,
  ignoreAvailability: model.ignoreAvailability,
  languageId: model.language?.id,
  alternativeLanguageId: model.alternativeLanguage?.id,
  contactPerson: model.contactPerson,
  type: model.type,
  specialRate: model.specialRate ? eurosToCents(model.specialRate) : undefined,
  client: model.client && {
    name: model.client?.name,
    birthDate: model.client.birthDate
      ? dayjs.utc(model.client?.birthDate, 'DD.MM.YYYY').toISOString()
      : undefined,
    reference: model.client?.reference,
  },
  gender: model?.gender,
  ...(model.type === BookingType.ONSITE &&
    model.onsite && {
      onsite: {
        alternativeService: model.onsite.alternativeService,
        duration: model.onsite.duration,
        location: model.onsite.location,
      },
    }),
  ...(model.type === BookingType.REMOTE &&
    model.remote && {
      remote: {
        duration: model.remote.duration,
        emails: model.remote.emails,
      },
    }),
  ...(model.type === BookingType.PHONE &&
    model.phone && {
      phone: {
        duration: model.phone.duration,
        clientPhone: model.phone.clientPhone,
        customerPhone: model.phone.customerPhone,
      },
    }),
  ...(model.type === BookingType.DOCUMENT &&
    model.document && {
      file: model.document.file && model.document.file[0]?.originFileObj,
      document: {
        wordCount: model.document.wordCount,
        fileId: model.document.fileId,
      },
    }),
});

function updateServiceBooking(model: Booking & { client: Client }) {
  const modelRequest = toRequest(model);
  return getSMPBackendAPIAdmin().serviceBookingsControllerUpdate(model.id, modelRequest);
}

export function useUpdateBooking() {
  return useMutation({ mutationFn: updateServiceBooking });
}
