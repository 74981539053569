import { CalendarOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Modal,
  Popconfirm,
  Row,
  Space,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { AppResourceChargesDetails } from '@/apps/app/components/detailsContent/AppResourceChargesDetails';
import { queryKeys } from '@/lib/react-query';
import { BookingStatus, BookingType, ResourceBooking } from '@/models';
import { generateICS, isButtonDisabled, isSpecificBookingType, showMessage } from '@/utils';
import {
  useCancelResourceBooking,
  useGetCancelResourceBooking,
  useResourceBooking,
} from '@/web-api/bookings';

import {
  AppDocumentDetailsContent,
  AppOnsiteDetailsContent,
  AppPhoneDetailsContent,
  AppRemoteDetailsContent,
  ResourceStatusHint,
} from '../components/detailsContent';
import { ConfirmDocument, ConfirmTime } from '../components/detailsContent/confirmModal';

const { Text } = Typography;

export function AppBookingDetailPage() {
  const [showConfirm, setShowConfirm] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bookingId } = useParams();
  const navigate = useNavigate();
  if (bookingId === undefined) {
    throw new Error('Expected bookingId to be defined');
  }
  const bookingQuery = useResourceBooking(bookingId);
  const { data: cancelData } = useGetCancelResourceBooking(bookingId);
  const cancelBookingMutation = useCancelResourceBooking();

  const onCancelBooking = (bookingId: string) => {
    if (bookingQuery.data) {
      cancelBookingMutation.mutate(bookingId, {
        onSuccess: () => {
          showMessage('success', t('common:CANCELED'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.resourceBookings.all });
          navigate('/app/jobs');
        },
      });
    }
  };

  const ConfirmButton = () => (
    <Button
      type='primary'
      onClick={() => setShowConfirm(true)}
      disabled={
        isButtonDisabled(bookingQuery?.data?.status as BookingStatus, [BookingStatus.CONFIRMED]) ||
        (dayjs().isBefore(bookingQuery?.data?.date, 'day') &&
          bookingQuery.data?.type !== BookingType.DOCUMENT)
      }
    >
      {t('common:confirm')}
    </Button>
  );

  const CancelButton = (booking: ResourceBooking) => (
    <Popconfirm
      title={
        <Text strong>
          {t('common:cancellationFees')}: {cancelData?.value ?? 0} €
        </Text>
      }
      onConfirm={() => onCancelBooking(booking.id)}
      okText={t('common:cancel')}
      okButtonProps={{ danger: true }}
      disabled={isButtonDisabled(booking.status, [
        BookingStatus.OPEN,
        BookingStatus.PUBLISHED,
        BookingStatus.CONFIRMED,
      ])}
    >
      <Button
        disabled={isButtonDisabled(booking.status, [
          BookingStatus.OPEN,
          BookingStatus.PUBLISHED,
          BookingStatus.CONFIRMED,
        ])}
        danger
      >
        {t('common:cancel')}
      </Button>
    </Popconfirm>
  );

  function renderBooking(job: ResourceBooking) {
    switch (job.type) {
      case 'ONSITE':
        return <AppOnsiteDetailsContent jobBooking={job} isJob={false} />;
      case 'REMOTE':
        return <AppRemoteDetailsContent jobBooking={job} isJob={false} />;
      case 'PHONE':
        return <AppPhoneDetailsContent jobBooking={job} isJob={false} />;
      case 'DOCUMENT':
        return <AppDocumentDetailsContent jobBooking={job} isJob={false} />;
      default:
        return null;
    }
  }

  function renderCancellationArea(booking: ResourceBooking) {
    if (['CONFIRMED', 'PUBLISHED'].includes(booking.status)) {
      return (
        <>
          <Divider orientation='center'>
            <Text type='danger'>{t('common:attention')}</Text>
          </Divider>
          <Alert type='info' showIcon message={t('common:cancellationWarningText')} />
          <Descriptions bordered column={1} size='small'>
            <Descriptions.Item label={t(`${cancelData?.name as string}`)}>
              {cancelData?.value ?? 0} €
            </Descriptions.Item>
          </Descriptions>
          <Space style={{ justifyContent: 'center', width: '100%', marginTop: '1rem' }}>
            <CancelButton {...booking} />
          </Space>
          <Divider />
        </>
      );
    }
  }

  function renderConfirmBooking(job: ResourceBooking) {
    const bookingType = job.type.toLowerCase();

    if (isSpecificBookingType(bookingType)) {
      const specificBooking = job[bookingType];
      if (specificBooking) {
        return <ConfirmTime bookingId={job.id} form={form} />;
      }
    } else if (job.type === BookingType.DOCUMENT) {
      return (
        <ConfirmDocument
          form={form}
          defaultWordCountValue={job.document?.wordCount ?? 0}
          bookingId={job.id}
        />
      );
    }

    return null;
  }

  const handleDownloadICS = (job: ResourceBooking) => {
    const icsContent = generateICS(job);
    const blob = new Blob([icsContent], { type: 'text/calendar' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `smp-termin-${job.reference}.ics`;

    // Add the event listener to handle opening in the default calendar app
    a.addEventListener('click', () => {
      setTimeout(() => {
        window.URL.revokeObjectURL(a.href);
      }, 150);
    });

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (bookingQuery.data === undefined) {
    return null;
  }

  return (
    <Fragment>
      <Modal
        open={showConfirm}
        title={t('common:confirmBooking')}
        centered={true}
        maskClosable
        closeIcon
        closable
        onCancel={() => setShowConfirm(false)}
        okText={t('common:confirm')}
        onOk={form.submit}
      >
        {renderConfirmBooking(bookingQuery.data)}
      </Modal>
      <Card
        title={t('common:bookingDetail')}
        loading={bookingQuery.isLoading}
        extra={<ConfirmButton />}
        actions={[
          <Link key='back' to={'/app/mybookings'}>
            {t('common:backTo')}&nbsp;
            {t('common:bookings')}
          </Link>,
        ]}
        styles={{ body: { overflowY: 'auto', height: '80%' } }}
        style={{ height: '100%' }}
      >
        <ResourceStatusHint
          bookingStatus={bookingQuery.data.status}
          bookingType={bookingQuery.data.type}
        />
        {bookingQuery.data.status === BookingStatus.CONFIRMED && (
          <Row justify='end'>
            <Button
              type='link'
              onClick={() => handleDownloadICS(bookingQuery.data)}
              icon={<CalendarOutlined />}
            >
              {t('common:downloadICS')}
            </Button>
          </Row>
        )}
        {renderBooking(bookingQuery.data)}
        <Divider orientation={'left'}>{t('forms:conditions')}</Divider>
        <AppResourceChargesDetails charges={bookingQuery.data.resourceCharges} />
        {renderCancellationArea(bookingQuery.data)}
      </Card>
    </Fragment>
  );
}
