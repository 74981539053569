import { FileTextOutlined, HomeOutlined, LaptopOutlined, PhoneOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import {
  DocumentBookingItem,
  OnsiteBookingItem,
  PhoneBookingItem,
  RemoteBookingItem,
} from '@/components/booking';
import { Booking, BookingStatus, BookingType, OnsiteBooking } from '@/models';
import { ROUTE_CONSTANTS } from '@/routes';

export const getRange = (start: number, end: number, step: number) => {
  const result = [];
  for (start; start <= end; start += step) {
    result.push(start * 60);
  }
  return result;
};

export function displayAddress({ street, zip, city, supplement }: OnsiteBooking['location']) {
  return `${street} ${supplement ?? ''}, ${zip} ${city} `;
}

export function displayEmptyString(text: string, fallback: string) {
  return text.length > 0 ? text : fallback;
}

export function isSpecificBookingType(type: string): type is 'onsite' | 'remote' | 'phone' {
  return ['onsite', 'remote', 'phone'].includes(type);
}

export function isButtonDisabled(currentsStatus: BookingStatus, allowedStatus: BookingStatus[]) {
  return !allowedStatus.includes(currentsStatus);
}

type DeviceType = 'android' | 'ios' | 'desktop';

function getDeviceType(): DeviceType {
  const userAgent = navigator.userAgent;
  if (/Android/i.test(userAgent)) {
    return 'android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'ios';
  } else {
    return 'desktop';
  }
}

type DeviceLocation = {
  street: string;
  city: string;
  zip: string;
};

export function generateMapUrl(location: DeviceLocation): string {
  const device = getDeviceType();
  const formattedAddress = `${location.street.replace(/ /g, '+')},+${
    location.zip
  }+${location.city.replace(/ /g, '+')}`;

  switch (device) {
    case 'android':
      return `geo:0,0?q=${formattedAddress}`;
    case 'ios':
      return `maps://maps.google.com/maps?daddr=${formattedAddress}&amp;ll=`;
    case 'desktop':
      return `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
  }
}

export function getBookingTypeIcon(type: BookingType) {
  switch (type) {
    case BookingType.ONSITE:
      return HomeOutlined;
    case BookingType.PHONE:
      return PhoneOutlined;
    case BookingType.REMOTE:
      return LaptopOutlined;
    case BookingType.DOCUMENT:
      return FileTextOutlined;
  }
}

export const initialBookingState: BookingState = {
  selectedBookingType: undefined,
  selectedLanguage: undefined,
  selectedAlternativeLanguage: undefined,
  selectedCustomer: undefined,
  selectedDebtor: undefined,
  selectedResource: undefined,
};

export type BookingState = {
  selectedBookingType?: BookingType;
  selectedLanguage: string | undefined;
  selectedAlternativeLanguage: string | undefined;
  selectedCustomer: string | undefined;
  selectedDebtor: string | undefined;
  selectedResource: string | undefined;
};

export type BookingAction =
  | { type: 'SET_BOOKING_TYPE'; payload: BookingType }
  | { type: 'SET_LANGUAGE'; payload: string | undefined }
  | { type: 'SET_ALTERNATIVE_LANGUAGE'; payload: string | undefined }
  | { type: 'SET_CUSTOMER'; payload: string | undefined }
  | { type: 'SET_DEBTOR'; payload: string | undefined }
  | { type: 'SET_RESOURCE'; payload: string | undefined }
  | { type: 'RESET' };

export function bookingReducer(state: BookingState, action: BookingAction): BookingState {
  switch (action.type) {
    case 'SET_BOOKING_TYPE':
      return { ...state, selectedBookingType: action.payload };
    case 'SET_LANGUAGE':
      return { ...state, selectedLanguage: action.payload };
    case 'SET_ALTERNATIVE_LANGUAGE':
      return { ...state, selectedAlternativeLanguage: action.payload };
    case 'SET_CUSTOMER':
      return { ...state, selectedCustomer: action.payload };
    case 'SET_DEBTOR':
      return { ...state, selectedDebtor: action.payload };
    case 'SET_RESOURCE':
      return { ...state, selectedResource: action.payload };
    case 'RESET':
      return initialBookingState;
  }
}

export function disabledDate(current: Dayjs) {
  return current?.isBefore(dayjs(), 'day');
}

export function disabledDateTime(current: Dayjs | null) {
  if (!current) return {};

  const disHrs: number[] = [];
  const disMins: number[] = [];

  const prevHr = dayjs().subtract(1, 'hour').hour();
  for (let i = 0; i <= prevHr; i++) {
    disHrs.push(i);
  }

  const prevMin = dayjs().minute();
  for (let i = 0; i <= prevMin; i++) {
    disMins.push(i);
  }

  return {
    disabledHours: () => {
      if (dayjs().date() === current?.date()) {
        return disHrs;
      } else {
        return [];
      }
    },
    disabledMinutes: () => {
      if (dayjs().hour() == current?.hour() && dayjs().date() == current?.date()) return disMins;
      else return [];
    },
  };
}

export function renderBooking(booking: Booking) {
  const extra = (
    <Link to={`/${ROUTE_CONSTANTS.ADMIN_PATH}/${ROUTE_CONSTANTS.BOOKINGS_PATH}/${booking.id}`}>
      {t('common:show')}
    </Link>
  );
  switch (booking.type) {
    case 'ONSITE':
      return <OnsiteBookingItem booking={booking} extra={extra} />;
    case 'REMOTE':
      return <RemoteBookingItem booking={booking} extra={extra} />;
    case 'PHONE':
      return <PhoneBookingItem booking={booking} extra={extra} />;
    case 'DOCUMENT':
      return <DocumentBookingItem booking={booking} extra={extra} />;
    default:
      return null;
  }
}
