/* eslint-disable @typescript-eslint/no-misused-promises */
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import { JiraHelpButtons } from '@/components/shared';

import { Header } from './Header';
import { Sider } from './Sider';

const { Content } = Layout;

export function MainLayout() {
  return (
    <Layout style={{ height: '100vh', flexDirection: 'row' }}>
      <Sider />
      <Layout>
        <Header />
        <Content
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: 16,
          }}
        >
          <Outlet />
          <JiraHelpButtons />
        </Content>
      </Layout>
    </Layout>
  );
}
