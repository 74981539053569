import { useQueryClient } from '@tanstack/react-query';
import { Button, Card, Divider, Popconfirm, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { AppResourceChargesDetails } from '@/apps/app/components/detailsContent/AppResourceChargesDetails';
import { queryKeys } from '@/lib/react-query';
import { ResourceJob } from '@/models';
import { showMessage } from '@/utils';
import { useAcceptPublishedBooking } from '@/web-api/bookings';
import { useJob } from '@/web-api/bookings/useJob';

import {
  AppDocumentDetailsContent,
  AppOnsiteDetailsContent,
  AppPhoneDetailsContent,
  AppRemoteDetailsContent,
  ResourceStatusHint,
  SpecialRateHint,
} from '../components/detailsContent';

export function AppJobDetailsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const confirmBookingMutation = useAcceptPublishedBooking();
  const { jobId } = useParams();
  if (jobId === undefined) {
    throw new Error('Expected jobId to be defined');
  }
  const jobQuery = useJob(jobId);

  const handleConfirmBooking = (id: string) => {
    return () =>
      confirmBookingMutation.mutate(id, {
        onSuccess: () => {
          showMessage('success', t('forms:bookedJob'));
          void queryClient.invalidateQueries({ queryKey: queryKeys.resourceBookings.all });
          navigate(`/app/mybookings/${id}`);
        },
      });
  };

  const Extra = (job: ResourceJob) => (
    <Space direction='horizontal' size='small'>
      <Popconfirm title={t('common:applyJob')} onConfirm={handleConfirmBooking(job.id)}>
        <Button type='primary'>{t('common:apply')}</Button>
      </Popconfirm>
    </Space>
  );

  function renderBooking(job: ResourceJob) {
    switch (job.type) {
      case 'ONSITE':
        return <AppOnsiteDetailsContent jobBooking={job} isJob />;
      case 'REMOTE':
        return <AppRemoteDetailsContent jobBooking={job} isJob />;
      case 'PHONE':
        return <AppPhoneDetailsContent jobBooking={job} isJob />;
      case 'DOCUMENT':
        return <AppDocumentDetailsContent jobBooking={job} isJob />;
      default:
        return null;
    }
  }

  if (jobQuery.data === undefined) {
    return null;
  }

  return (
    <Card
      title='Job Info'
      loading={jobQuery.isLoading}
      extra={<Extra {...jobQuery.data} />}
      styles={{ body: { overflowY: 'auto', height: '80%' } }}
      style={{ height: '100%' }}
      actions={[
        <Link key='back' to={'/app/jobs'}>
          {t('common:backTo')}&nbsp;
          {t('common:jobs')}
        </Link>,
      ]}
    >
      <SpecialRateHint specialRate={jobQuery.data.specialRate} />
      <ResourceStatusHint bookingType={jobQuery.data.type} bookingStatus={jobQuery.data.status} />
      <Divider orientation={'left'}>{t('forms:basicInfo')}</Divider>
      {renderBooking(jobQuery.data)}
      <Divider orientation={'left'}>{t('forms:conditions')}</Divider>
      <AppResourceChargesDetails charges={jobQuery.data.resourceCharges} />
    </Card>
  );
}
