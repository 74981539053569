import useUrlState from '@ahooksjs/use-url-state';
import { Card, Space, Spin } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingList } from '@/components/booking';
import { CalendarHeader } from '@/components/calendar';
import { BookingStatus, BookingType } from '@/models';
import { useBookings } from '@/web-api/bookings';

export const BookingsPage = () => {
  const [showCalendar, setShowCalendar] = useState(false);

  const [urlState, setUrlState] = useUrlState({
    selectedDate: dayjs().toISOString(),
    selectedTypes: [],
    selectedCustomers: [],
    selectedLanguages: [],
    selectedResources: [],
    selectedStatus: [],
  });

  const { t } = useTranslation();

  const { data: bookings, isFetching } = useBookings({
    types: urlState.selectedTypes,
    customers: urlState.selectedCustomers,
    languages: urlState.selectedLanguages,
    resources: urlState.selectedResources,
    status: urlState.selectedStatus,
    month: dayjs(urlState.selectedDate).month(),
    year: dayjs(urlState.selectedDate).year(),
  });

  const onFilterChange = (
    date: Dayjs,
    types: BookingType[],
    customerIds: string[],
    serviceIds: string[],
    resourceIds: string[],
    status: BookingStatus[]
  ) => {
    setUrlState({
      selectedDate: date.toISOString(),
      selectedTypes: types,
      selectedCustomers: customerIds,
      selectedLanguages: serviceIds,
      selectedResources: resourceIds,
      selectedStatus: status,
    });
  };

  const bookingNode = showCalendar ? (
    // <CalendarView
    //   bookingData={serviceBookings ?? []}
    //   selectedDate={selectedDate}
    //   setSelectedDate={setSelectedDate}
    // />
    <></>
  ) : (
    <BookingList bookingData={bookings} />
  );

  return (
    <Card
      title={
        showCalendar ? (
          t('common:calendar')
        ) : (
          <Space>
            {t('common:bookings')}
            <Spin spinning={isFetching} />
          </Space>
        )
      }
      styles={{
        header: {
          position: 'sticky',
          top: -16,
          zIndex: 1000,
          backgroundColor: 'white',
        },
      }}
      extra={
        <CalendarHeader
          onChange={onFilterChange}
          selectedDate={dayjs(urlState.selectedDate)}
          selectedTypes={urlState.selectedTypes}
          selectedCustomers={urlState.selectedCustomers}
          selectedResources={urlState.selectedResources}
          selectedLanguages={urlState.selectedLanguages}
          selectedStatus={urlState.selectedStatus}
        />
      }
    >
      {bookingNode}
    </Card>
  );
};
