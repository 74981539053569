import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toCustomer } from '@/services/customer.service';

function getCustomer(id: string) {
  return getSMPBackendAPIAdmin().customersControllerFindOne(id);
}

export function useCustomer(id: string) {
  return useQuery({
    queryKey: queryKeys.customers.detail(id),
    queryFn: () => getCustomer(id),
    select: (data) => toCustomer(data),
  });
}
