/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, InputNumber, List, Select, Space } from 'antd';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingStatus, Surcharge, SurchargeType } from '@/models';
import { isNumber } from '@/utils';

type SurchargeFormsProps = {
  surcharges: Surcharge[];
  onSave: (data: Surcharge[]) => void;
  bookingStatus?: BookingStatus;
  invoice?: boolean;
  editButtonAriaLabel?: string;
};

export const SurchargeForms: React.FC<SurchargeFormsProps> = ({
  surcharges,
  onSave,
  bookingStatus,
  invoice,
  editButtonAriaLabel,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const isEditingDisabled =
    !(
      bookingStatus === BookingStatus.CLOSED ||
      bookingStatus === BookingStatus.VERIFIED ||
      bookingStatus === BookingStatus.CONFIRMED
    ) || invoice;

  function onCancel() {
    form.resetFields();
    setIsEditing(false);
  }

  function onFinish(values: { surcharges: Surcharge[] }) {
    onSave(values.surcharges);
    setIsEditing(false);
  }

  return (
    <Fragment>
      {isEditing ? (
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{ surcharges }}
          layout='vertical'
          autoComplete='off'
          disabled={isEditingDisabled}
        >
          <Form.List name='surcharges'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ width: '100%' }}>
                    <Form.Item
                      {...restField}
                      label={t('forms:name')}
                      name={[name, 'name']}
                      rules={[{ required: true }]}
                    >
                      <Input aria-label='Kosten Name' placeholder={t('forms:name')} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      label={t('forms:description')}
                    >
                      <Input
                        aria-label='Kosten Beschreibung'
                        placeholder={t('forms:description')}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('forms:type')}
                      name={[name, 'type']}
                      rules={[{ required: true }]}
                    >
                      <Select
                        aria-label='Kosten Typ'
                        placeholder={t('forms:type')}
                        popupMatchSelectWidth={false}
                        onChange={() =>
                          form.setFieldsValue({
                            surcharges: { [name]: { value: undefined, duration: undefined } },
                          })
                        }
                      >
                        {Object.values(SurchargeType).map((type) => (
                          <Select.Option key={type} value={type}>
                            {t(`forms:${type}`)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          prevValues?.surcharges?.[name]?.type !==
                          curValues?.surcharges?.[name]?.type
                        );
                      }}
                    >
                      {() => {
                        return form.getFieldValue(['surcharges', name, 'type']) !==
                          SurchargeType.FIXED ? (
                          <Form.Item
                            {...restField}
                            label={t('forms:HOURLY')}
                            name={[name, 'duration']}
                            rules={[{ required: true }]}
                            tooltip={t('forms:HOURLYTooltip')}
                          >
                            <InputNumber aria-label='Anzahl' />
                          </Form.Item>
                        ) : null;
                      }}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          prevValues?.surcharges?.[name]?.type !==
                          curValues?.surcharges?.[name]?.type
                        );
                      }}
                    >
                      {() => {
                        const type = form.getFieldValue([
                          'surcharges',
                          name,
                          'type',
                        ]) as SurchargeType;
                        return type === SurchargeType.PERCENTAGE ? (
                          <Form.Item
                            {...restField}
                            label={t('forms:amount')}
                            name={[name, 'value']}
                            rules={[{ required: true }]}
                          >
                            <InputNumber
                              aria-label='Prozentual'
                              min={1}
                              precision={2}
                              addonAfter='%'
                              decimalSeparator=','
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            {...restField}
                            label={t('forms:amount')}
                            name={[name, 'value']}
                            rules={[{ required: true }]}
                          >
                            <InputNumber<number>
                              aria-label='Pauschal'
                              precision={2}
                              controls={false}
                              addonAfter='€'
                              formatter={(value) => (value ? `${value}` : '')}
                              onChange={(value) =>
                                value &&
                                form.setFieldsValue({
                                  surcharges: { [name]: { value: value } },
                                })
                              }
                              decimalSeparator=','
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    <Button
                      aria-label='Kosten entfernen'
                      type='text'
                      icon={<MinusCircleOutlined />}
                      danger
                      onClick={() => {
                        form.setFieldsValue({
                          surcharges: { [name]: { value: undefined } },
                        });
                        remove(name);
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    aria-label='Kosten hinzufügen'
                    type='dashed'
                    onClick={() => add({ type: SurchargeType.HOURLY })}
                    icon={<PlusOutlined />}
                  >
                    {t('forms:addSurcharge')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      ) : (
        <List
          dataSource={surcharges}
          renderItem={(surcharge, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar shape='circle'>{index + 1}</Avatar>}
                title={surcharge.name}
                description={
                  <Space split='|'>
                    {surcharge.description ? surcharge.description : null}
                    {surcharge.type ? t(`forms:${surcharge.type}`) : null}
                    {surcharge.duration ? surcharge.duration : null}
                    <span>
                      {isNumber(surcharge.value) ? surcharge.value : null}&nbsp;
                      {surcharge.type === SurchargeType.PERCENTAGE ? '%' : '€'}
                    </span>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      )}
      <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
        {isEditing ? (
          <>
            <Button aria-label='Kosten abbrechen' onClick={onCancel}>
              {t('forms:cancel')}
            </Button>
            <Button aria-label='Kosten speichern' type='primary' onClick={form.submit}>
              {t('forms:save')}
            </Button>
          </>
        ) : (
          <Button
            aria-label={editButtonAriaLabel}
            type='primary'
            onClick={() => setIsEditing(true)}
            disabled={isEditingDisabled}
          >
            {t('forms:edit')}
          </Button>
        )}
      </Space>
    </Fragment>
  );
};
