import type { Dayjs } from 'dayjs';

import { BookingStatus, BookingType, ContactPerson, Surcharge } from '@/models';

export type BillableCustomer = {
  id: string;
  company: string;
  number: number;
};

export type BillableCustomerBooking = {
  id: string;
  reference: string;
  status: BookingStatus;
  type: BookingType;
  date: Dayjs;
  specialRate?: number;
  contactPerson: ContactPerson;
};

export type Billable = {
  billable: {
    customer: BillableCustomer;
    customerBookings: BillableCustomerBooking[];
  }[];
};

export type Invoice = {
  id: string;
  number: string;
  status: InvoiceStatus;
  customer: BillableCustomer;
  created: Dayjs;
  updated: Dayjs;
};

export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus];

export const InvoiceStatus = {
  DRAFT: 'DRAFT',
  OPEN: 'OPEN',
  PAID: 'PAID',
  VOIDED: 'VOIDED',
} as const;

export type ResourceBillable = {
  month: number;
  year: number;
  billableBookings: BillableServiceBooking[];
  cancellationFees: CancellationFee[];
  billableTotal: number;
};

export type CancellationFee = {
  name: string;
  description: string;
  bookingDate: string;
  value: number;
};

export type BillableServiceBooking = {
  id: string;
  reference: string;
  date: string;
  customer: string;
  resourceCharges: Surcharge[];
  type: BookingType;
  status: BookingStatus;
  total: number;
};
