import {
  CalendarOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
  HomeOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import { Card, List, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ResourceBooking } from '@/models';
import { DATETIME_WITHOUT_DAY_FORMAT, displayAddress, getStatusColor, showGender } from '@/utils';

import { JobBookingCardItemProps } from './detailsContent';

const { Text } = Typography;

export function OnsiteBookingCardItem({ jobBooking, extra, isJob }: JobBookingCardItemProps) {
  const { t } = useTranslation();
  return (
    <List.Item>
      <Card
        size='small'
        title={`${jobBooking.language}${jobBooking.alternativeLanguage ? `/${jobBooking.alternativeLanguage}` : ''}`}
        extra={
          <Space>
            {jobBooking.gender && (
              <Tag color={jobBooking.gender === 'FEMALE' ? 'pink-inverse' : 'blue-inverse'}>
                {showGender(jobBooking.gender)}
              </Tag>
            )}
            <Tag color={getStatusColor(jobBooking.status)}>{t(jobBooking.status)}</Tag>
            <Tag icon={<HomeOutlined />}>{t(jobBooking.type)}</Tag>
          </Space>
        }
        actions={extra ? [extra] : undefined}
        style={{ borderWidth: '1px', borderColor: '#000' }}
      >
        <Space direction='vertical'>
          {!isJob && (
            <Text>
              <IdcardOutlined /> {(jobBooking as ResourceBooking).customer}
            </Text>
          )}

          <Text>
            <CalendarOutlined />
            &nbsp;
            {dayjs(jobBooking.date).format(DATETIME_WITHOUT_DAY_FORMAT)}
          </Text>
          {jobBooking.onsite?.location ? (
            <Text>
              <EnvironmentOutlined /> {jobBooking.onsite.location.name}
              <br />
              <Text style={{ marginLeft: 18 }}>{displayAddress(jobBooking.onsite?.location)}</Text>
            </Text>
          ) : null}
          <Text>
            <ClockCircleOutlined />
            &nbsp;
            {t('minuteWithCount', { count: jobBooking.onsite?.duration })}
          </Text>
          <Text>
            <ContactsOutlined />
            &nbsp;
            {jobBooking.contactPerson.name}
          </Text>
          {jobBooking.specialRate ? (
            <Text>
              <EuroCircleOutlined style={{ color: 'red' }} />
              &nbsp;
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {t('forms:specialPrice').toUpperCase()}: {jobBooking.specialRate} €
              </span>
            </Text>
          ) : null}
        </Space>
      </Card>
    </List.Item>
  );
}
