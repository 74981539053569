import { Language } from '@/models';
import { LanguageDto } from '@/proxy';
import { centsToEuros } from '@/utils';

export const toLanguage = (data: LanguageDto): Language => ({
  id: data.id,
  name: data.name,
  isArchived: data.isArchived,
  specialRates: data?.specialRates?.map((specialRate) => ({
    rate: centsToEuros(specialRate.rate),
    bookingType: specialRate.serviceBookingType,
  })),
  isRenameable: !!data.isRenameable,
});
