import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toUser } from '@/services';

function getUsers() {
  return getSMPBackendAPIAdmin().usersControllerFindAll();
}

export function useUsers() {
  return useQuery({
    queryKey: queryKeys.users.lists(),
    queryFn: () => getUsers(),
    select: (data) => data.map(toUser),
  });
}
