import { useAuth0 } from '@auth0/auth0-react';
import { useMount } from 'ahooks';
import { Checkbox, Col, Divider, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { Dayjs } from 'dayjs';
import { Dispatch, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectCustomer } from '@/components/customer';
import { SelectLanguage } from '@/components/language';
import { SelectResource } from '@/components/resource';
import { Booking, BookingRequest, BookingStatus, BookingType, Client } from '@/models';
import {
  BookingAction,
  BookingState,
  isButtonDisabled,
  isUserAdmin,
  validatePhoneNumber,
} from '@/utils';
import { useCustomers } from '@/web-api/customers';
import { useLanguages } from '@/web-api/languages';
import { useResources } from '@/web-api/resources';

import { BookingSubForm } from '.';

const { Option } = Select;

type BookingFormProp = {
  defaultDate?: Dayjs;
  form: FormInstance<BookingRequest & { client: Client }>;
  booking?: Booking;
  onFinish: (resource: Booking & { client: Client }) => void;
  isEditingMode: boolean;
  isCreateModal: boolean;
  bookingState: [BookingState, Dispatch<BookingAction>];
};

export const BookingForm = ({
  form,
  booking,
  onFinish,
  isEditingMode,
  isCreateModal,
  bookingState,
}: BookingFormProp) => {
  const [
    {
      selectedBookingType,
      selectedCustomer,
      selectedDebtor,
      selectedLanguage,
      selectedAlternativeLanguage,
      selectedResource,
    },
    dispatch,
  ] = bookingState;
  const { t } = useTranslation();
  const watchGender = Form.useWatch('gender', form);

  const { data: languages } = useLanguages(false);
  const { data: customers } = useCustomers();
  const { data: resources, refetch } = useResources({
    includeLanguages: [selectedLanguage || '', selectedAlternativeLanguage || ''],
    excludeCustomers: selectedCustomer ? [selectedCustomer] : undefined,
    serviceBookingTypes: booking?.type ? [booking.type] : undefined,
    gender: watchGender,
  });
  const { user } = useAuth0();
  const isAdmin = isUserAdmin(user);
  const showCheckbox =
    (booking?.status === BookingStatus.OPEN || booking?.status === BookingStatus.PUBLISHED) &&
    isAdmin &&
    !isCreateModal;

  useMount(() => {
    if (isEditingMode && booking === undefined) {
      form.setFieldValue('type', selectedBookingType);
    }
    if (booking) {
      dispatch({ type: 'SET_LANGUAGE', payload: booking.language.id });
      dispatch({ type: 'SET_ALTERNATIVE_LANGUAGE', payload: booking.alternativeLanguage?.id });
      dispatch({ type: 'SET_CUSTOMER', payload: booking.customer.id });
      dispatch({ type: 'SET_DEBTOR', payload: booking.debtor?.id ?? undefined });
      dispatch({ type: 'SET_RESOURCE', payload: booking.resource?.id });
    }
  });

  useEffect(() => {
    void refetch();
    form.setFieldValue('resourceId', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, selectedCustomer, refetch]);

  const handleValuesChange = (changedValues: Booking & { client: Client }) => {
    if (changedValues?.client?.birthDate) {
      let value = changedValues.client.birthDate.replace(/\D/g, '');
      if (value.length > 8) value = value.slice(0, 8);
      if (value.length > 4) value = value.slice(0, 4) + '.' + value.slice(4);
      if (value.length > 2) value = value.slice(0, 2) + '.' + value.slice(2);

      form.setFieldsValue({ client: { birthDate: value } });
    }
  };

  function isDisabled(
    allowedStatuses: BookingStatus[] = [
      BookingStatus.OPEN,
      BookingStatus.PUBLISHED,
      BookingStatus.REQUESTED,
    ]
  ) {
    if (isCreateModal) return false;

    return !isEditingMode || isButtonDisabled(booking?.status as BookingStatus, allowedStatuses);
  }

  return (
    <Form
      form={form}
      initialValues={booking}
      layout='vertical'
      disabled={!isEditingMode}
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
    >
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Divider orientation='left'>{t('forms:basicInfo')}</Divider>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('common:customer')}
            name={['customer', 'id']}
            rules={[{ required: true }]}
          >
            <SelectCustomer
              selectedCustomer={selectedCustomer}
              customers={customers}
              onChange={(value) => {
                dispatch({ type: 'SET_CUSTOMER', payload: value });
                dispatch({ type: 'SET_RESOURCE', payload: undefined });
              }}
              isDisabled={isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={['debtor', 'id']} label='Debtor'>
            <SelectCustomer
              selectedCustomer={selectedDebtor}
              customers={customers?.filter((c) => c.id !== selectedCustomer)}
              onChange={(value) => {
                dispatch({ type: 'SET_DEBTOR', payload: value });
              }}
              isDisabled={
                !selectedCustomer ||
                isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED, BookingStatus.CONFIRMED])
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('common:sourceLanguage')}
            name={['language', 'id']}
            rules={[{ required: true }]}
            tooltip={t('common:sourceLanguageTooltip')}
          >
            <SelectLanguage
              ariaLabel='Sprache auswählen'
              selectedLanguage={selectedLanguage}
              languages={languages}
              onChange={(value) => {
                form.setFieldValue('languageId', value);
                dispatch({ type: 'SET_LANGUAGE', payload: value });
                dispatch({ type: 'SET_RESOURCE', payload: undefined });
              }}
              isDisabled={isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('common:alternativeLanguage')}
            name={['alternativeLanguage', 'id']}
            tooltip={t('common:alternativeLanguageTooltip')}
          >
            <SelectLanguage
              ariaLabel='Ersatzsprache auswählen'
              selectedLanguage={selectedAlternativeLanguage}
              languages={languages?.filter((l) => l.id !== selectedLanguage)}
              onChange={(value) => {
                form.setFieldValue('alternativeLanguageId', value);
                dispatch({ type: 'SET_ALTERNATIVE_LANGUAGE', payload: value });
                dispatch({ type: 'SET_RESOURCE', payload: undefined });
              }}
              isDisabled={
                !selectedLanguage || isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED])
              }
            />
          </Form.Item>
        </Col>
        {!isCreateModal && (
          <Col xs={24} md={12}>
            <Form.Item
              label={t('common:resource')}
              name={['resource', 'id']}
              rules={[{ required: false }]}
            >
              <SelectResource
                selectedResource={selectedResource}
                resources={resources}
                onChange={(value) => {
                  form.setFieldValue('resourceId', value);
                  dispatch({ type: 'SET_RESOURCE', payload: value });
                }}
                disabled={
                  !selectedCustomer ||
                  !selectedLanguage ||
                  isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED])
                }
              />
            </Form.Item>
          </Col>
        )}
        {showCheckbox && (
          <Col xs={24} md={12}>
            <Form.Item
              label={t('forms:ignoreAvailability')}
              name={'ignoreAvailability'}
              rules={[{ required: false }]}
              valuePropName='checked'
            >
              <Checkbox disabled={isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED])}>
                {t('forms:ignoreAvailabilityCheckbox')}
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} md={12}>
          <Form.Item label={t('forms:service')} name='type' rules={[{ required: true }]}>
            <Select
              aria-label='Service auswählen'
              allowClear
              value={selectedBookingType}
              options={Object.values(BookingType).map((type) => ({
                label: t(`common:${type}`),
                value: type,
              }))}
              onChange={(value) => {
                dispatch({ type: 'SET_BOOKING_TYPE', payload: value });
              }}
              disabled={isDisabled([BookingStatus.OPEN])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          {/*TODO: multipart/form-data sends only string values and is trying to do .toString() to null value @apiController.ts:261 */}
          <Form.Item
            name='specialRate'
            label={t('forms:specialPrice')}
            extra={
              booking?.type === BookingType.DOCUMENT
                ? t('forms:specialPriceDocumentExtraHint')
                : t('forms:specialPriceExtraHint')
            }
          >
            <InputNumber<number>
              precision={2}
              controls={false}
              addonAfter='€'
              formatter={(value) => (value ? `${value}` : '')}
              onChange={(value) => value && form.setFieldsValue({ specialRate: value })}
              decimalSeparator=','
              style={{ width: '100%' }}
              disabled={isDisabled()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>{t('forms:contactInfo')}</Divider>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={8}>
          <Form.Item
            name={['contactPerson', 'name']}
            label={t('forms:name')}
            rules={[{ required: true }]}
          >
            <Input
              aria-label='Konakt Name eingeben'
              disabled={isDisabled([
                BookingStatus.OPEN,
                BookingStatus.PUBLISHED,
                BookingStatus.CONFIRMED,
              ])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={['contactPerson', 'email']}
            label={t('forms:email')}
            rules={[{ required: true, type: 'email' }]}
          >
            <Input
              aria-label='Kontakt E-Mail eingeben'
              disabled={isDisabled([
                BookingStatus.OPEN,
                BookingStatus.PUBLISHED,
                BookingStatus.CONFIRMED,
              ])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={['contactPerson', 'phone']}
            label={t('forms:phone')}
            rules={[
              {
                required: true,
                validator: validatePhoneNumber,
                message: `${t('forms:phoneNumberInvalid')}`,
              },
            ]}
            tooltip={t('forms:urgentMatters')}
          >
            <Input
              aria-label='Kontakt Telefon eingeben'
              style={{ width: '100%' }}
              disabled={isDisabled([
                BookingStatus.OPEN,
                BookingStatus.PUBLISHED,
                BookingStatus.CONFIRMED,
              ])}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>{t('forms:bookingData')}</Divider>
      <BookingSubForm
        status={booking?.status}
        type={selectedBookingType}
        mode={isEditingMode && isCreateModal ? 'create' : isEditingMode ? 'edit' : 'view'}
      />
      {selectedBookingType !== 'DOCUMENT' && (
        <>
          <Divider orientation='left'>{t('forms:clientData')}</Divider>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item label={t('forms:name')} name={['client', 'name']}>
                <Input
                  aria-label='Klientenname eingeben'
                  disabled={isDisabled([
                    BookingStatus.OPEN,
                    BookingStatus.PUBLISHED,
                    BookingStatus.CLOSED,
                    BookingStatus.CONFIRMED,
                    BookingStatus.VERIFIED,
                  ])}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:birthdate')}
                name={['client', 'birthDate']}
                rules={[
                  {
                    pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                    message: t('forms:birthdateInvalid'),
                  },
                ]}
              >
                <Input
                  aria-label='Klienten Geburtsdatum eingeben'
                  disabled={isDisabled([
                    BookingStatus.OPEN,
                    BookingStatus.PUBLISHED,
                    BookingStatus.CLOSED,
                    BookingStatus.CONFIRMED,
                    BookingStatus.VERIFIED,
                  ])}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('forms:reference')}
                name={['client', 'reference']}
                rules={[{ required: true }]}
                tooltip={t('forms:referenceHelp')}
                extra={t('forms:referenceExtra')}
              >
                <Input
                  aria-label='Referenz eingeben'
                  disabled={isDisabled([
                    BookingStatus.OPEN,
                    BookingStatus.PUBLISHED,
                    BookingStatus.CLOSED,
                    BookingStatus.CONFIRMED,
                    BookingStatus.VERIFIED,
                  ])}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Divider orientation='left'>{t('common:resourceInformation')}</Divider>
      {!isCreateModal && (
        <Form.Item name='acceptsAlternativeResource' valuePropName='checked'>
          <Checkbox disabled={true}>{t('common:specificResourceCheckbox')}</Checkbox>
        </Form.Item>
      )}
      <Form.Item label={t('common:gender')} name='gender'>
        <Select disabled={isDisabled([BookingStatus.OPEN, BookingStatus.PUBLISHED])} allowClear>
          <Option value='MALE'>{t('common:male')}</Option>
          <Option value='FEMALE'>{t('common:female')}</Option>
        </Select>
      </Form.Item>
      <Divider orientation='left'>{t('common:furtherInformation')}</Divider>
      <Form.Item label={t('forms:topic')} name='notes' rules={[{ required: false }]}>
        <Input.TextArea
          placeholder={t('forms:topicPlaceholder')}
          disabled={isDisabled([
            BookingStatus.OPEN,
            BookingStatus.PUBLISHED,
            BookingStatus.CONFIRMED,
          ])}
        />
      </Form.Item>
    </Form>
  );
};
