import { FileAddOutlined, NumberOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Form, FormInstance, InputNumber, Typography, Upload, UploadFile, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { queryKeys } from '@/lib/react-query';
import { showMessage } from '@/utils';
import { useResourceCloseBooking } from '@/web-api/bookings';

const { Paragraph } = Typography;
const { Dragger } = Upload;

type ConfirmDocumentProps = {
  form: FormInstance;
  defaultWordCountValue: number;
  bookingId: string;
};

export function ConfirmDocument({ defaultWordCountValue, form, bookingId }: ConfirmDocumentProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const confirmBookingMutation = useResourceCloseBooking();

  const onConfirmBooking = (formValue: { value: number; file: UploadFile[] }) => {
    if (formValue) {
      confirmBookingMutation.mutate(
        { bookingId, value: formValue.value, file: formValue.file },
        {
          onSuccess: () => {
            showMessage('success', t('common:CONFIRMED'));
            void queryClient.invalidateQueries({ queryKey: queryKeys.resourceBookings.all });
            navigate(`/app/mybookings`);
          },
        }
      );
    }
  };

  const normFile = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return e?.fileList as UploadFile[];
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: () => {
      form.setFieldValue('file', undefined);
    },
    beforeUpload: (file) => {
      form.setFieldValue('file', [file]);
      return false;
    },
  };

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark='optional'
      initialValues={{ value: defaultWordCountValue }}
      onFinish={onConfirmBooking}
    >
      <Paragraph>{t('common:confirmBookingWordCountText')}</Paragraph>
      <Form.Item name='value' label={t('common:word_other')} rules={[{ required: true }]}>
        <InputNumber
          min={1}
          size='large'
          style={{ width: '100%' }}
          addonBefore={<NumberOutlined />}
        />
      </Form.Item>
      <Form.Item noStyle>
        <Form.Item
          label={t('forms:dataUploadLabel')}
          name='file'
          valuePropName='fileList'
          rules={[{ required: true }]}
          getValueFromEvent={normFile}
        >
          <Dragger {...props}>
            <p className='ant-upload-drag-icon'>
              <FileAddOutlined />
            </p>
            <p className='ant-upload-text'>{t('forms:uploadFileText')}</p>
            <p className='ant-upload-hint'>{t('forms:uploadFileHint')}</p>
          </Dragger>
        </Form.Item>
      </Form.Item>
    </Form>
  );
}
