import { useMutation } from '@tanstack/react-query';

import { ExpressSurcharge } from '@/models';
import { CreateUpdateExpressSurchargeDto, getSMPBackendAPIAdmin } from '@/proxy';
import { percentToDecimal } from '@/utils';

function toRequest(express: ExpressSurcharge): CreateUpdateExpressSurchargeDto {
  return {
    minutes: express.minutes,
    percentage: percentToDecimal(express.percentage),
    resourceShare: percentToDecimal(express.resourceShare),
  };
}

function createExpressSurcharge(express: ExpressSurcharge) {
  const data = toRequest(express);
  return getSMPBackendAPIAdmin().settingsControllerCreateExpressSurcharge(data);
}

export function useCreateExpressSurcharge() {
  return useMutation({ mutationFn: createExpressSurcharge });
}
