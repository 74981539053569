import { Navigate, useLocation } from 'react-router-dom';

import { AppLayout } from './components';

export function AppIndex() {
  const location = useLocation();

  if (location.pathname === '/app' || location.pathname === '/app/') {
    return <Navigate to='/app/mybookings' replace />;
  }

  return <AppLayout />;
}
