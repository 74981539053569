import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { defaultStaleTime, queryKeys } from '@/lib/react-query';
import { PaginatedCustomersDto } from '@/models';
import { CustomersControllerFindAllWithPaginationParams, getSMPBackendAPIAdmin } from '@/proxy';
import { toPaginatedCustomers } from '@/services/customer.service';

function getCustomersPaginated(params: CustomersControllerFindAllWithPaginationParams) {
  return getSMPBackendAPIAdmin().customersControllerFindAllWithPagination(
    params
  ) as Promise<PaginatedCustomersDto>;
}

export function useCustomersPaginated(params: CustomersControllerFindAllWithPaginationParams) {
  return useQuery({
    queryKey: queryKeys.customers.pagination(params),
    queryFn: () => getCustomersPaginated(params),
    select: (data) => toPaginatedCustomers(data),
    placeholderData: keepPreviousData,
    staleTime: defaultStaleTime,
  });
}
