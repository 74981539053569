/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQueryClient } from '@tanstack/react-query';
import { useDeepCompareEffect } from 'ahooks';
import {
  Alert,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Statistic,
  Tag,
  Typography,
  Watermark,
} from 'antd';
import dayjs from 'dayjs';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BookingForm, BookingVerifyStatus } from '@/components/booking';
// eslint-disable-next-line no-restricted-imports
import { BookingActions } from '@/components/booking/BookingActions';
// eslint-disable-next-line no-restricted-imports
import { SurchargeForms } from '@/components/booking/SurchargeForms';
import { EditSaveButton } from '@/components/shared';
import { useDelayedLoading } from '@/hooks';
import { queryKeys } from '@/lib/react-query';
import { Booking, BookingStatus, Client, Surcharge } from '@/models';
import {
  DATETIME_WITH_DAY_FORMAT,
  bookingReducer,
  getInvoiceStatusColor,
  getStatusColor,
  initialBookingState,
  isButtonDisabled,
  showMessage,
} from '@/utils';
import {
  useBooking,
  useUpdateBooking,
  useUpdateCustomerCharges,
  useUpdateResourceCharges,
} from '@/web-api/bookings';

const { Countdown } = Statistic;

export const BookingDetailPage = () => {
  const [bookingState, bookingDispatch] = useReducer(bookingReducer, initialBookingState);
  const [isEditingMode, setEditingMode] = useState(false);
  const { t } = useTranslation();
  const { bookingId } = useParams();
  if (bookingId === undefined) {
    throw new Error('Expected bookingId to be defined');
  }
  const { data, isLoading } = useBooking(bookingId);
  const updateBookingMutation = useUpdateBooking();
  const updateCustomerCharges = useUpdateCustomerCharges();
  const updateResourceCharges = useUpdateResourceCharges();

  const showDelayedLoading = useDelayedLoading(isLoading);
  const queryClient = useQueryClient();
  const bookingInvoice = data?.invoice;
  const [form] = Form.useForm<Booking & { debtorId: string; client: Client }>();

  useDeepCompareEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      bookingDispatch({ type: 'SET_BOOKING_TYPE', payload: data.type });
      bookingDispatch({ type: 'SET_LANGUAGE', payload: data.language.id });
      bookingDispatch({
        type: 'SET_ALTERNATIVE_LANGUAGE',
        payload: data.alternativeLanguage?.id ?? undefined,
      });
      bookingDispatch({ type: 'SET_CUSTOMER', payload: data.customer.id });
      bookingDispatch({ type: 'SET_DEBTOR', payload: data.debtor?.id ?? undefined });
      bookingDispatch({ type: 'SET_RESOURCE', payload: data.resource?.id });
    }
  }, [data]);

  const onSave = () => form.submit();
  const onCancel = () => {
    if (data) {
      bookingDispatch({ type: 'SET_BOOKING_TYPE', payload: data.type });
      bookingDispatch({ type: 'SET_LANGUAGE', payload: data.language.id });
      bookingDispatch({
        type: 'SET_ALTERNATIVE_LANGUAGE',
        payload: data.alternativeLanguage?.id ?? undefined,
      });
      bookingDispatch({ type: 'SET_CUSTOMER', payload: data.customer.id });
      bookingDispatch({ type: 'SET_DEBTOR', payload: data.debtor?.id ?? undefined });
      bookingDispatch({ type: 'SET_RESOURCE', payload: data.resource?.id });
    }
    form.resetFields();
    setEditingMode(!isEditingMode);
  };

  const onFormFinish = (booking: Booking & { client: Client }) => {
    updateBookingMutation.mutate(booking, {
      onSuccess: () => {
        showMessage('success', t('forms:saved'));
        void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.detail(booking.id) });
        setEditingMode(false);
      },
    });
  };

  const onCustomerChargesSave = (surcharges: Surcharge[]) => {
    if (data) {
      updateCustomerCharges.mutate(
        { id: data?.id, model: surcharges },
        {
          onSuccess: () => {
            showMessage('success', t('forms:saved'));
            void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.detail(data.id) });
            setEditingMode(false);
          },
        }
      );
    }
  };
  const onResourceChargesSave = (surcharges: Surcharge[]) => {
    if (data) {
      updateResourceCharges.mutate(
        { id: data?.id, model: surcharges },
        {
          onSuccess: () => {
            showMessage('success', t('forms:saved'));
            void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.detail(data.id) });
            setEditingMode(false);
          },
        }
      );
    }
  };

  if (!data) return null;

  const showExtra = !isButtonDisabled(data?.status, [
    BookingStatus.OPEN,
    BookingStatus.CONFIRMED,
    BookingStatus.PUBLISHED,
    BookingStatus.CLOSED,
    BookingStatus.VERIFIED,
    BookingStatus.REQUESTED,
  ]);

  const showGenderWarning =
    data?.gender &&
    (data.status === 'OPEN' || data.status === 'CONFIRMED' || data.status === 'PUBLISHED');

  const showRequestedAlert = data?.status === 'REQUESTED';

  function onCountdownFinish() {
    if (bookingId) {
      void queryClient.invalidateQueries({ queryKey: queryKeys.bookings.detail(bookingId) });
    }
  }

  const mainContent = (
    <Card
      title={
        <Space>
          {t('common:bookingDetail')}
          {data?.reference}
          {data?.status && (
            <Tag color={getStatusColor(data?.status)}>{t(`common:${data?.status}`)}</Tag>
          )}
          <span>
            {bookingInvoice && (
              <span>
                {t('common:invoiceStatus')}:&nbsp;
                <Tag color={getInvoiceStatusColor(bookingInvoice.status)}>
                  {t(`common:${bookingInvoice.status}`)}
                </Tag>
              </span>
            )}
          </span>
        </Space>
      }
      loading={showDelayedLoading}
      extra={
        showExtra && (
          <Space>
            <EditSaveButton
              form={form}
              isDisabled={!!bookingInvoice}
              isEditing={isEditingMode}
              onCancel={onCancel}
              onSave={onSave}
              onEdit={() => setEditingMode(true)}
              editButtonAriaLabel='Buchung bearbeiten'
            />
            <BookingActions
              data={data}
              form={form}
              isEditingMode={isEditingMode}
              setEditingMode={setEditingMode}
            />
          </Space>
        )
      }
    >
      {showGenderWarning && <Alert showIcon type='warning' message={t('common:genderWarning')} />}
      {showRequestedAlert && data?.requestExpiresAt && (
        <Alert
          showIcon
          type='info'
          message={t('common:requestedAlert')}
          description={
            <Countdown
              title={t('common:responseUntil', {
                date: data?.requestExpiresAt?.format(DATETIME_WITH_DAY_FORMAT),
              })}
              value={data?.requestExpiresAt?.valueOf()}
              onFinish={onCountdownFinish}
            />
          }
        />
      )}
      <BookingForm
        form={form}
        booking={data}
        onFinish={onFormFinish}
        isEditingMode={isEditingMode}
        isCreateModal={false}
        bookingState={[bookingState, bookingDispatch]}
      />
      <Row>
        <Col xs={24}>
          <Divider orientation='left'>{t('common:billableWithCustomer')}</Divider>
          <SurchargeForms
            bookingStatus={data?.status}
            surcharges={data.customerCharges}
            onSave={onCustomerChargesSave}
            invoice={!!bookingInvoice}
            editButtonAriaLabel='Verrechnung mit Kunde bearbeiten'
          />
        </Col>
        <Col xs={24}>
          <Divider orientation='left'>{t('common:billableWithResource')}</Divider>
          <SurchargeForms
            bookingStatus={data?.status}
            surcharges={data?.resourceCharges}
            onSave={onResourceChargesSave}
            invoice={!!bookingInvoice}
            editButtonAriaLabel='Verrechnung mit Ressource bearbeiten'
          />
        </Col>
      </Row>
      <BookingVerifyStatus
        bookingId={bookingId}
        bookingType={data?.type}
        bookingStatus={data?.status}
        bookingVerification={data?.verification}
        invoice={bookingInvoice}
        editButtonAriaLabel='Verifizierung bearbeiten'
      />
      <Row>
        <Space direction={'vertical'} align={'center'} style={{ width: '100%' }}>
          <Typography.Text type='secondary'>
            {t('common:createdAt')}: {dayjs(data.createdAt).format(DATETIME_WITH_DAY_FORMAT)}
          </Typography.Text>
          <Typography.Text type='secondary'>
            {t('common:updatedAt')}: {dayjs(data.updatedAt).format(DATETIME_WITH_DAY_FORMAT)}
          </Typography.Text>
        </Space>
      </Row>
    </Card>
  );

  return bookingInvoice ? (
    <Watermark content={`${bookingInvoice.number} - ${t(`common:${bookingInvoice.status}`)}`}>
      {mainContent}
    </Watermark>
  ) : (
    mainContent
  );
};
