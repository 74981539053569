import { useMount } from 'ahooks';
import { Col, Divider, Form, FormInstance, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { Customer, CustomerRequest } from '@/models';

type CustomerFormProp = {
  form: FormInstance<CustomerRequest>;
  customer?: Customer;
  onFinish: (resource: CustomerRequest) => void;
};

// const customerDefaultValues = {
//   id: '',
//   company: 'Tester GmbH',
//   street: 'Wienerstr. 28',
//   supplement: '',
//   zip: '10999',
//   city: 'Berlin',
//   country: 'Deutschland',
//   firstName: 'Mario',
//   lastName: 'Ballotelli',
//   email: 'mario@smp-berlin.de',
//   phone: '456489765186',
// };

export const CustomerForm = ({ form, customer, onFinish }: CustomerFormProp) => {
  const { t } = useTranslation('forms');

  useMount(() => {
    form.setFieldsValue({
      country: 'Deutschland',
    });
  });

  return (
    <Form form={form} initialValues={customer} layout='vertical' onFinish={onFinish}>
      <Row gutter={[12, 12]}>
        <Form.Item name='id' hidden>
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item label={t('name')} name='company' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={16}>
          <Form.Item label={t('forms:street')} name='street' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            label={t('forms:department')}
            name='supplement'
            rules={[{ required: false }]}
            tooltip={t('forms:departmentTooltip')}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label={t('forms:zip')} name='zip' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item label={t('forms:city')} name='city' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label={t('forms:country')} name='country' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>{t('forms:contactInfo')}</Divider>
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12}>
          <Form.Item label={t('forms:firstname')} name='firstName' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={t('forms:lastname')} name='lastName' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={t('forms:email')} name='email' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={t('forms:phone')} name='phone' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
