import { useQueryClient } from '@tanstack/react-query';
import { Alert, Statistic } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/lib/react-query';
import { BookingStatus, BookingType } from '@/models';
import { DATETIME_WITH_DAY_FORMAT } from '@/utils';

type ResourceStatusHintProps = {
  bookingStatus: BookingStatus;
  bookingType: BookingType;
  requestExpirationDate?: Dayjs;
};

const { Countdown } = Statistic;

export function ResourceStatusHint({
  bookingStatus,
  bookingType,
  requestExpirationDate,
}: ResourceStatusHintProps) {
  const isDocument = bookingType === BookingType.DOCUMENT;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const deadlineDate = requestExpirationDate?.format(DATETIME_WITH_DAY_FORMAT);

  function onFinishCountdown() {
    void queryClient.invalidateQueries({ queryKey: queryKeys.jobs.requests() });
  }

  function renderStatusHint() {
    switch (bookingStatus) {
      case BookingStatus.PUBLISHED:
        return <Alert showIcon type='info' message={t('common:statusHintPublished')} />;
      case BookingStatus.CONFIRMED:
        return (
          <Alert
            showIcon
            type='warning'
            message={t(isDocument ? 'statusHintDocumentConfirmed' : 'common:statusHintConfirmed')}
          />
        );
      case BookingStatus.CLOSED:
        return (
          <Alert
            showIcon
            type='info'
            message={t(isDocument ? 'statusHintDocumentClosed' : 'common:statusHintClosed')}
          />
        );
      case BookingStatus.VERIFIED:
        return (
          <Alert
            showIcon
            type='success'
            message={t(
              isDocument ? 'common:statusHintDocumentVerified' : 'common:statusHintVerified'
            )}
          />
        );
      case BookingStatus.CANCELED:
        return <Alert showIcon type='error' message={t('common:statusHintCanceled')} />;
      case BookingStatus.REQUESTED:
        return (
          <Alert
            showIcon
            type='info'
            description={t('common:statusHintRequested')}
            message={
              <Countdown
                title={t('common:responseUntil', { date: deadlineDate })}
                value={requestExpirationDate?.valueOf()}
                onFinish={onFinishCountdown}
              />
            }
          />
        );
      case BookingStatus.DECLINED:
        return <Alert showIcon type='error' message={t('common:statusHintDeclined')} />;
      default:
        return <Alert type='warning' message={t('common:statusHintUnknown')} />;
    }
  }

  return <div style={{ marginBottom: 12 }}>{renderStatusHint()}</div>;
}
