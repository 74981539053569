import { useResponsive } from 'ahooks';
import { Descriptions, DescriptionsProps } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BookingStatus, ResourceBooking, ResourceJob } from '@/models';
import { DATETIME_WITH_DAY_FORMAT, displayEmptyString } from '@/utils';

import { AppDetailsContentProps } from '.';

export function AppRemoteDetailsContent({ jobBooking, isJob }: AppDetailsContentProps) {
  const { t } = useTranslation();
  const breakpoint = useResponsive();
  const isVerified = jobBooking.status === BookingStatus.VERIFIED;
  const inHours = Number(
    (((jobBooking as ResourceBooking)?.verification?.value ?? 0) / 60).toFixed(2)
  );

  const hasMeetingUrl = !!(jobBooking.remote as ResourceBooking['remote'])?.meetingUrl;

  const items: DescriptionsProps['items'] = [
    {
      key: 'reference',
      label: t('common:reference'),
      children: t(jobBooking.reference),
    },
    {
      key: 'type',
      label: t('common:bookingType'),
      children: t(jobBooking.type),
    },
    ...(!isJob && hasMeetingUrl
      ? [
          {
            key: 'meetingUrl',
            label: t('common:meetingUrl'),
            children: (
              <a
                href={(jobBooking.remote as ResourceBooking['remote'])?.meetingUrl}
                target='_blank'
                rel='noreferrer'
              >
                {t('common:joinMeeting')}
              </a>
            ),
          },
        ]
      : []),
    {
      key: 'language',
      label: t('common:language'),
      children: jobBooking.language,
    },
    ...(jobBooking.alternativeLanguage
      ? [
          {
            key: 'alternativeLanguage',
            label: t('common:alternativeLanguage'),
            children: jobBooking.alternativeLanguage,
          },
        ]
      : []),
    {
      key: 'date',
      label: t('forms:startTime'),
      children: dayjs(jobBooking.date).format(DATETIME_WITH_DAY_FORMAT),
    },
    {
      key: 'duration',
      label: t('forms:duration'),
      children: t('minuteWithCount', {
        count: jobBooking.remote?.duration,
      }),
    },
    {
      key: 'participant',
      label: t('common:numberParticipants'),
      children: t('common:participant', {
        count: isJob
          ? (jobBooking as ResourceJob).remote?.participantsCount
          : (jobBooking as ResourceBooking).remote?.emails.length,
      }),
    },
    {
      key: 'notes',
      label: t('forms:topic'),
      children: displayEmptyString(jobBooking.notes ?? '', t('common:noData')),
    },
    ...(jobBooking.gender
      ? [
          {
            key: 'gender',
            label: t('common:gender'),
            children: t(`common:${jobBooking.gender.toString().toLowerCase()}`),
          },
        ]
      : []),
    {
      key: 'contactPerson',
      label: t('common:contactPerson'),
      children: jobBooking.contactPerson.name,
    },
    ...(!isJob
      ? [
          {
            key: 'customer',
            label: t('common:customer'),
            children: (jobBooking as ResourceBooking).customer,
          },
        ]
      : []),
    ...(isVerified
      ? [
          {
            key: 'verified',
            label: t('common:VERIFIED'),
            children: (
              <>
                <strong>
                  {t('minuteWithCount', {
                    count: (jobBooking as ResourceBooking).verification?.value,
                  })}
                  &nbsp; (
                  {t('hourWithCount', {
                    count: inHours,
                  })}
                  )
                </strong>
                <br />
                {t('forms:startTime')}:&nbsp;
                {(jobBooking as ResourceBooking).verification?.startTime?.format(
                  DATETIME_WITH_DAY_FORMAT
                )}
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <Descriptions
      size='small'
      bordered
      layout={breakpoint.lg ? 'horizontal' : 'vertical'}
      items={items}
      labelStyle={{ fontWeight: 'bolder' }}
      column={1}
    />
  );
}
