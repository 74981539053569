/* eslint-disable @typescript-eslint/restrict-template-expressions */
import dayjs from 'dayjs';

import { BookingType, Resource, ResourceBooking } from '@/models';

export const formatResourceName = (
  firstname: string,
  lastname: string,
  display: 'formal' | 'non-formal'
) => {
  switch (display) {
    case 'formal':
      return `${lastname}, ${firstname}`;
    case 'non-formal':
      return `${firstname} ${lastname}`;
  }
};

export const getInitials = (firstname: string, lastname: string) => {
  return `${firstname.charAt(0)}${lastname.charAt(0)}`;
};

export function generateICS(eventDetails: ResourceBooking): string {
  const { type, date, onsite, phone, remote, document, contactPerson, language, reference } =
    eventDetails;

  let location = '';
  let description = `${type} Termin`;
  let dtEnd = formatDate(date);

  switch (type) {
    case BookingType.ONSITE:
      if (onsite) {
        const { street, supplement, zip, city, country } = onsite.location;
        location = `${street}${supplement ? ', ' + supplement : ''}, ${zip} ${city}, ${country}`;
        dtEnd = formatDate(date.add(onsite.duration, 'minute'));
      }
      break;
    case BookingType.PHONE:
      if (phone) {
        location = `Telefon: ${phone.zoomConferenceNumber}`;
        description += `\nMit ${contactPerson?.name ?? 'Unbekannt'}\nEinwahlnummer: ${phone.dialInNumber.split(' ').join('')}\nTelefon: ${phone.zoomConferenceNumber}\nKonferenz-ID: ${phone.zoomConferenceId}`;
        dtEnd = formatDate(date.add(phone.duration, 'minute'));
      }
      break;
    case BookingType.REMOTE:
      if (remote) {
        location = remote.meetingUrl ? remote.meetingUrl : 'Video Termin';
        dtEnd = formatDate(date.add(remote.duration, 'minute'));
      }
      break;
    case BookingType.DOCUMENT:
      if (document) {
        description += `\nDokument Abgabe: ${document.fileName}`;
        location = document.viewUrl || document.downloadUrl;
      }
      break;
    default:
      location = 'Unspecified';
  }

  let reminderTime;
  switch (type) {
    case BookingType.PHONE:
      reminderTime = '-PT15M'; // 15 minutes before
      break;
    case BookingType.DOCUMENT:
      reminderTime = '-PT24H'; // 24 hours before
      break;
    case BookingType.REMOTE:
      reminderTime = '-PT15M'; // 15 minutes before
      break;
    case BookingType.ONSITE:
      reminderTime = '-PT60M'; // 60 minutes before
      break;
    default:
      reminderTime = '-PT30M'; // Default reminder time
  }

  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `UID:${Date.now()}@smp-berlin.de`,
    `SUMMARY:SMP Termin - ${type} ${language}`,
    `DESCRIPTION:${description.replace(/\n/g, '\\n')}\\nBuchungsnummer: ${reference}`,
    `DTSTART:${formatDate(date)}`,
    `DTEND:${dtEnd}`,
    `LOCATION:${location}`,
    'BEGIN:VALARM',
    `TRIGGER:${reminderTime}`,
    'ACTION:DISPLAY',
    'DESCRIPTION:Reminder',
    'END:VALARM',
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');
}

function formatDate(date: dayjs.Dayjs): string {
  // Ensure the date is a valid dayjs object
  return date.isValid() ? date.format('YYYYMMDDTHHmmss') : '';
}

export function showGender(gender?: Resource['gender']) {
  switch (gender) {
    case 'FEMALE':
      return '♀️';
    case 'MALE':
      return '♂️';
    default:
      return '';
  }
}
