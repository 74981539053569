import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import {
  InvoiceControllerGetChargeableServiceBookingsByCustomerParams,
  getSMPBackendAPIAdmin,
} from '@/proxy';
import { toBillable } from '@/services';

function getAllBillables(params: InvoiceControllerGetChargeableServiceBookingsByCustomerParams) {
  return getSMPBackendAPIAdmin().invoiceControllerGetChargeableServiceBookingsByCustomer(params);
}

export function useBillables(
  params: InvoiceControllerGetChargeableServiceBookingsByCustomerParams
) {
  return useQuery({
    queryKey: queryKeys.billing.billable(params),
    queryFn: () => getAllBillables(params),
    select: (data) => toBillable(data),
    enabled: !!params,
  });
}
