import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Booking, BookingRequest, BookingType, Client } from '@/models';
import { CreateServiceBookingRequest, getSMPBackendAPIAdmin } from '@/proxy';
import { eurosToCents } from '@/utils';

const toRequest = (model: BookingRequest & { client: Client }): CreateServiceBookingRequest => ({
  debtorId: model.debtor?.id,
  date: model.date.format(),
  notes: model.notes,
  customerId: model.customer.id,
  languageId: model.language.id,
  alternativeLanguageId: model.alternativeLanguage?.id,
  contactPerson: model.contactPerson,
  type: model.type,
  specialRate: model.specialRate ? eurosToCents(model.specialRate) : undefined,
  client: model.client && {
    name: model.client?.name,
    birthDate: model.client.birthDate
      ? dayjs.utc(model.client?.birthDate, 'DD.MM.YYYY').toISOString()
      : undefined,
    reference: model.client?.reference,
  },
  gender: model?.gender,
  ...(model.type === BookingType.ONSITE &&
    model.onsite && {
      onsite: {
        location: model.onsite.location,
        duration: model.onsite.duration,
        alternativeService: model.onsite.alternativeService ?? false,
      },
    }),
  ...(model.type === BookingType.REMOTE &&
    model.remote && {
      remote: {
        duration: model.remote.duration,
        emails: model.remote.emails,
      },
    }),
  ...(model.type === BookingType.PHONE &&
    model.phone && {
      phone: {
        duration: model.phone.duration,
        clientPhone: model.phone.clientPhone,
        customerPhone: model.phone.customerPhone,
      },
    }),
  ...(model.type === BookingType.DOCUMENT &&
    model.document && {
      document: {
        wordCount: model.document.wordCount,
      },
      file: model.document.file && model.document.file[0]?.originFileObj,
    }),
});

function createServiceBooking(model: Booking & { client: Client }) {
  return getSMPBackendAPIAdmin().serviceBookingsControllerCreate(toRequest(model));
}

export function useCreateServiceBooking() {
  return useMutation({ mutationFn: createServiceBooking });
}
