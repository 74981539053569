import { useMutation } from '@tanstack/react-query';

import { FormBookingTypeValues } from '@/components/settings';
import {
  CreateUpdateServiceRateDtoServiceBookingType,
  UpsertServiceRateReqeuest,
  getSMPBackendAPIAdmin,
} from '@/proxy';
import { eurosToCents } from '@/utils';

const toRequest = (rates: FormBookingTypeValues): UpsertServiceRateReqeuest => {
  const values = Object.entries(rates)
    .filter(([type]) => type in CreateUpdateServiceRateDtoServiceBookingType)
    .map(([type, rate]) => ({
      serviceBookingType:
        CreateUpdateServiceRateDtoServiceBookingType[
          type as keyof typeof CreateUpdateServiceRateDtoServiceBookingType
        ],
      rate: eurosToCents(rate),
    }));
  return { rates: values };
};

function updateRatesSettings(rate: FormBookingTypeValues) {
  const data = toRequest(rate);
  return getSMPBackendAPIAdmin().settingsControllerUpsertServiceRate(data);
}

export function useUpdateRatesSettings() {
  return useMutation({ mutationFn: updateRatesSettings });
}
