import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toLanguage } from '@/services';

function getLanguage(id: string) {
  return getSMPBackendAPIAdmin().languagesControllerFindOne(id);
}

export function useLanguage(id: string) {
  return useQuery({
    queryKey: queryKeys.languages.detail(id),
    queryFn: () => getLanguage(id),
    select: (data) => toLanguage(data),
    enabled: !!id,
  });
}
