import { useQuery, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { User } from '@/models';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toUser } from '@/services';

function getCurrentUser() {
  return getSMPBackendAPIAdmin().usersControllerFindMe();
}

export function useCurrentUser() {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: queryKeys.currentUser.all,
    queryFn: getCurrentUser,
    staleTime: 60 * 60 * 60 * 24,
    initialData: () => queryClient.getQueryData<User>(queryKeys.currentUser.all),
    initialDataUpdatedAt: () => queryClient.getQueryState(queryKeys.currentUser.all)?.dataUpdatedAt,
    select: (data) => toUser(data),
  });
}
