/* eslint-disable @typescript-eslint/no-unsafe-call */
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useResponsive } from 'ahooks';
import { Button, Descriptions, DescriptionsProps, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BookingStatus, ResourceBooking } from '@/models';
import { DATETIME_WITH_DAY_FORMAT, displayEmptyString } from '@/utils';

import { AppDetailsContentProps } from '.';

const { Text } = Typography;

export function AppDocumentDetailsContent({ jobBooking, isJob }: AppDetailsContentProps) {
  const { t } = useTranslation();
  const breakpoint = useResponsive();
  const isVerified = jobBooking.status === BookingStatus.VERIFIED;

  const items: DescriptionsProps['items'] = [
    {
      key: 'reference',
      label: t('common:reference'),
      children: t(jobBooking.reference),
    },
    {
      key: 'type',
      label: t('common:bookingType'),
      children: t(jobBooking.type),
    },
    {
      key: 'language',
      label: t('common:language'),
      children: jobBooking.language,
    },
    ...(jobBooking.alternativeLanguage
      ? [
          {
            key: 'alternativeLanguage',
            label: t('common:alternativeLanguage'),
            children: jobBooking.alternativeLanguage,
          },
        ]
      : []),
    {
      key: 'date',
      label: t('forms:dueDate'),
      children: (
        <Text>
          {dayjs(jobBooking.date).format(DATETIME_WITH_DAY_FORMAT)}
          &nbsp;({dayjs(jobBooking.date).fromNow()})
        </Text>
      ),
    },
    {
      key: 'wordCount',
      label: t('common:word_other'),
      children: t('wordWithCount', { count: jobBooking.document?.wordCount }),
    },
    {
      key: 'notes',
      label: t('forms:topic'),
      children: displayEmptyString(jobBooking.notes ?? '', t('common:noData')),
    },
    ...(jobBooking.gender
      ? [
          {
            key: 'gender',
            label: t('common:gender'),
            children: t(`common:${jobBooking.gender.toString().toLowerCase()}`),
          },
        ]
      : []),
    {
      key: 'contactPerson',
      label: t('common:contactPerson'),
      children: jobBooking.contactPerson.name,
    },
    ...(!isJob
      ? [
          {
            key: 'customer',
            label: t('common:customer'),
            children: (jobBooking as ResourceBooking).customer,
          },
          {
            key: 'file',
            label: t('common:file'),
            children: (
              <Space direction='vertical' style={{ width: '100%' }}>
                <Text code>{(jobBooking.document as ResourceBooking['document'])?.fileName}</Text>
                <Button
                  icon={<EyeOutlined />}
                  href={(jobBooking.document as ResourceBooking['document'])?.viewUrl}
                  target='_blank'
                  rel='noreferrer'
                  block
                >
                  {t('common:viewHere')}
                </Button>
                <Button
                  icon={<DownloadOutlined />}
                  href={(jobBooking.document as ResourceBooking['document'])?.downloadUrl}
                  target='_blank'
                  rel='noreferrer'
                  block
                >
                  {t('common:downloadHere')}
                </Button>
              </Space>
            ),
          },
        ]
      : []),
    ...(isVerified
      ? [
          {
            key: 'upload',
            label: t('common:VERIFIED'),
            children: (
              <>
                <Link
                  to={(jobBooking as ResourceBooking).verification?.fileUrl ?? ''}
                  target={'_blank'}
                >
                  {(jobBooking as ResourceBooking).verification?.fileName}
                </Link>
              </>
            ),
          },
          {
            key: 'verified',
            label: t('common:word_other'),
            children: t('wordWithCount', {
              count: (jobBooking as ResourceBooking).verification?.value,
            }),
          },
        ]
      : []),
  ];

  return (
    <Descriptions
      size='small'
      bordered
      layout={breakpoint.lg ? 'horizontal' : 'vertical'}
      items={items}
      labelStyle={{ fontWeight: 'bolder' }}
      column={1}
    />
  );
}
