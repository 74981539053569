import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserRoles } from '@/hooks';
import { customAuth } from '@/lib/customAuth';
import { navigateToRoleDefault } from '@/utils';

import { CenterSpinner } from '../shared';

type AuthenticationGuardProps = {
  component: ComponentType<any>;
  roles: string[];
};

export const AuthenticationGuard = ({ component, roles }: AuthenticationGuardProps) => {
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const userRoles = useUserRoles();

  useEffect(() => {
    if (isAuthenticated) {
      customAuth.setAccessTokenSilently(getAccessTokenSilently());
      if (userRoles && !roles.some((role) => userRoles.includes(role))) {
        navigateToRoleDefault(userRoles[0], navigate);
      }
    }
  }, [getAccessTokenSilently, isAuthenticated, navigate, roles, userRoles]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <CenterSpinner isLoading />,
  });

  return <Component />;
};
