import { useMutation } from '@tanstack/react-query';

import { FormLanguage } from '@/components/language';
import { CreateUpdateLanguageRequest, getSMPBackendAPIAdmin } from '@/proxy';
import { eurosToCents } from '@/utils';

const toRequest = (data: FormLanguage): CreateUpdateLanguageRequest => {
  return {
    name: data.name,
    onsiteRate: data.onsiteRate && eurosToCents(data.onsiteRate),
    remoteRate: data.remoteRate && eurosToCents(data.remoteRate),
    phoneRate: data.phoneRate && eurosToCents(data.phoneRate),
    documentRate: data.documentRate && eurosToCents(data.documentRate),
  };
};

function updateLanguage(language: FormLanguage) {
  return getSMPBackendAPIAdmin().languagesControllerUpdate(language?.id ?? '', toRequest(language));
}

export function useUpdateLanguage() {
  return useMutation({ mutationFn: updateLanguage });
}
