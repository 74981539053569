import { useMutation } from '@tanstack/react-query';

import { getSMPBackendAPIPublic } from '@/proxy-web';

function cancelWebBooking(bookingId: string) {
  return getSMPBackendAPIPublic().requestControllerConfirmCancelRequest(bookingId);
}

export function useCancelWebBooking() {
  return useMutation({ mutationFn: cancelWebBooking });
}
