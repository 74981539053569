import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toUser } from '@/services';

function getUser(id: string) {
  return getSMPBackendAPIAdmin().usersControllerFindOne(id);
}

export function useUser(id: string) {
  return useQuery({
    queryKey: queryKeys.users.detail(id),
    queryFn: () => getUser(id),
    select: (data) => toUser(data),
  });
}
