import { Card, List } from 'antd';
import { useTranslation } from 'react-i18next';

export function AppProfilePage() {
  const { t } = useTranslation();
  return (
    <Card title={t('header:yourProfile')}>
      <List />
    </Card>
  );
}
