import { Avatar, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { User } from '@/models';
import { formatResourceName, getInitials } from '@/utils/resourceHelper';

type UserListProp = {
  users?: User[];
};

export const UserList = ({ users }: UserListProp) => {
  const { t } = useTranslation();

  return (
    <List
      size='small'
      itemLayout='horizontal'
      dataSource={users}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Link key={item.id} to={`/admin/users/${item.id}`}>
              {t('common:show')}
            </Link>,
          ]}
        >
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={<Avatar>{getInitials(item.firstname, item.lastname)}</Avatar>}
            title={formatResourceName(item.firstname, item.lastname, 'formal')}
            description={item.email}
          />
        </List.Item>
      )}
    />
  );
};
