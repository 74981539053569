import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { defaultStaleTime, queryKeys } from '@/lib/react-query';
import { getSMPBackendAPIAdmin } from '@/proxy';
import { toCustomer } from '@/services/customer.service';

function getCustomers() {
  return getSMPBackendAPIAdmin().customersControllerFindAll();
}

export function useCustomers() {
  return useQuery({
    queryKey: queryKeys.customers.lists(),
    queryFn: () => getCustomers(),
    select: (data) => data.map(toCustomer),
    placeholderData: keepPreviousData,
    staleTime: defaultStaleTime,
  });
}
