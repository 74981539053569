import { FormLanguage } from '@/components/language';
import { BookingType, Language, SpecialRate } from '@/models';

export function transformLanguage(language: Language): FormLanguage {
  const request: FormLanguage = {
    id: language.id,
    name: language.name,
    onsiteRate: null,
    remoteRate: null,
    documentRate: null,
    phoneRate: null,
  };

  language?.specialRates?.forEach((specialRate: SpecialRate) => {
    switch (specialRate.bookingType) {
      case BookingType.ONSITE:
        request.onsiteRate = specialRate.rate;
        break;
      case BookingType.REMOTE:
        request.remoteRate = specialRate.rate;
        break;
      case BookingType.DOCUMENT:
        request.documentRate = specialRate.rate;
        break;
      case BookingType.PHONE:
        request.phoneRate = specialRate.rate;
        break;
      default:
        break;
    }
  });
  return request;
}
