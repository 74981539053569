import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateLanguageModal, LanguageList } from '@/components/language';
import { useDelayedLoading } from '@/hooks';
import { useLanguages } from '@/web-api/languages';

export const LanguagesPage = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useLanguages(true);
  const showDelayedLoading = useDelayedLoading(isLoading);

  return (
    <Card title={t('common:languages')} extra={<CreateLanguageModal />} bordered={false}>
      <LanguageList languages={data} isLoading={showDelayedLoading} />
    </Card>
  );
};
