import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { CreateResourceModal, ResourceList } from '@/components/resource';
import { useDelayedLoading } from '@/hooks';
import { useResources } from '@/web-api/resources';

export const ResourcesPage = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useResources();
  const showDelayedLoading = useDelayedLoading(isLoading);

  return (
    <Card title={t('common:resources')} extra={<CreateResourceModal />} bordered={false}>
      <ResourceList resources={data} isLoading={showDelayedLoading} />
    </Card>
  );
};
