import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib/react-query';
import { ResourceBookingsControllerFindBookingsParams, getSMPBackendAPIAdmin } from '@/proxy';
import { toResourceBooking } from '@/services';

function getBookings(params: ResourceBookingsControllerFindBookingsParams) {
  return getSMPBackendAPIAdmin().resourceBookingsControllerFindBookings(params);
}

export function useMyBookings(params: ResourceBookingsControllerFindBookingsParams) {
  return useQuery({
    queryKey: queryKeys.resourceBookings.lists(params.status),
    queryFn: () => getBookings(params),
    select: (data) => data.map(toResourceBooking),
  });
}
